import "./index.css";
import logo from "../../svg/fair-logo-red.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateQARating } from "../../data/api/QA/qaSlice";
import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { QARating } from "../QARating";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

type AnswerBubbleProps = {
  sessionId?: string;
  questionId?: string;
  rating?: string | null;
  answer: string;
};

function AnswerBubble({
  sessionId,
  questionId,
  rating,
  answer,
}: AnswerBubbleProps) {
  const dispatch = useAppDispatch();
  const [currentRating, setCurrentRating] = useState<number | undefined>(
    undefined
  );
  const [isRatingError, setIsRatingError] = useState<boolean>(false);
  const [isLoadingRating, setIsLoadingRating] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);
  const { ratingResults } = useAppSelector(
    (state: RootState) => state.qaDetails
  );

  useEffect(() => {
    if (rating !== null && rating !== undefined) {
      setCurrentRating(parseInt(rating));
    }
  }, [rating]);

  useEffect(() => {
    console.log("rating results " + JSON.stringify(ratingResults));
    const ratingResult = ratingResults.find((r) => r.questionId === questionId);
    if (ratingResult) {
      setIsLoadingRating(ratingResult.isLoading);
      if (ratingResult.error) {
        setIsRatingError(true);
        setCurrentRating(0);
      }
    }
  }, [ratingResults]);

  const setRating = async (rating: number) => {
    if (sessionId && questionId) {
      setCurrentRating(rating);
      dispatch(
        updateQARating({
          sessionId,
          questionId,
          rating,
        })
      );
    }
  };

  const stripMarkdown = (text: string) => {
    return text
      .replace(/[#*]/g, "")
      .replace(/^\s*\n/gm, "\n")
      .replace(/\n\n+/g, "\n\n");
  };

  const copyToClipboard = async () => {
    const plainText = stripMarkdown(answer);
    await navigator.clipboard.writeText(plainText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <div
      data-testid="answer-bubble"
      className="answer-bubble-container d-flex justify-content-start"
    >
      <div className="d-flex flex-column bubble-rating-container">
        <div className="d-flex align-items-end">
          <img src={logo} />
          <div className="answer-bubble">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{answer}</ReactMarkdown>
          </div>
        </div>

        <div className="w-100 d-flex align-items-center justify-content-end">
          {isRatingError && (
            <div className="rating-error-txt">Error setting rating</div>
          )}
          <QARating
            rating={currentRating ?? 0}
            setRating={setRating}
            disabled={isLoadingRating}
            copyToClipboard={copyToClipboard}
            copied={isCopied}
          />
        </div>
      </div>
    </div>
  );
}

export default AnswerBubble;
