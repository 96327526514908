const BASE_URL = "http://192.168.1.9:8000/";

const PREDICT = "search";

const CHAT = "chat";

const VISUAL_CHAT = "visual";

const INTERVIEW = "interview";

const CONTRACT = "contract";

const UPLOAD_CONTRACT = "uploadingcontract";

const FETCH_FILE_LIST = "fetchFileList";

const GET_EXTRACTED_DATA = "getExtractedData";

const GET_DOCUMENT_SOURCE = "getDocumentSource";

const REVERT_EXTRACTED_DATA = "revertExtractedData";

const SUBMIT_CONTRACT = "submitContract";

const BUCKET_HISTORY = "getBucketHistory";

const GET_EXTRACTED_DATA_DOWNLOAD = "getExtractedDataDownload";

const FILE = "file";

const FILES = "files";

const MSW_BASE_URL = "https://dev.ice.rackspace.net/api/";

const USER_DATA_URL = "userdetails";

const TOKEN_URL = "tokendetails";

const RESPONSE_SETTINGS_URL = "getresponsesettings";

const UPDATE_RESPONSE_SETTINGS_URL = "updateresponsesettings";

const HISTORY_URL = "gethistory";

const FAVORITES_URL = "getfavorites";

const GET_SEARCH_URL = "getsearch";

const CATEGORIES_URL = "getcategories";

const ERROR_MESSAGE_500 = "An error has occurred. Please try again later";

const SUPPORTED_IMAGE_TYPES = ["jpg", "jpeg", "png", "svg"];

const SUPPORTED_DOCUMENT_TYPES = ["doc", "docx", "pdf", "xls", "xlsx", "txt"];

export {
  BASE_URL,
  ERROR_MESSAGE_500,
  MSW_BASE_URL,
  PREDICT,
  USER_DATA_URL,
  HISTORY_URL,
  FAVORITES_URL,
  GET_SEARCH_URL,
  CHAT,
  SUPPORTED_IMAGE_TYPES,
  SUPPORTED_DOCUMENT_TYPES,
  TOKEN_URL,
  INTERVIEW,
  CATEGORIES_URL,
  CONTRACT,
  RESPONSE_SETTINGS_URL,
  UPDATE_RESPONSE_SETTINGS_URL,
  FILE,
  FILES,
  UPLOAD_CONTRACT,
  FETCH_FILE_LIST,
  GET_EXTRACTED_DATA,
  GET_EXTRACTED_DATA_DOWNLOAD,
  GET_DOCUMENT_SOURCE,
  REVERT_EXTRACTED_DATA,
  VISUAL_CHAT,
  SUBMIT_CONTRACT,
  BUCKET_HISTORY,
};
