import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  useState,
} from "react";
import { QUESTIONNAIRE_LIST } from "../../../constants";
import { OptionsIcon } from "../../../svg/optionsIcon";
import { SortIcon } from "../../../svg/sort-icon";
import "./index.css";
import moment from "moment";

const QuestionnaireTable = ({
  setNewQuestionnaire,
  setViewData,
  setQueryId,
  displayedFiles,
}: any) => {
  const [showPopup, setShowPopup] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedList, setSortedList] = useState(QUESTIONNAIRE_LIST);

  const togglePopup = (index: any) => {
    setShowPopup(showPopup === index ? null : index);
  };

  const sortList = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    const sorted = [...sortedList].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortOrder(newSortOrder);
    setSortedList(sorted);
  };

  const dateFormat = (isoDate: string) => {
    if (!isoDate) return "";
    const formattedDate = moment(isoDate).format("DD/MM/YYYY");

    const [day, month, year] = formattedDate.split("/");
    const result = `${month}/${day}/${year}`;
    return result;
  };

  return (
    <div className="hr-interview-table">
      <div className="heading-margin">Select a Questionnaire</div>
      <table className="hr-questionnaire-table">
        <thead>
          <tr>
            <th>
              <div className="sort-name" onClick={sortList}>
                Name{" "}
                <div className="d-flex align-items-center" role="img">
                  <SortIcon
                    className={sortOrder === "desc" ? "rotate-arrow" : ""}
                  />
                </div>
              </div>
            </th>
            <th>Job Title</th>
            <th>Level</th>
            <th>Updated</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="hr-table-body">
          {displayedFiles?.map(
            (
              item: {
                id: any;
                name:
                  | string
                  | number
                  | boolean
                  | ReactElement<any, string | JSXElementConstructor<any>>
                  | Iterable<ReactNode>
                  | ReactPortal
                  | null
                  | undefined;
                inputs: {
                  title:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | null
                    | undefined;
                  level:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | null
                    | undefined;
                };
                last_modified_date: string;
              },
              index: Key | null | undefined
            ) => (
              <tr
                key={index}
                onClick={() => {
                  setQueryId(item?.id);
                  setNewQuestionnaire(true);
                  setViewData(true);
                }}
              >
                <td>{item.name}</td>
                <td>{item.inputs.title}</td>
                <td>{item.inputs.level}</td>
                <td>{dateFormat(item.last_modified_date)}</td>
                <td>
                  <div
                    className="options-icon position-relative cursor-pointer"
                    onClick={() => togglePopup(index)}
                    role="button"
                  >
                    <OptionsIcon />
                    {showPopup === index && (
                      <OptionsPopup
                        setNewQuestionnaire={setNewQuestionnaire}
                        setViewData={setViewData}
                        setQueryId={setQueryId}
                        queryId={item.id}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

const OptionsPopup = ({
  setNewQuestionnaire,
  setViewData,
  setQueryId,
  queryId,
}: any) => {
  return (
    <div className="popup-container" data-testid="popup-container">
      <div className="hr-popup popup-text">
        <div
          onClick={() => {
            setQueryId(queryId);
            setNewQuestionnaire(true);
            setViewData(true);
          }}
        >
          View
        </div>
        <div>Rename</div>
        <div>Remove</div>
      </div>
    </div>
  );
};

export default QuestionnaireTable;
