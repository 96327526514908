function ClosePanel() {
  return (
    <svg width="28px" height="24px" className="close-pane" viewBox="0 0 30 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>9A73EAAF-AC06-4762-8FD3-093699C09672</title>
      <g id="ICE-V.1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ICE-V.1--Prompt-Categories" transform="translate(-910, -27)" fill="#367ED4" fillRule="nonzero">
          <path d="M937.857143,27 C939.04061,27 940,27.9700497 940,29.1666667 L940,50.8333333 C940,52.0299503 939.04061,53 937.857143,53 L912.142857,53 C910.95939,53 910,52.0299503 910,50.8333333 L910,29.1666667 C910,27.9700497 910.95939,27 912.142857,27 Z M929.285714,29.1666667 L912.142857,29.1666667 L912.142857,50.8333333 L929.285714,50.8333333 L929.285714,29.1666667 Z M937.857143,29.1666667 L931.428571,29.1666667 L931.428571,50.8333333 L937.857143,50.8333333 L937.857143,29.1666667 Z M921.652902,36 C921.850074,36 922.019345,36.0650407 922.160714,36.195122 L925.793527,39.5378691 C925.931176,39.6576808 926,39.8117244 926,40 C926,40.1848524 925.931176,40.3406076 925.793527,40.4672657 L922.160714,43.8100128 C922.015625,43.9366709 921.846354,44 921.652902,44 C921.46317,44 921.295759,43.9366709 921.15067,43.8100128 L920.732143,43.4249037 C920.590774,43.2948224 920.520089,43.1390672 920.520089,42.957638 C920.520089,42.7727856 920.590774,42.618742 920.732143,42.4955071 L922.367188,40.9858793 L916.652902,40.9858793 C916.459449,40.9858793 916.302269,40.9216945 916.181362,40.7933248 C916.060454,40.6649551 916,40.5100556 916,40.3286264 L916,39.6713736 C916,39.4899444 916.060454,39.3350449 916.181362,39.2066752 C916.302269,39.0783055 916.459449,39.0141207 916.652902,39.0141207 L922.367188,39.0141207 L920.732143,37.5044929 C920.590774,37.381258 920.520089,37.2272144 920.520089,37.042362 C920.520089,36.8575096 920.590774,36.703466 920.732143,36.5802311 L921.15067,36.195122 C921.292039,36.0650407 921.459449,36 921.652902,36 Z" id="close-pane" />
        </g>
      </g>
    </svg>
  );
}

export { ClosePanel };
