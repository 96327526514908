import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDownloadApi,
  fetchPutApi,
  fetchUploadApi,
  fetchPostApi,
  RawRequestError,
  fetchGetApi,
} from "../../../api/fetch";
import {
  CONTRACT,
  UPLOAD_CONTRACT,
  FETCH_FILE_LIST,
  GET_EXTRACTED_DATA,
  GET_DOCUMENT_SOURCE,
  REVERT_EXTRACTED_DATA,
  SUBMIT_CONTRACT,
  BUCKET_HISTORY,
} from "../../../constants";
import { serializeError } from "serialize-error";
//import { FileType } from "../../../types";

export type contractHistoryType = {
  bucket_id: string;
  bucket_name: string;
  session_id: string;
  updated_datetime: string;
  created_datetime: string;
  status: string;
};

export type contractHistoryResponseType = contractHistoryType[];
interface InitialState {
  isLoading: boolean;
  extractedResponse: string;
  error: RawRequestError | null;
  queryId: string;
  file: string;
  bucket_id: string;
  status: string;
  bucket_name: string;
  isEditing: boolean;
  editedResponseSummary: string;
  editedResponseData: string;
  file_data: [
    {
      status: string;
      file_name: string;
      file_id: string;
    }
  ];
  totalFilesUploaded: number;
  filesSuccessfullyUploaded: number;
  filesWithError: number;
  extracted_data: string;
  summary: string;
  // revertedSummary: string;
  // revertedExtractedData: string;
  getSourceFile: any;
  isDocumentSourceLoading: boolean;
  submitContractStatus: string;
  submitContractLoading: boolean;
  contractError: RawRequestError | null;
  isDownloading: boolean;
  historyResults: contractHistoryResponseType;
  isLoadingHistory: boolean;
  isUploadingVar: boolean;
  uploadedFilesVar: number;
}

const initialState: InitialState = {
  isLoading: false,
  extractedResponse: "",
  queryId: "",
  error: null,
  file: "",
  bucket_id: "",
  status: "",
  bucket_name: "",
  isEditing: false,
  editedResponseSummary: "",
  editedResponseData: "",
  file_data: [
    {
      status: "",
      file_name: "",
      file_id: "",
    },
  ],
  totalFilesUploaded: 0,
  filesSuccessfullyUploaded: 0,
  filesWithError: 0,
  extracted_data: "",
  summary: "",
  // revertedSummary: "",
  // revertedExtractedData: "",
  getSourceFile: "" as any,
  isDocumentSourceLoading: false,
  submitContractStatus: "",
  submitContractLoading: false,
  contractError: null,
  isDownloading: false,
  historyResults: [],
  isLoadingHistory: false,
  isUploadingVar: false,
  uploadedFilesVar: 0,
};

type ContractPostRequest = {
  file: File;
};

const fetchContract = createAsyncThunk(
  "contract/fetchContract",
  async (params: ContractPostRequest, thunkAPI) => {
    const { file } = params;
    const formData = new FormData();
    formData.append("file_data", file);

    return fetchUploadApi(formData, CONTRACT)
      .then((res: any) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const uploadContract = createAsyncThunk(
  "contract/uploadContract",
  async (bucket_name: string, thunkAPI) => {
    // const UPLOAD_CONTRACT_BUCKET_ID = `${UPLOAD_CONTRACT}?bucket_name=${encodeURIComponent(bucket_id)}`;
    const UPLOAD_CONTRACT_BUCKET_ID =
      UPLOAD_CONTRACT + "/bucket_name?bucket_name=" + bucket_name;
    return fetchPostApi({}, UPLOAD_CONTRACT_BUCKET_ID)
      .then((res: any) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const fetchFileList = createAsyncThunk(
  "contract/fetchFileList",
  async (bucket_id: string, thunkAPI) => {
    const FETCH_URL = FETCH_FILE_LIST + "/bucket_id?bucket_id=" + bucket_id;
    return fetchGetApi(FETCH_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getExtractedData = createAsyncThunk(
  "contract/getExtractedData",
  async (file_id: string, thunkAPI) => {
    const FETCH_URL = GET_EXTRACTED_DATA + "/file_id?file_id=" + file_id;
    return fetchGetApi(FETCH_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getBucketHistory = createAsyncThunk(
  "contract/getBucketHistory",
  async (_: void, thunkAPI) => {
    const FETCH_URL = BUCKET_HISTORY;
    return fetchGetApi(FETCH_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getDocumentSource = createAsyncThunk(
  "contract/getDocumentSource",
  async (
    params: { fileId: string; bucketId: string; fileName: string },
    thunkAPI
  ) => {
    const { fileId, bucketId } = params;
    const DOCUMENT_SOURCE_URL = `${GET_DOCUMENT_SOURCE}/${bucketId}/${fileId}`;
    // return fetchDownloadApi(DOCUMENT_SOURCE_URL)
    return fetchDownloadApi(DOCUMENT_SOURCE_URL)
      .then(async (res) => {
        return res.blob();
      })
      .then((blob) => {
        return { data: blob };
      })
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const revertExtractedData = createAsyncThunk(
  "contract/revertExtractedData",
  async (params: { fileId: string; bucketId: string }, thunkAPI) => {
    // const { fileId, bucketId } = params;
    const REVERT_EXTRACTED_DATA_URL = `${REVERT_EXTRACTED_DATA}/bucketId/${params.bucketId}/fileId/${params.fileId}`;
    return fetchPostApi({}, REVERT_EXTRACTED_DATA_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const submitContract = createAsyncThunk(
  "contract/submitContract",
  async (bucketId: string, thunkAPI) => {
    // const { fileId, bucketId } = params;
    const SUBMIT_CONTRACT_DATA_URL = `${SUBMIT_CONTRACT}/${bucketId}`;
    return fetchPostApi({}, SUBMIT_CONTRACT_DATA_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getDownloadContract = createAsyncThunk(
  "contract/getDownloadContract",
  async (
    params: { fileType: string; fileName: string; fileId: string },
    thunkAPI
  ) => {
    const endpoint = `contract_extraction/${params.fileId}/download/${params.fileType}`;

    return fetchDownloadApi(endpoint)
      .then(async (res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = params.fileName;
        a.style.display = "none";
        document.body.appendChild(a);

        a.click();
        console.log(a, "atag");
        return { data: params.fileName };
      })
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const updateContract = createAsyncThunk(
  "contract/updateContract",
  async (
    params: { fileId: string; summary: string; extracted_details: string },
    thunkAPI
  ) => {
    const { fileId, summary, extracted_details } = params;

    const reqData = {
      file_id: fileId,
      summary,
      extracted_details,
    };
    const UPDATE_CONTRACT = `${CONTRACT}/queries/${fileId}`;
    return fetchPutApi(reqData, UPDATE_CONTRACT)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setFiledata: (state, action) => ({
      ...state,
      file_data: action.payload,
      filesSuccessfullyUploaded: 0,
      totalFilesUploaded: 0,
      bucket_id: "",
      status: "",
    }),
    setBucketNameStatusAndId: (state, action) => ({
      ...state,
      bucket_id: action.payload.bucket_id,
      status: action.payload.status,
      bucket_name: action.payload.bucket_name,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContract.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      })
      .addCase(fetchContract.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          isLoading: false,
          extractedResponse: data.contract_extracted_data,
          queryId: data.id,
          error: null,
        };
      })
      .addCase(fetchContract.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload as RawRequestError,
        };
      })
      .addCase(getDownloadContract.pending, (state) => ({
        ...state,
        isDownloading: true,
      }))
      .addCase(getDownloadContract.fulfilled, (state, action) => {
        const file = action.payload.data;
        return {
          ...state,
          file,
          isDownloading: false,
          error: null,
        };
      })
      .addCase(getDownloadContract.rejected, (state, action) => ({
        ...state,
        isDownloading: false,
        error: action.payload as RawRequestError,
      }))
      .addCase(getDocumentSource.pending, (state) => {
        return {
          ...state,
          isDocumentSourceLoading: true,
        };
      })
      .addCase(getDocumentSource.fulfilled, (state, action) => {
        // getSourceFile = action.payload.data;
        return {
          ...state,
          isDocumentSourceLoading: false,
          getSourceFile: action.payload.data,
          error: null,
        };
      })
      .addCase(getDocumentSource.rejected, (state, action) => ({
        ...state,
        isDocumentSourceLoading: false,
        error: action.payload as RawRequestError,
      }))
      .addCase(uploadContract.pending, (state) => ({
        ...state,
        contractError: null,
      }))
      .addCase(uploadContract.fulfilled, (state, action) => {
        return {
          ...state,
          bucket_id: action.payload.data.bucket_id,
          contractError: null,
        };
      })
      .addCase(uploadContract.rejected, (state, action) => ({
        ...state,
        contractError: action.payload as RawRequestError,
      }))
      .addCase(fetchFileList.pending, (state) => ({
        ...state,
        isUploadingVar: true,
        uploadedFilesVar: 1,
      }))
      .addCase(fetchFileList.fulfilled, (state, action) => {
        const { data } = action.payload;
        let historyResults = state.historyResults;
        const existsInHistory = historyResults.find(
          (history) => history.session_id === data.session_id
        );
        if (!existsInHistory && data.status === "Completed") {
          const history = {
            bucket_id: data.bucket_id,
            bucket_name: data.bucket_name,
            session_id: data.session_id,
            updated_datetime: data.updated_datetime,
            created_datetime: data.created_datetime,
            status: data.status,
          };
          historyResults = [...state.historyResults, history];
        }
        return {
          ...state,
          status: action.payload.data.status,
          file_data: action.payload.data.file_data,
          totalFilesUploaded: action.payload.data.total,
          filesSuccessfullyUploaded: action.payload.data.success_count,
          filesWithError: action.payload.data.failed_count,
          error: null,
        };
      })
      .addCase(fetchFileList.rejected, (state, action) => ({
        ...state,
        error: action.payload as RawRequestError,
      }))
      .addCase(getExtractedData.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(getExtractedData.fulfilled, (state, action) => {
        return {
          ...state,
          extracted_data: action.payload.data.extracted_data,
          summary: action.payload.data.summary,
          isLoading: false,
          error: null,
        };
      })
      .addCase(getExtractedData.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload as RawRequestError,
        };
      })
      .addCase(revertExtractedData.pending, (state) => ({
        ...state,
        isLoading: true,
        editedResponseSummary: "",
        editedResponseData: "",
      }))
      .addCase(revertExtractedData.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          summary: action.payload.data.data.summary,
          extracted_data: action.payload.data.data.extracted_details,
          error: null,
        };
      })
      .addCase(revertExtractedData.rejected, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload as RawRequestError,
      }))
      .addCase(submitContract.pending, (state) => ({
        ...state,
        submitContractLoading: true,
        submitContractStatus: "",
      }))
      .addCase(submitContract.fulfilled, (state, action) => {
        return {
          ...state,
          submitContractLoading: false,
          submitContractStatus: action.payload.data.status,
          error: null,
        };
      })
      .addCase(submitContract.rejected, (state, action) => ({
        ...state,
        submitContractLoading: false,
        submitContractStatus: "",
        error: action.payload as RawRequestError,
      }))
      .addCase(getBucketHistory.pending, (state) => ({
        ...state,
        isLoadingHistory: true,
      }))
      .addCase(getBucketHistory.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingHistory: false,
          historyResults: action.payload.data,
          error: null,
        };
      })
      .addCase(getBucketHistory.rejected, (state, action) => ({
        ...state,
        isLoadingHistory: false,
        error: action.payload as RawRequestError,
      }));

    builder.addCase(updateContract.pending, (state) => ({
      ...state,
      isEditing: true,
      editedResponseSummary: "",
      editedResponseData: "",
    }));
    builder.addCase(updateContract.fulfilled, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        isEditing: false,
        editedResponseSummary: data.summary,
        editedResponseData: data.extracted_data,
        error: null,
      };
    });
    builder.addCase(updateContract.rejected, (state, action) => {
      return {
        ...state,
        isEditing: false,
        error: action.payload as RawRequestError,
      };
    });
  },
});

const { setFiledata, setBucketNameStatusAndId } = contractSlice.actions;
export default contractSlice.reducer;
export {
  fetchContract,
  getDownloadContract,
  uploadContract,
  fetchFileList,
  updateContract,
  getExtractedData,
  getDocumentSource,
  revertExtractedData,
  submitContract,
  setFiledata,
  getBucketHistory,
  setBucketNameStatusAndId,
};
