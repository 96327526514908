function SearchIcon() {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>A33EC548-A819-4D19-9886-C8C12605AB99</title>
      <g
        id="Admin"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Admin---Connector---Empty"
          transform="translate(-1089, -310)"
          fill="#9E9E9E"
          fill-rule="nonzero"
        >
          <g id="Group-2" transform="translate(1075, 299)">
            <path
              d="M30.6,29 L24.3,22.7 C23.8,23.1 23.225,23.4166667 22.575,23.65 C21.925,23.8833333 21.2333333,24 20.5,24 C18.6833333,24 17.146,23.3706667 15.888,22.112 C14.63,20.8533333 14.0006667,19.316 14,17.5 C14,15.6833333 14.6293333,14.146 15.888,12.888 C17.1466667,11.63 18.684,11.0006667 20.5,11 C22.3166667,11 23.854,11.6293333 25.112,12.888 C26.37,14.1466667 26.9993333,15.684 27,17.5 C27,18.2333333 26.8833333,18.925 26.65,19.575 C26.4166667,20.225 26.1,20.8 25.7,21.3 L32,27.6 L30.6,29 Z M20.4999992,21.9999992 C21.75,21.9999992 22.8126667,21.5623333 23.688,20.687 C24.5633333,19.8116667 25.0006667,18.7493333 24.9999992,17.4999992 C24.9999992,16.25 24.5623333,15.1873333 23.687,14.312 C22.8116667,13.4366667 21.7493333,12.9993333 20.4999992,12.9999992 C19.25,12.9999992 18.1873333,13.4376667 17.312,14.313 C16.4366667,15.1883333 15.9993333,16.2506667 15.9999992,17.4999992 C15.9999992,18.75 16.4376667,19.8126667 17.313,20.688 C18.1883333,21.5633333 19.2506667,22.0006667 20.4999992,21.9999992 Z"
              id="search-icon"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { SearchIcon };
