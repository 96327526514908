import { useState } from 'react';
import { XmarkCircle, ExclamationDiamond, Pencil } from '@rss-engineering/fabric-icons';
import { UpdateInput } from '../UpdateInput';
import { setIsSidePanelActive } from '../../data/api/SidePanel/sidePanelSlice';
import { useAppDispatch } from '../../redux/hooks';
import { FairUser } from '../../svg/fairUser';
import {
  MULTIPLE_MESSAGE,
  MULTIPLE_NO_RESPONSE_MESSAGE,
  SINGLE_MESSAGE,
  SINGLE_NO_RESPONSE_MESSAGE,
  VIEW_PROMPTS_EXAMPLE,
} from '../../constants';
import './index.css';

type NoResponseProps = {
  type: string;
  question: string;
  resultId: number;
  index: number;
  onEditQuestion: (searchTerm: string, searchId: number) => void;
  deleteQuestion: (questionId: number) => void;
};

function NoResponse({
  type, question, resultId, index, onEditQuestion, deleteQuestion,
}: NoResponseProps) {
  const [closeComponent, setCloseComponent] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const displayMessage = (type === SINGLE_MESSAGE)
    ? SINGLE_NO_RESPONSE_MESSAGE : MULTIPLE_NO_RESPONSE_MESSAGE;

  const showSidePanel = () => {
    dispatch(setIsSidePanelActive(true));
  };

  const closeNoResponse = () => {
    setCloseComponent(true);
    deleteQuestion(resultId);
  };

  return (
    !closeComponent && (
      <div
        data-testid="no-response"
        className={`error-response mt-8 mb-10 alert-error default-padding d-flex ${
          type === MULTIPLE_MESSAGE ? ' custom-margin-left' : ''
        }`}
      >
        {type === SINGLE_MESSAGE
          && <FairUser />}
        <span className="d-flex flex-row w-100 ms-5">
          <span className="d-flex flex-column w-100">
            {type === SINGLE_MESSAGE
              && (
              <span className="d-flex flex-row">
                {showEdit ? (
                  <UpdateInput
                    question={question}
                    setShowEdit={(value) => setShowEdit(value)}
                    onEditQuestion={onEditQuestion}
                    searchId={resultId}
                    data-testid="update-input"
                  />
                )
                  : <span>{question}</span>}
                {!showEdit && (
                <span className="d-flex flex-row ms-auto">
                  { index === 0
                    && (
                    <Pencil
                      className="cursor-pointer"
                      width={24}
                      height={24}
                      onClick={() => setShowEdit(true)}
                      data-testid="edit-icon"
                    />
                    )}
                  <XmarkCircle
                    className="cursor-pointer ms-4"
                    width={24}
                    height={24}
                    onClick={closeNoResponse}
                    data-testid="close-prompt"
                  />
                </span>
                ) }
              </span>
              )}
            {type === MULTIPLE_MESSAGE
                && <span>{question}</span>}
            <span className="d-flex flex-row mt-4">
              <ExclamationDiamond size={18} className="mt-2 me-3 error-text-color" />
              <span className="error-text-color">{displayMessage}</span>
              <a
                href="#"
                className="link-underline-light ms-auto"
                onClick={showSidePanel}
                data-testid="show-sidepanel"
              >
                {VIEW_PROMPTS_EXAMPLE}
              </a>
            </span>
          </span>
        </span>
      </div>
    )
  );
}

export default NoResponse;
