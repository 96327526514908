function TxtIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>543D2AD1-1D15-45A4-B0FD-CB7A0B5C5DDB</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-222, -621)">
                    <g id="TXT" transform="translate(222, 621)">
                        <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                            <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                            <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                        </g>
                        <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                        <g id="Group" transform="translate(4.5, 12)" fill="#FFFFFF">
                            <polygon id="Path-Copy" points="8.44363017 2.0686745 9.56863904 0 10.9903076 0 9.24311482 2.97531973 11.0356966 6 9.59750361 6 8.44363017 3.89834756 7.28982766 6 5.85163471 6 7.64421645 2.97531973 5.89695279 0 7.31862131 0"></polygon>
                            <polygon id="Path" points="4.88733127 1.00139477 3.04943146 1.00139477 3.04943146 6 1.81314863 6 1.81314863 1.00139477 0 1.00139477 0 0 4.88733127 0"></polygon>
                            <polygon id="Path-Copy-2" points="16.8873313 1.00139477 15.0494315 1.00139477 15.0494315 6 13.8131486 6 13.8131486 1.00139477 12 1.00139477 12 0 16.8873313 0"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { TxtIcon }