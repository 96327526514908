import { useEffect, useState } from "react";
import "./index.css";
import InvoiceResponse from "../../components/InvoiceReponse";
import { fetchInvoice } from "../../data/api/Invoice/invoiceSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { CommonLoader } from "../../components/CommonLoader";
import { FileRejection, useDropzone } from "react-dropzone";
import { UploadIcon } from "../../svg/uploadIcon";
import { ContractIcon } from "../../svg/contractIcon";
import { CloseCircle } from "../../svg/closeCircle";

function AWSInvoiceReader() {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [viewSource, setViewSource] = useState(false);
  const [storedFile, setStoredFile] = useState<File | null>(null);
  const [prompt, setPrompt] = useState("");

  const { isLoading, summary, extractedResponse } = useAppSelector(
    (state: RootState) => state.invoiceDetails
  );

  useEffect(() => {
    setStoredFile(file);
    setFile(null);
  }, [extractedResponse, summary]);

  const onDropAccepted = (acceptedFiles: File[]) => {
    setIsUploading(true);
    setTimeout(() => {
      setFile(acceptedFiles[0]);
      setIsUploading(false);
    }, 1000);
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    console.log(fileRejections);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropAccepted,
    onDropRejected: onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  });

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleExtractClick = () => {
    if (file) {
      setViewSource(false);
      dispatch(
        fetchInvoice({
          file,
          prompt,
        })
      );
    }
  };

  return (
    <div
      className={`invoice-container ${
        viewSource ? "container-source-padding" : "container-padding"
      }`}
      data-testid="invoice-extraction-component"
    >
      {file && (
        <div
          className={`uploaded-file-container ${
            viewSource ? "source-file-margin" : ""
          }`}
        >
          <label
            className={`source-file-label ${
              isLoading ? "disable-opacity" : ""
            }`}
          >
            Source File:
          </label>
          <div
            className={`uploaded-file ${isLoading ? "disable-opacity" : ""}`}
          >
            <div
              className={`source-file-name ${
                isLoading ? "source-file-width-loading" : "source-file-width"
              }`}
            >
              {file.name}
            </div>
            {!isLoading && (
              <div onClick={handleRemoveFile} className="remove-file">
                <CloseCircle size="20px" />
              </div>
            )}
          </div>
          <button
            onClick={handleExtractClick}
            className={`extract-button ${isLoading ? "disable-opacity" : ""}`}
            disabled={isLoading}
          >
            Extract
          </button>
        </div>
      )}

      {!file && (
        <div
          {...getRootProps()}
          className={`dropzone ${viewSource ? "source-file-margin" : ""}`}
          data-testid="dropzone"
        >
          {!isUploading ? (
            <>
              <input {...getInputProps()} />
              <div data-testid="upload-text">File Extraction</div>
              <div className="drag-drop">
                <UploadIcon />
                Drag and drop a PDF file or{" "}
                <button className="browse-button">Browse Files</button>
              </div>
            </>
          ) : (
            <div>Uploading ...</div>
          )}
        </div>
      )}
      <div className={`${viewSource ? "source-file-margin" : ""}`}>
        <div className="prompt-label">Choose Prompt: (Optional)</div>
        <textarea
          className="prompt-textarea"
          placeholder="Provide the list of fields to be extracted from the file in comma seperated format like:  name, address, email"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        ></textarea>
      </div>
      {!isLoading && (extractedResponse || summary) ? (
        <InvoiceResponse
          file={storedFile as File}
          viewSource={viewSource}
          setViewSource={setViewSource}
        />
      ) : (
        <div className="empty-invoice-screen">
          {isLoading ? (
            <CommonLoader loaderMsg="Extracting Document" />
          ) : (
            <div className="d-flex flex-column align-items-center gap-7">
              <ContractIcon />
              <div>
                Welcome to <span className="title-bold">File Extraction</span>
              </div>
              <div className="d-flex flex-column gap-3">
                <div>1. Upload a PDF file above</div>
                <div>2. Extract and summarize the File</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AWSInvoiceReader;
