import { useEffect, useState } from "react";
import "./index.css";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import QuestionBubbleFileInput from "../QuestionBubbleFileInput";
import { QAFileInput } from "../../data/api/QA/qaSlice";

type QuestionBubbleProps = {
  question: string;
  files?: QAFileInput[];
};

function QuestionBubble({ question, files }: QuestionBubbleProps) {
  const [rackerName, setRackerName] = useState<string>("");

  const { user } = useAppSelector((state: RootState) => state.userDetails);

  useEffect(() => {
    const { firstName, lastName } = user;
    if (firstName && lastName) {
      setRackerName((firstName[0] + lastName[0]).toUpperCase());
    }
  }, [user]);

  return (
    <div
      data-testid="question-bubble"
      className="question-bubble-container d-flex justify-content-end align-items-end"
    >
      <div className="question-bubble d-flex flex-column align-items-end">
        {files && files.length > 0 && <QuestionBubbleFileInput files={files} />}
        <div className="question-bubble-txt">{question}</div>
      </div>
      <div className="flex-shrink-0 name-badge">{rackerName}</div>
    </div>
  );
}

export default QuestionBubble;
