import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "@mdxeditor/editor/style.css";
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";
import logo from "../../svg/fair-logo-red.svg";
import { Rating } from "../Rating";
import { useState } from "react";
import "./index.css";
import { Document, Page, pdfjs } from "react-pdf";
import { CloseCircle } from "../../svg/closeCircle";
import { useAppDispatch } from "../../redux/hooks";
import { getDownloadInvoice } from "../../data/api/Invoice/invoiceSlice";
import { Spinner } from "react-bootstrap";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InvoiceResponse = ({
  file,
  viewSource,
  setViewSource,
}: {
  file: File;
  viewSource: boolean;
  setViewSource: any;
}) => {
  const dispatch = useAppDispatch();
  const [numPages, setNumPages] = useState<number>(0);

  const { summary, extractedResponse, fileId, isDownloading } = useAppSelector(
    (state: RootState) => state.invoiceDetails
  );

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const downloadInvoice = () => {
    let fileName = `File Summary.pdf`;
    dispatch(
      getDownloadInvoice({
        queryId: fileId,
        fileName,
      })
    );
  };

  return (
    <div
      className="invoice-response-container"
      data-testid="invoice-response-container"
    >
      <div className={viewSource ? "pdf-container" : "extracted-content"}>
        <div className={viewSource ? "width-50per" : ""}>
          <div className="response-header">
            <div className="response-heading">
              <img src={logo} alt="FairLogoRound" />
              File Summary
            </div>
          </div>
          <div className="response-content">
            <div
              className={`invoice-markdown-container ${
                viewSource ? "scrollable-container" : ""
              }`}
            >
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {summary}
              </ReactMarkdown>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {extractedResponse}
              </ReactMarkdown>
            </div>
          </div>
          <div
            className={`response-footer position-relative`}
            data-testid="response-footer"
          >
            <div className="d-flex gap-10 align-items-center">
              {isDownloading ? (
                <button
                  disabled
                  className="download-btn d-flex align-items-center justify-content-center"
                >
                  <Spinner animation="border" variant="primary" />
                </button>
              ) : (
                <button className="download-btn" onClick={downloadInvoice}>
                  Download
                </button>
              )}
              {!viewSource && (
                <div className="edit-text" onClick={() => setViewSource(true)}>
                  View Document Source
                </div>
              )}
            </div>
            <div className="cursor-pointer">
              <Rating
                rating={0}
                isFavourite={false}
                setFavourite={() => {}}
                setRating={() => {}}
                searchResult={{}}
                type="interview"
                onFavoriteClick={() => {}}
              />
            </div>
          </div>
        </div>
        {viewSource && (
          <div className={viewSource ? "width-50per" : ""}>
            <div className="response-header">
              <div className="source-view-header">
                <div>
                  <div>Document Source</div>
                  <div className="uploaded-file-name">{file.name}</div>
                </div>
                <div
                  onClick={() => setViewSource(false)}
                  className="close-view-source"
                  data-testid="close-view-source-button"
                >
                  <CloseCircle size="24px" />
                </div>
              </div>
            </div>
            <div className="response-content">
              {file && (
                <div className="pdf-viewer-container">
                  <div
                    className={`pdf-viewer ${
                      viewSource ? "scrollable-container" : ""
                    }`}
                    data-testid="pdf-page"
                  >
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(new Array(numPages), (_, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          renderMode="canvas"
                          scale={1}
                        />
                      ))}
                    </Document>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`response-footer position-relative`}
              data-testid="response-footer"
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceResponse;