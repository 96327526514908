function PromptSearch() {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>FC22B26F-7615-4F59-A197-0572FC524393</title>
      <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Search---Landing" transform="translate(-818, -158)">
          <g id="Group-7" transform="translate(104, 149)">
            <g id="send-button" transform="translate(714, 9)">
              <circle id="Oval" stroke="#367ED4" fill="#367ED4" cx="12" cy="12" r="11.5"></circle>
              <path d="M7,18 L7,6 L21.2366154,12 L7,18 Z M7.92307692,16.6153846 L18.8615385,12 L7.92307692,7.38461538 L7.92307692,10.9707692 L12.3963077,12 L7.92307692,13.0292308 L7.92307692,16.6153846 Z M7.92307692,16.6153846 L7.92307692,7.38461538 L7.92307692,16.6153846 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { PromptSearch };
