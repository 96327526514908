function ChatBubblesMobile() {
  return (
    <svg
      x="347"
      y="328"
      width="359px"
      height="297px"
      viewBox="0 0 359 297"
      version="1.1"
      className="welcome-banner"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>0E0C00F7-E8B1-42FF-8D67-70D508A6AD1C</title>
      <defs>
        <path
          d="M94.4696674,297 C99.0364074,297 96.5517683,283.477617 100.929746,282.803924 C142.879215,276.348655 175,240.128507 175,196.41356 C175,148.136384 135.824916,109 87.5,109 C39.1750844,109 0,148.136384 0,196.41356 C0,239.703607 31.4994043,275.643842 72.8498392,282.607277 C77.6135196,283.409483 89.4776069,297 94.4696674,297 Z"
          id="path-1"
        />
        <filter
          x="-0.3%"
          y="-0.3%"
          width="100.6%"
          height="101.1%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <path
          d="M278.469667,297 C283.036407,297 280.551768,283.477617 284.929746,282.803924 C326.879215,276.348655 359,240.128507 359,196.41356 C359,148.136384 319.824916,109 271.5,109 C223.175084,109 184,148.136384 184,196.41356 C184,239.703607 215.499404,275.643842 256.849839,282.607277 C261.61352,283.409483 273.477607,297 278.469667,297 Z"
          id="path-3"
        />
        <filter
          x="-0.3%"
          y="-0.3%"
          width="100.6%"
          height="101.1%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <path
          d="M192.469667,188 C197.036407,188 194.551768,174.477617 198.929746,173.803924 C240.879215,167.348655 273,131.128507 273,87.4135598 C273,39.1363838 233.824916,0 185.5,0 C137.175084,0 98,39.1363838 98,87.4135598 C98,130.703607 129.499404,166.643842 170.849839,173.607277 C175.61352,174.409483 187.477607,188 192.469667,188 Z"
          id="path-5"
        />
        <filter
          x="-0.3%"
          y="-0.3%"
          width="100.6%"
          height="101.1%"
          filterUnits="objectBoundingBox"
          id="filter-6"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
      </defs>
      <g
        id="ICE-V.1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Mobile---Landing" transform="translate(-8.000000, -344.000000)">
          <g id="Chat-Bubbles" transform="translate(8.000000, 344.000000)">
            <g id="Oval-Copy-2">
              <use fill="black" fillOpacity="1" filter="url(#filter-2)" />
              <path
                stroke="#9E9E9E"
                strokeWidth="2"
                d="M87.5,110 C111.386429,110 133.011576,119.672248 148.665086,135.310295 C164.318239,150.947984 174,172.551227 174,196.41356 C174,217.864857 166.175985,237.490598 153.223121,252.597912 C140.081924,267.924885 121.662115,278.601815 100.777654,281.815558 C99.5685293,282.001621 98.6742155,282.838331 98.0649688,284.213474 C97.436404,285.632221 97.1188169,287.654043 96.8272216,289.687454 L96.7340895,290.335874 C96.4748198,292.127745 96.2141288,293.874252 95.6335136,295.037747 C95.4704981,295.364414 95.2870809,295.640257 95.04447,295.818888 C94.88275,295.937961 94.6924974,296 94.4696674,296 C92.3186887,296 88.8732417,293.235772 85.3115626,290.256637 L84.7170752,289.758562 C84.4194954,289.508967 84.1215631,289.258863 83.8239599,289.009725 L83.0411708,288.35408 C79.0849869,285.041044 75.2693584,282.000645 73.0159015,281.621162 C52.4177746,278.152426 34.2927638,267.409697 21.3782665,252.129656 C8.663656,237.086115 1,217.643905 1,196.41356 C1,172.551227 10.6817612,150.947984 26.3349139,135.310295 C41.9884245,119.672248 63.6135714,110 87.5,110 Z"
                strokeLinecap="square"
                fill="#FFFFFF"
                fillRule="evenodd"
              />
              <use xlinkHref="#path-1" />
            </g>
            <g
              id="Oval-Copy-3"
              transform="translate(271.500000, 203.000000) scale(-1, 1) translate(-271.500000, -203.000000) "
            >
              <use fill="black" fillOpacity="1" filter="url(#filter-4)" />
              <path
                stroke="#9E9E9E"
                strokeWidth="2"
                d="M271.5,110 C295.386429,110 317.011576,119.672248 332.665086,135.310295 C348.318239,150.947984 358,172.551227 358,196.41356 C358,217.864857 350.175985,237.490598 337.223121,252.597912 C324.081924,267.924885 305.662115,278.601815 284.777654,281.815558 C283.568529,282.001621 282.674215,282.838331 282.064969,284.213474 C281.436404,285.632221 281.118817,287.654043 280.827222,289.687454 L280.73409,290.335874 C280.47482,292.127745 280.214129,293.874252 279.633514,295.037747 C279.470498,295.364414 279.287081,295.640257 279.04447,295.818888 C278.88275,295.937961 278.692497,296 278.469667,296 C276.318689,296 272.873242,293.235772 269.311563,290.256637 L268.717075,289.758562 C268.419495,289.508967 268.121563,289.258863 267.82396,289.009725 L267.041171,288.35408 C263.084987,285.041044 259.269358,282.000645 257.015901,281.621162 C236.417775,278.152426 218.292764,267.409697 205.378267,252.129656 C192.663656,237.086115 185,217.643905 185,196.41356 C185,172.551227 194.681761,150.947984 210.334914,135.310295 C225.988424,119.672248 247.613571,110 271.5,110 Z"
                strokeLinecap="square"
                fill="#FFFFFF"
                fillRule="evenodd"
              />
              <use xlinkHref="#path-3" />
            </g>
            <g
              id="Oval-Copy"
              transform="translate(185.500000, 94.000000) scale(-1, 1) translate(-185.500000, -94.000000) "
            >
              <use fill="black" fillOpacity="1" filter="url(#filter-6)" />
              <path
                stroke="#9E9E9E"
                strokeWidth="2"
                d="M185.5,1 C209.386429,1 231.011576,10.6722484 246.665086,26.310295 C262.318239,41.9479842 272,63.5512272 272,87.4135598 C272,108.864857 264.175985,128.490598 251.223121,143.597912 C238.081924,158.924885 219.662115,169.601815 198.777654,172.815558 C197.568529,173.001621 196.674215,173.838331 196.064969,175.213474 C195.436404,176.632221 195.118817,178.654043 194.827222,180.687454 L194.73409,181.335874 C194.47482,183.127745 194.214129,184.874252 193.633514,186.037747 C193.470498,186.364414 193.287081,186.640257 193.04447,186.818888 C192.88275,186.937961 192.692497,187 192.469667,187 C190.318689,187 186.873242,184.235772 183.311563,181.256637 L182.717075,180.758562 C182.419495,180.508967 182.121563,180.258863 181.82396,180.009725 L181.041171,179.35408 C177.084987,176.041044 173.269358,173.000645 171.015901,172.621162 C150.417775,169.152426 132.292764,158.409697 119.378267,143.129656 C106.663656,128.086115 99,108.643905 99,87.4135598 C99,63.5512272 108.681761,41.9479842 124.334914,26.310295 C139.988424,10.6722484 161.613571,1 185.5,1 Z"
                strokeLinecap="square"
                fill="#FFFFFF"
                fillRule="evenodd"
              />
              <use xlinkHref="#path-5" />
            </g>
          </g>
        </g>
      </g>
      <foreignObject x="5" y="5" width="380" height="550">
        <div className="mx-auto mt-10 welcome-chat-bubbles">
          <span className="left-text-mobile">
            Provide a detailed customer story on security
          </span>
          <span className="centered-text-mobile">
            Give me a good example of a customer with a healthcare migration
            solution
          </span>
          <span className="right-text-mobile">
            What are the key objectives of the Rackspace Technical Community
          </span>
        </div>
      </foreignObject>
    </svg>
  );
}

export { ChatBubblesMobile };
