function ArrowUpIcon() {
  return (
    <svg
      width="15px"
      height="8px"
      viewBox="0 0 15 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>994A0794-0D92-412E-81EE-ADF7DC084396</title>
      <g
        id="ICE-V.2"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="HR-Interview---Question-Details-Expanded"
          transform="translate(-897, -165)"
          fill="#367ED4"
          fill-rule="nonzero"
        >
          <path
            d="M897.146447,165.146447 C897.320013,164.97288 897.589437,164.953595 897.784306,165.088591 L897.853553,165.146447 L904.5,171.793 L911.146447,165.146447 C911.320013,164.97288 911.589437,164.953595 911.784306,165.088591 L911.853553,165.146447 C912.02712,165.320013 912.046405,165.589437 911.911409,165.784306 L911.853553,165.853553 L904.853553,172.853553 C904.679987,173.02712 904.410563,173.046405 904.215694,172.911409 L904.146447,172.853553 L897.146447,165.853553 C896.951184,165.658291 896.951184,165.341709 897.146447,165.146447 Z"
            id="arrow-up-icon"
            transform="translate(904.5, 169) scale(1, -1) rotate(-360) translate(-904.5, -169)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export { ArrowUpIcon };
