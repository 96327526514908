import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { DefaultFavourite } from "../../svg/defaultFavorite";
import { DefaultRatingStar } from "../../svg/deafultRatingStar";
import { GrCopy } from "react-icons/gr";
import "./index.css";

type RatingProps = {
  rating: number;
  isFavourite: boolean;
  setRating: (rating: number) => void;
  // setFavoriteSearchResult: (isFavourite: boolean, searchResult:any) => void;
  setFavourite: (isFavourite: boolean, searchResult: any) => void;
  searchResult: any;
  type?: string;
  onFavoriteClick: () => void;
  copyToClipboard?: () => void;
  copied?: boolean;
};

type DefaultRatingProps = {
  rating: number;
};

function Rating({
  rating,
  isFavourite,
  setRating,
  setFavourite,
  searchResult,
  type,
  onFavoriteClick,
  copyToClipboard,
  copied,
}: RatingProps) {
  const [showDefaultRating, setDefaultRating] = useState(true);
  const [currentHoveringRating, setCurrentHoveringRating] = useState(0);
  const [currentRatingClass, setCurrentRatingClass] = useState("rating-none");

  function DefaultRating({ rating }: DefaultRatingProps) {
    if (!rating) {
      return <DefaultRatingStar id="star-default" className="rating-none" />;
    } else {
      let ratingClass = "";

      switch (rating) {
        case 1:
          ratingClass = "rating-one";
          break;
        case 2:
          ratingClass = "rating-two";
          break;
        case 3:
          ratingClass = "rating-three";
          break;
        case 4:
          ratingClass = "rating-four";
          break;
        case 5:
          ratingClass = "rating-five";
          break;
        default:
          break;
      }
      return <DefaultRatingStar id="star-default" className={ratingClass} />;
    }
  }

  function SetRating({ setRating }: RatingProps) {
    const mouseEnterForOneRating = () => {
      setCurrentHoveringRating(1);
      setCurrentRatingClass("rating-one");
    };

    const mouseEnterForTwoRating = () => {
      setCurrentHoveringRating(2);
      setCurrentRatingClass("rating-two");
    };

    const mouseEnterForThreeRating = () => {
      setCurrentHoveringRating(3);
      setCurrentRatingClass("rating-three");
    };

    const mouseEnterForFourRating = () => {
      setCurrentHoveringRating(4);
      setCurrentRatingClass("rating-four");
    };

    const mouseEnterForFiveRating = () => {
      setCurrentHoveringRating(5);
      setCurrentRatingClass("rating-five");
    };

    return (
      <>
        <span
          onMouseEnter={() => mouseEnterForOneRating()}
          onClick={() => setRating(1)}
        >
          <DefaultRatingStar
            id="star-one"
            className={
              currentHoveringRating >= 1 ? currentRatingClass : "rating-none"
            }
          />
        </span>
        <span
          onMouseEnter={() => mouseEnterForTwoRating()}
          onClick={() => setRating(2)}
        >
          <DefaultRatingStar
            id="star-two"
            className={
              currentHoveringRating >= 2 ? currentRatingClass : "rating-none"
            }
          />
        </span>
        <span
          onMouseEnter={() => mouseEnterForThreeRating()}
          onClick={() => setRating(3)}
        >
          <DefaultRatingStar
            id="star-three"
            className={
              currentHoveringRating >= 3 ? currentRatingClass : "rating-none"
            }
          />
        </span>
        <span
          onMouseEnter={() => mouseEnterForFourRating()}
          onClick={() => setRating(4)}
        >
          <DefaultRatingStar
            id="star-four"
            className={
              currentHoveringRating >= 4 ? currentRatingClass : "rating-none"
            }
          />
        </span>
        <span
          onMouseEnter={() => mouseEnterForFiveRating()}
          onClick={() => setRating(5)}
        >
          <DefaultRatingStar
            id="star-five"
            className={
              currentHoveringRating >= 5 ? currentRatingClass : "rating-none"
            }
          />
        </span>
      </>
    );
  }

  const setPromptRating = (rating: number) => {
    setRating(rating);
    setDefaultRating(true);
  };

  return (
    <div className="align-rating">
      <Button
        className="rating-btn"
        onMouseEnter={() => setDefaultRating(false)}
        onMouseLeave={() => setDefaultRating(true)}
      >
        {showDefaultRating ? (
          <DefaultRating rating={rating} />
        ) : (
          <SetRating
            setRating={setPromptRating}
            rating={rating}
            isFavourite={isFavourite}
            setFavourite={setFavourite}
            searchResult={searchResult}
            onFavoriteClick={onFavoriteClick}
          />
        )}
      </Button>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={(props: any) => (
          <Tooltip {...props} id="tooltipid">
            <span>{isFavourite ? "Remove Favorite" : "Add to Favorites"}</span>
          </Tooltip>
        )}
      >
        <Button
          className={`${
            type === "search" ? "search-favourite-btn" : "favourite-btn"
          }`}
          onClick={() =>
            type === "interview"
              ? onFavoriteClick()
              : setFavourite(!isFavourite, searchResult)
          }
        >
          <DefaultFavourite
            className={
              isFavourite ? "checked-favourite" : "unchecked-favourite"
            }
          />
        </Button>
      </OverlayTrigger>
      {type === "search" && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={(props: any) => (
            <Tooltip {...props} id="tooltipid">
              <span>{copied ? "Response Copied" : "Copy Response"}</span>
            </Tooltip>
          )}
        >
          <div className="copy-container">
            <GrCopy
              className={` copy-button ${copied ? "copied" : "uncopied"}`}
              onClick={() => {
                copyToClipboard && copyToClipboard();
              }}
            />
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
}

export { Rating };
