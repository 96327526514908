function FeedbackIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>feedback icon</title>
      <g
        id="Share-Feedback"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Share-Feedback-Panel"
          transform="translate(-982, -30)"
          fill="#000000"
          fill-rule="nonzero"
        >
          <g id="Group-3" transform="translate(962, 0)">
            <path
              d="M32.0000134,30 C38.627606,30 44,35.372394 44,41.9999865 C44,48.6275791 38.627606,53.9999866 32.0000134,53.9999866 C30.0288406,54.0029408 28.0875581,53.5181355 26.3492198,52.5887746 L20.0000134,53.9999866 L21.4112254,47.6507802 C20.4818645,45.9124419 19.9970592,43.9711594 20.0000134,41.9999865 C20.0000134,35.372394 25.3724209,30 32.0000134,30 Z M32.0000135,32.3999973 C26.6980858,32.3999973 22.4000243,36.6980589 22.4000243,41.9999865 C22.4000243,43.6019847 22.7900238,45.140383 23.528023,46.5191814 L23.9468225,47.3027806 L23.1608234,50.8391766 L26.6972194,50.0531775 L27.4808186,50.473177 C28.8710772,51.2161432 30.4236864,51.6032648 32.0000135,51.5999965 C37.3019411,51.5999965 41.6000027,47.3019142 41.6000027,41.9999865 C41.6000027,36.6980589 37.3019411,32.3999973 32.0000135,32.3999973 Z M33.2,46.8 L33.2,49.2 L30.8,49.2 L30.8,46.8 L33.2,46.8 Z M33.2,36 L33.2,44.4 L30.8,44.4 L30.8,36 L33.2,36 Z"
              id="feedback-icon"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { FeedbackIcon };
