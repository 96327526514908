import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'serialize-error';
import { RawRequestError, fetchGetApi } from '../../../api/fetch';
import { Favorites } from '../../../types';
import { FAVORITES_URL } from '../../../constants';

type InitialState = {
  isLoading: boolean,
  favorites: Favorites[]
  error: RawRequestError | null,
};

const initialState: InitialState = {
  isLoading: false,
  favorites: [] as Favorites[],
  error: null,
};

// fetch Favorites details
const fetchFavorites = createAsyncThunk(
  'login/fetchFavorites',
  async (_: void, thunkAPI) => fetchGetApi(FAVORITES_URL)
    .then((res) => res)
    .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e))),
);

const searchSlice = createSlice({
  name: 'login/fetchFavorites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFavorites.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(
        fetchFavorites.fulfilled,
      (state, action) => {
        const favorites = action.payload.data.result;
        return {
          ...state,
          isLoading: false,
          favorites: favorites,
          error: null,
        };
      },
    );
    builder.addCase(fetchFavorites.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
     // const favorites = action.payload.data.result;
      return {
        ...state,
        isLoading: false,
        favorites: [],
        error: action.payload as RawRequestError,
      };
    });
  },
});

export default searchSlice.reducer;
export {
    fetchFavorites, initialState,
};
