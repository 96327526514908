import "./index.css";

function CommonLoader({ loaderMsg }: { loaderMsg: string }) {
  return (
    <div className="loading-answer">
      <div
        className={`mx-auto spinner-border ${"custom-spinner"} loader-color`}
        role="status"
      />
      <span className="loading-text-color mt-8">{loaderMsg}</span>
    </div>
  );
}

export { CommonLoader };
