function SavedIcon({ fillColor = "#367ED4" }) {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 1H3.33333C2.04467 1 1 2.03319 1 3.30769V21L8 14.8462L15 21V3.30769C15 2.03319 13.9553 1 12.6667 1"
        stroke={fillColor}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export { SavedIcon };
