import { Offcanvas } from "react-bootstrap";
import "./index.css";
import { FeedbackRating } from "../FeedbackRating";
import { useState } from "react";
import { FeedbackIcon } from "../../svg/feedbackIcon";

type SettingsProps = {
  type?: string;
  setIsFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFeedbackOpen: boolean;
};

function Feedback({ isFeedbackOpen, setIsFeedbackOpen }: SettingsProps) {
  const [feedback, setFeedback] = useState("");

  return (
    <Offcanvas
      className="setting-offcanvas"
      show={isFeedbackOpen}
      placement="end"
      onHide={() => setIsFeedbackOpen(!isFeedbackOpen)}
    >
      <Offcanvas.Header>
        <Offcanvas.Title className="feedback-title">
          <FeedbackIcon />
          &nbsp;Share Feedback
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="setting-offcanvas-body">
        <>
          <div>
            <div className="datasets-component">
              <div className="rating-text">
                How would you rate your experience using our app?
              </div>
              <FeedbackRating rating={0} setRating={() => {}} />
            </div>
          </div>
          <div className="settings-padding">
            <div className="setting-offcanvas-body-data">
              <div className="feedback-text">Your Feedback</div>
              <div>
                <textarea
                  className="feedback-textarea"
                  placeholder="Please share what you like or dislike about our application.

Feel free to offer suggestions or features you’d like to see added in the future."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
                <div className="screenshot-text">
                  <input type="checkbox" className="screenshot-checkbox" />
                  Include a screenshot of previous screen
                </div>
              </div>
            </div>
          </div>
        </>
        <div className="d-flex align-items-center mt-7 gap-12 settings-padding justify-content-end">
          <div
            className="text-primary cancel-btn"
            data-testid="cancel-button"
            onClick={() => {
              setIsFeedbackOpen(!isFeedbackOpen);
            }}
          >
            Cancel
          </div>
          <button
            className={`send-btn bg-primary text-white border-0 ${
              feedback ? "" : "disabled-opacity"
            }`}
            disabled={!feedback}
            onClick={() => {
              setIsFeedbackOpen(!isFeedbackOpen);
            }}
          >
            Send Feedback
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Feedback;
