import { InputGroup, Form, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";

type UpdateInputProps = {
  question: string;
  setShowEdit: (value: boolean) => void;
  onEditQuestion: (searchTerm: string, searchId: number) => void;
  searchId: number;
};

function UpdateInput({
  question,
  searchId,
  setShowEdit,
  onEditQuestion,
}: UpdateInputProps) {
  const [searchTerm, setSearchTerm] = useState<string>(question);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    // Set initial textarea content and adjust its height
    if (textareaRef.current && searchTerm !== "") {
      textareaRef.current.value = searchTerm;
      adjustTextareaHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const submitSearch = () => {
    onEditQuestion(searchTerm, searchId);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submitSearch();
    }
  };

  return (
    <>
      <InputGroup className="ms-5 mb-3 mx-auto ama" data-testid="update-input">
        <Form.Control
          as="textarea"
          rows={1}
          ref={textareaRef}
          placeholder="Update Your Prompt..."
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
        />
      </InputGroup>
      <Button
        variant="primary"
        data-testid="update-button"
        onClick={submitSearch}
      >
        Update
      </Button>
      <Button
        className="text-primary"
        variant="light"
        onClick={() => setShowEdit(false)}
        data-testid="cancel-button"
      >
        Cancel
      </Button>
    </>
  );
}

export { UpdateInput };
