function CloseCircle({
  size = "20px",
  color = "#E60013",
}: {
  size: string;
  color?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>B6D8DA23-7BAD-4CF6-A169-63341D61B21F</title>
      <g
        id="Contract-Extraction"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contract-Uploaded"
          transform="translate(-874, -184)"
          fill={color}
          fill-rule="nonzero"
        >
          <path
            d="M884,184 C889.522847,184 894,188.477152 894,194 C894,199.522847 889.522847,204 884,204 C878.477152,204 874,199.522847 874,194 C874,188.477152 878.477152,184 884,184 Z M884,185.428571 C879.266131,185.428571 875.428571,189.266131 875.428571,194 C875.428571,198.733869 879.266131,202.571429 884,202.571429 C888.733869,202.571429 892.571429,198.733869 892.571429,194 C892.571429,189.266131 888.733869,185.428571 884,185.428571 Z M880.834722,189.840845 L880.933648,189.923495 L884,192.99 L887.066352,189.923495 L887.165278,189.840845 C887.443661,189.647993 887.828553,189.675543 888.076505,189.923495 C888.355451,190.202441 888.355451,190.654702 888.076505,190.933648 L885.01,194 L888.076505,197.066352 C888.355451,197.345298 888.355451,197.797559 888.076505,198.076505 C887.828553,198.324457 887.443661,198.352007 887.165278,198.159155 L887.066352,198.076505 L884,195.01 L880.933648,198.076505 L880.834722,198.159155 C880.556339,198.352007 880.171447,198.324457 879.923495,198.076505 C879.644549,197.797559 879.644549,197.345298 879.923495,197.066352 L882.99,194 L879.923495,190.933648 C879.644549,190.654702 879.644549,190.202441 879.923495,189.923495 C880.171447,189.675543 880.556339,189.647993 880.834722,189.840845 Z"
            id="close-xmark-circle"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export { CloseCircle };
