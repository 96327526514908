import { Button } from "react-bootstrap";
import { SUPPORTED_IMAGE_TYPES } from "../../constants";
import './index.css';
import { RemoveIcon } from "../../svg/removeIcon";
import FileIcon from "../FileIcons";
import { getFileExtension } from "../../utils/file";

type FileInputProps = {
  file: File;
  onRemoveFile: (file: File) => void;
};

function FileInput ({
  file,
  onRemoveFile
}: FileInputProps) {
  if (!file) {
    return;
  }
  const fileExtension= getFileExtension(file.name);
  if (!fileExtension) {
    return;
  }
  const isImage = SUPPORTED_IMAGE_TYPES.includes(fileExtension);

  const onRemoveFileClick = () => {
    onRemoveFile(file);
  };

  return (
    isImage ? (
    <div className="d-flex w-100 h-100 file-input-sub-container">
      <img src={URL.createObjectURL(file)} className="file-input-img" />
      <Button
          className="remove-file-button"
          variant="primary"
          id="remove-file"
          data-testid="remove-file"
          onClick={onRemoveFileClick}
        >
          <RemoveIcon />
      </Button>
    </div>
    ) : (
      <div className="d-flex w-100 h-100 file-input-sub-container">
        <div className="d-flex align-items-center file-icon-name-container">
          <div className="file-icon-container">
            <FileIcon url={file.name} />
          </div>
          <div className="file-name-txt">
            {file.name}
          </div>
        </div>

        <Button
          className="remove-file-button"
          variant="primary"
          id="remove-file"
          data-testid="remove-file"
          onClick={onRemoveFileClick}
        >
          <RemoveIcon />
        </Button>
      </div>
    ) 
  )
}

export default FileInput;