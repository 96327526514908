import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDownloadApi,
  fetchUploadApi,
  RawRequestError,
} from "../../../api/fetch";
import { FILE, FILES } from "../../../constants";
import { serializeError } from "serialize-error";

interface InitialState {
  isLoading: boolean;
  summary: string;
  extractedResponse: string;
  error: RawRequestError | null;
  fileId: string;
  file: string;
  isDownloading: boolean;
}

const initialState: InitialState = {
  isLoading: false,
  summary: "",
  extractedResponse: "",
  fileId: "",
  error: null,
  file: "",
  isDownloading: false,
};

type InvoicePostRequest = {
  file: File;
  prompt?: string;
};

const fetchInvoice = createAsyncThunk(
  "contract/fetchInvoice",
  async (params: InvoicePostRequest, thunkAPI) => {
    const { file } = params;
    const formData = new FormData();
    formData.append("file_data", file);

    if (params.prompt) {
      formData.append("key_values", params.prompt);
    }

    return fetchUploadApi(formData, FILE)
      .then((res: any) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getDownloadInvoice = createAsyncThunk(
  "interview/getDownloadInvoice",
  async (params: { queryId: string; fileName: string }, thunkAPI) => {
    const endpoint = `${FILES}/${params.queryId}/download`;

    return fetchDownloadApi(endpoint)
      .then(async (res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = params.fileName;
        a.style.display = "none";
        document.body.appendChild(a);

        a.click();
        return { data: params.fileName };
      })
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoice.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          summary: "",
          extractedResponse: "",
          error: null,
        };
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        const { data } = action.payload;
        return {
          ...state,
          isLoading: false,
          summary: data.summary,
          extractedResponse: data.extracted_details,
          fileId: data.file_id,
          error: null,
        };
      })
      .addCase(fetchInvoice.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: action.payload as RawRequestError,
        };
      })
      .addCase(getDownloadInvoice.pending, (state) => ({
        ...state,
        isDownloading: true,
      }))
      .addCase(getDownloadInvoice.fulfilled, (state, action) => {
        const file = action.payload.data;
        return {
          ...state,
          isDownloading: false,
          file,
          error: null,
        };
      })
      .addCase(getDownloadInvoice.rejected, (state, action) => ({
        ...state,
        isDownloading: false,
        error: action.payload as RawRequestError,
      }));
  },
});

export default invoiceSlice.reducer;
export { fetchInvoice, getDownloadInvoice };