function IceLogoMobile() {
  return (
    <svg width="172.29004px" height="51px" viewBox="0 0 172.29004 51" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Single image logos</title>
      <g id="ICE-V.1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Mobile---Landing" transform="translate(-15, -13.5)">
          <g id="Single-image-logos" transform="translate(15, 14)">
            <g id="FAIR-Logo-V-CLR" fillRule="nonzero">
              <path d="M29.24023,0 L35.2206651,0.000172219559 L35.2206651,5.9960162 L23.2597989,5.9960162 L29.24023,0 Z M23.2597909,17.9880446 L35.2206612,17.9880446 L35.2206612,11.9922006 L29.240226,11.9920284 L23.2597909,17.9880446 L23.2597909,17.9880446 Z" id="Shape" fill="#000000" />
              <path d="M23.2597949,5.9960122 L23.2597949,17.9880446 L17.2795316,17.9880446 L17.2793598,11.9920284 L23.2597949,5.9960122 L23.2597949,5.9960122 Z M17.2793678,23.9840728 L17.2795396,29.980073 L23.2597869,29.980073 L23.2597869,17.9880686 L17.2793678,23.9840688 L17.2793678,23.9840728 Z" id="Shape" fill="#EB0000" />
              <path d="M0,38.0079876 L8.6183622,38.0079876 L8.6183622,40.0105687 L1.9973772,40.0105687 L1.9973772,43.7568007 L7.06493582,43.7568007 L7.06493582,45.7593817 L1.9973772,45.7593817 L1.9973772,50 L0,50 L0,38.0079876 L0,38.0079876 Z M53.8777445,49.1854976 L54.2332407,49.1854976 L54.2332407,49.999988 L54.4195415,49.999988 L54.4195415,49.1854976 L54.7750377,49.1854976 L54.7750377,48.9987115 L53.8777445,48.9987115 L53.8777445,49.1854976 Z M55.8095088,48.9987115 L55.4892179,49.5565587 L55.168927,48.9987115 L54.9784438,48.9987115 L54.9784438,49.999988 L55.1647445,49.999988 L55.1647445,49.3658035 L55.3963451,49.7691978 L55.5820986,49.7691978 L55.8136992,49.3658035 L55.8136992,49.999988 L56,49.999988 L56,48.9987115 L55.8095168,48.9987115 L55.8095088,48.9987115 Z M43.3369936,49.999992 L43.3369936,38.0079876 L49.903475,38.0079876 C51.3351669,38.0079876 52.500029,39.1758846 52.500029,40.6113065 L52.500029,43.1559587 C52.500029,44.5397548 51.4174817,45.6745976 50.0570791,45.7545836 L52.5725247,49.999992 L50.2496316,49.999992 L47.7367225,45.7592776 L45.3343429,45.7592776 L45.3343429,49.999992 L43.3369936,49.999992 L43.3369936,49.999992 Z M45.3343429,43.7567206 L49.903475,43.7567206 C50.233505,43.7567206 50.5026797,43.4868445 50.5026797,43.1559547 L50.5026797,40.6113025 C50.5026797,40.2804127 50.233505,40.0105366 49.903475,40.0105366 L45.3343429,40.0105366 L45.3343429,43.7567206 Z" id="Shape" fill="#000000" />
              <path d="M34.1081656,40.0105687 L34.1081656,47.9974149 L37.1132015,47.9974149 L37.1132015,49.999992 L29.106148,49.999992 L29.106148,47.9974149 L32.1107924,47.9974149 L32.1107924,40.0105687 L29.106148,40.0105687 L29.106148,38.0079916 L37.1132015,38.0079916 L37.1132015,40.0105687 L34.1081656,40.0105687 Z M23.2277255,43.7569609 L23.2277255,49.999996 L21.2303763,49.999996 L21.2303763,45.7596741 L15.5574371,45.7596741 L15.5574371,49.999996 L13.5600878,49.999996 L13.5600878,43.7569609 L15.4696615,38.0079916 L21.3181877,38.0079916 L23.2277255,43.7569609 Z M16.9097024,40.0105446 L15.665242,43.7571171 L21.1225753,43.7571171 L19.8781469,40.0105446 L16.9097024,40.0105446 Z" id="Shape" fill="#EB0000" />
            </g>
            <line x1="76" y1="5.8893862e-16" x2="76" y2="50" id="Line-3" stroke="#9E9E9E" strokeLinecap="square" />
            <path d="M172.29004,7.56925 L172.29004,44.43075 L95.74879,44.43075 L95.74879,7.56925 L172.29004,7.56925 Z M118.43484,15.41467 L103.07729,15.41467 L103.07729,18.95005 L108.9886,18.95005 L108.9886,33.04981 L103.07729,33.04981 L103.07729,36.58519 L118.43484,36.58519 L118.43484,33.04981 L112.52353,33.04981 L112.52353,18.95005 L118.43484,18.95005 L118.43484,15.41467 Z M164.96148,15.41467 L150.03771,15.41467 L150.03771,36.58519 L164.96148,36.58519 L164.96148,33.04981 L153.57265,33.04981 L153.57265,27.76785 L162.2793,27.76785 L162.2793,24.23247 L153.57265,24.23247 L153.57265,18.95006 L164.96148,18.95006 L164.96148,15.41467 Z M137.7382,15.41467 L130.02171,15.41467 C127.52588,15.41467 125.49527,17.44528 125.49527,19.94133 L125.49527,32.05853 C125.49527,34.55458 127.52588,36.58519 130.02171,36.58519 L137.7382,36.58519 C140.23403,36.58519 142.26464,34.55458 142.26464,32.05853 L142.26464,29.05573 L138.72971,29.05573 L138.72971,32.05853 C138.72971,32.60525 138.2847,33.04981 137.73821,33.04981 L130.02172,33.04981 C129.47522,33.04981 129.03022,32.60525 129.03022,32.05853 L129.03022,19.94133 C129.03022,19.39461 129.47523,18.95005 130.02172,18.95005 L137.73821,18.95005 C138.28471,18.95005 138.72971,19.39461 138.72971,19.94133 L138.72971,22.94413 L142.26464,22.94413 L142.26464,19.94133 C142.26464,17.44528 140.23403,15.41467 137.7382,15.41467 Z" id="ICE-logo" fill="#005AC8" fillRule="nonzero" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { IceLogoMobile };
