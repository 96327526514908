import { useEffect, useState } from 'react';
import { XmarkCircle, ExclamationDiamond } from '@rss-engineering/fabric-icons';
import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setError } from '../../data/api/Search/searchSlice';
import { FairUser } from '../../svg/fairUser';
import { RawRequestError } from '../../api/fetch';
import { ERROR_MESSAGE_500 } from '../../constants';
import './index.css';

type ErrorResponseProps = {
  error: RawRequestError;
};

function ErrorResponse({ error }: ErrorResponseProps) {
  const [countdown, setCountdown] = useState(5);
  const dispatch = useAppDispatch();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (error.code === 401) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [error]);

  useEffect(() => {
    if (countdown === 0 && (error.code === 401)) {
      window.location.reload(); // Refresh the screen
    }
  }, [countdown, error]);

  const getMessage = (errorCode: number | undefined) => {
    if (errorCode === 401) {
      return `System timed-out. Screen will be refreshed in ${countdown} seconds.`;
    }

    return ERROR_MESSAGE_500;
  };

  const {
    isSidePanelActive,
  } = useAppSelector((state: RootState) => state.sidePanelDetails);

  const closeComponentAndError = () => {
    dispatch(setError(null));
  };

  return (
    <div data-testid="error-response" className={`error-response mt-8 mb-10 alert-error default-padding d-flex ${!isSidePanelActive ? 'error-response-non-split-body' : ''}`}>
      <FairUser />
      <span className="d-flex flex-row w-100 ms-5">
        <span className="d-flex flex-column w-100 mt-4">
          <span className="d-flex flex-row align-items-center">
            <ExclamationDiamond size={18} className="mt-2 me-3 error-text-color" />
            <span className="error-text-color">
              {getMessage(error.code)}
            </span>
            <XmarkCircle
              className="cursor-pointer ms-auto"
              width={24}
              height={24}
              onClick={() => closeComponentAndError()}
              data-testid="close-error"
            />
          </span>
        </span>
      </span>
    </div>
  );
}

export default ErrorResponse;
