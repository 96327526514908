function CsvIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>D1B03531-BC19-4566-94EF-B476CB41CFD9</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-105, -621)">
                    <g id="CSV" transform="translate(105, 621)">
                        <g id="HTML" fill="#9E9E9E">
                            <g id="Group-21" transform="translate(3, 0)" fill-rule="nonzero">
                                <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                                <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                            </g>
                            <rect id="Rectangle" x="0" y="10" width="26" height="10" rx="2"></rect>
                        </g>
                        <g id="Group-22" transform="translate(4.3, 12)" fill="#FFFFFF">
                            <path d="M4.76069521,3.97459895 C4.71524044,4.60294434 4.48329089,5.0975918 4.06483959,5.45855617 C3.6463883,5.81952052 3.09492322,6 2.41042782,6 C1.66176097,6 1.07286311,5.74799723 0.643716585,5.24398398 C0.214570048,4.73997076 0,4.04813276 0,3.16844921 L0,2.81149734 C0,2.2499972 0.0989294926,1.75534974 0.296791446,1.32754012 C0.494653399,0.899730485 0.777404585,0.571525212 1.14505349,0.342914441 C1.51270238,0.11430367 1.93983715,0 2.4264706,0 C3.10027076,0 3.64304609,0.180479483 4.05481285,0.541443854 C4.46657962,0.902408224 4.70454515,1.40908766 4.7687166,2.06149734 L3.56550803,2.06149734 C3.53609612,1.6844901 3.43115065,1.41109711 3.25066846,1.24131017 C3.07018628,1.07152322 2.79545641,0.986631019 2.4264706,0.986631019 C2.02539907,0.986631019 1.72526839,1.13034616 1.52606953,1.41778075 C1.32687067,1.70521535 1.22459897,2.15106651 1.21925134,2.75534761 L1.21925134,3.19652408 C1.21925134,3.82754328 1.31483862,4.28876862 1.50601605,4.58021392 C1.69719348,4.87165923 1.99866105,5.0173797 2.41042782,5.0173797 C2.78208743,5.0173797 3.05949108,4.9324875 3.24264707,4.76270056 C3.42580307,4.59291361 3.53074855,4.3302157 3.55748665,3.97459895 L4.76069521,3.97459895 Z" id="Path"></path>
                            <path d="M9.41711231,4.38770055 C9.41711231,4.16042669 9.33689922,3.98596319 9.1764706,3.86430483 C9.01604199,3.74264647 8.72727483,3.6143055 8.31016044,3.47927809 C7.89304605,3.34425067 7.56283545,3.21123062 7.31951873,3.08021392 C6.6564138,2.72192336 6.32486631,2.23930786 6.32486631,1.63235295 C6.32486631,1.31684335 6.41376917,1.03542905 6.59157755,0.788101607 C6.76938592,0.540774165 7.02473096,0.347594282 7.35762033,0.208556152 C7.6905097,0.0695180226 8.06416907,0 8.47860964,0 C8.89572403,0 9.26737807,0.0755340031 9.59358291,0.22660428 C9.91978775,0.377674557 10.1731275,0.59090772 10.3536096,0.866310167 C10.5340918,1.14171261 10.6243316,1.45454371 10.6243316,1.80481285 L9.42112301,1.80481285 C9.42112301,1.53743183 9.33689926,1.32954621 9.16844922,1.18114974 C8.99999918,1.03275328 8.76337052,0.958556158 8.45855616,0.958556158 C8.16443705,0.958556158 7.93582971,1.02072131 7.77272729,1.14505349 C7.60962486,1.26938566 7.52807488,1.43315407 7.52807488,1.63636365 C7.52807488,1.82620416 7.62366216,1.98529349 7.81483959,2.11363638 C8.00601701,2.24197927 8.28743131,2.36229892 8.65909092,2.47459894 C9.34358633,2.68048233 9.84224445,2.93582737 10.1550802,3.24064173 C10.467916,3.54545609 10.6243316,3.92513143 10.6243316,4.37967916 C10.6243316,4.88502929 10.433157,5.2814157 10.0508022,5.56885026 C9.66844731,5.85628491 9.15374657,6 8.5066845,6 C8.0574844,6 7.64839758,5.91778158 7.27941177,5.75334227 C6.91042597,5.58890295 6.62901167,5.36363782 6.43516043,5.07754013 C6.2413092,4.79144244 6.14438503,4.45989495 6.14438503,4.08288772 L7.35160429,4.08288772 C7.35160429,4.72727597 7.73662718,5.04946526 8.5066845,5.04946526 C8.79278219,5.04946526 9.01604199,4.99131076 9.1764706,4.87500002 C9.33689922,4.75868928 9.41711231,4.59625775 9.41711231,4.38770055 Z" id="Path"></path>
                            <polygon id="Path" points="14.7239072 4.51237559 16.0838042 0 17.4601546 0 15.3709132 6 14.0810856 6 12 0 13.3722371 0"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}


export {CsvIcon};
