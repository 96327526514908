function NewChatButton(){

return(
<svg width="81px" height="42px" viewBox="0 0 81 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>712F6E80-B300-4972-8F3C-03388FAEE2AF</title>
    <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Q&amp;A" transform="translate(-50, -765)">
            <g id="new-chat-button" transform="translate(50, 765)">
                <g id="send-button---disabled-copy-2">
                    <rect id="Rectangle" stroke="#367ED4" stroke-width="2" fill="#FFFFFF" x="1" y="1" width="79" height="40"></rect>
                    <text id="New" font-family="OpenSansRoman-SemiBold, Open Sans" font-size="16" font-weight="500" fill="#367ED4">
                        <tspan x="36" y="27">New</tspan>
                    </text>
                </g>
                <g id="Group" transform="translate(11, 11)" fill="#367ED4">
                    <path d="M0,10 C0,4.477 4.477,0 10,0 C15.523,0 20,4.477 20,10 C20,15.523 15.523,20 10,20 C4.477,20 0,15.523 0,10 Z M10,2 C5.581722,2 2,5.581722 2,10 C2,14.418278 5.581722,18 10,18 C14.418278,18 18,14.418278 18,10 C18,5.581722 14.418278,2 10,2 Z" id="Shape"></path>
                    <path d="M11,5 C11,4.44771525 10.5522847,4 10,4 C9.44771525,4 9,4.44771525 9,5 L9,9 L5,9 C4.44771525,9 4,9.44771525 4,10 C4,10.5522847 4.44771525,11 5,11 L9,11 L9,15 C9,15.5522847 9.44771525,16 10,16 C10.5522847,16 11,15.5522847 11,15 L11,11 L15,11 C15.5522847,11 16,10.5522847 16,10 C16,9.44771525 15.5522847,9 15,9 L11,9 L11,5 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
);
}

export {NewChatButton}