function XlsxIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>150433F9-1C07-4F0D-B5DE-57367BA66F8A</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-193, -621)">
                    <g id="XLSX" transform="translate(193, 621)">
                        <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                            <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                            <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                        </g>
                        <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                        <path d="M16.6345356,16.3877006 C16.6345356,16.1604267 16.5543225,15.9859632 16.3938939,15.8643048 C16.2334653,15.7426465 15.9446981,15.6143055 15.5275837,15.4792781 C15.1104694,15.3442507 14.7802588,15.2112306 14.536942,15.0802139 C13.8738371,14.7219234 13.5422896,14.2393079 13.5422896,13.6323529 C13.5422896,13.3168433 13.6311925,13.035429 13.8090008,12.7881016 C13.9868092,12.5407742 14.2421543,12.3475943 14.5750436,12.2085562 C14.907933,12.069518 15.2815924,12 15.6960329,12 C16.1131473,12 16.4848014,12.075534 16.8110062,12.2266043 C17.1372111,12.3776746 17.3905508,12.5909077 17.5710329,12.8663102 C17.7515151,13.1417126 17.8417549,13.4545437 17.8417549,13.8048128 L16.6385463,13.8048128 C16.6385463,13.5374318 16.5543226,13.3295462 16.3858725,13.1811497 C16.2174225,13.0327533 15.9807938,12.9585562 15.6759795,12.9585562 C15.3818603,12.9585562 15.153253,13.0207213 14.9901506,13.1450535 C14.8270482,13.2693857 14.7454982,13.4331541 14.7454982,13.6363636 C14.7454982,13.8262042 14.8410855,13.9852935 15.0322629,14.1136364 C15.2234403,14.2419793 15.5048546,14.3622989 15.8765142,14.4745989 C16.5610096,14.6804823 17.0596677,14.9358274 17.3725035,15.2406417 C17.6853393,15.5454561 17.8417549,15.9251314 17.8417549,16.3796792 C17.8417549,16.8850293 17.6505803,17.2814157 17.2682255,17.5688503 C16.8858706,17.8562849 16.3711699,18 15.7241078,18 C15.2749077,18 14.8658209,17.9177816 14.4968351,17.7533423 C14.1278493,17.588903 13.846435,17.3636378 13.6525837,17.0775401 C13.4587325,16.7914424 13.3618083,16.459895 13.3618083,16.0828877 L14.5690276,16.0828877 C14.5690276,16.727276 14.9540505,17.0494653 15.7241078,17.0494653 C16.0102055,17.0494653 16.2334653,16.9913108 16.3938939,16.875 C16.5543225,16.7586893 16.6345356,16.5962577 16.6345356,16.3877006 Z" id="Path" fill="#FFFFFF"></path>
                        <polygon id="Path" fill="#FFFFFF" points="4.59199546 14.0686745 5.71700433 12 7.13867284 12 5.39148011 14.9753197 7.18406184 18 5.74586889 18 4.59199546 15.8983476 3.43819295 18 2 18 3.79258174 14.9753197 2.04531808 12 3.4669866 12"></polygon>
                        <polygon id="Path-Copy" fill="#FFFFFF" points="21.5919955 14.0686745 22.7170043 12 24.1386728 12 22.3914801 14.9753197 24.1840618 18 22.7458689 18 21.5919955 15.8983476 20.438193 18 19 18 20.7925817 14.9753197 19.0453181 12 20.4669866 12"></polygon>
                        <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="9.5785898 17.0069029 12.2035632 17.0069029 12.2035632 18 8.34230697 18 8.34230697 12 9.5785898 12"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { XlsxIcon }