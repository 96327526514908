import { createSlice } from "@reduxjs/toolkit";
import { HISTORY_TAB, PROMPT_TAB } from "../../../constants";

export enum SidePanelState {
  search,
  qa,
  ask,
  // admin,
  interview,
  contract,
  invoice,
}

type InitialState = {
  isSidePanelActive: boolean;
  sidePanelActiveTab: string;
  isSidePanelActiveMobileView: boolean;
  questionFromCatagory: string | null;
  sidePanelState: SidePanelState;
};

const initialState: InitialState = {
  isSidePanelActive: false,
  sidePanelActiveTab: PROMPT_TAB,
  isSidePanelActiveMobileView: false,
  questionFromCatagory: null,
  sidePanelState: SidePanelState.search,
};

const sidePanelSlice = createSlice({
  name: "sidePanel",
  initialState,
  reducers: {
    setIsSidePanelActive: (state, action) => ({
      ...state,
      isSidePanelActiveMobileView: false,
      sidePanelActiveTab:
        state.sidePanelState === SidePanelState.search
          ? PROMPT_TAB
          : HISTORY_TAB,
      isSidePanelActive: action.payload,
    }),
    setIsSidePanelActiveMobileView: (state, action) => ({
      ...state,
      isSidePanelActive: false,
      sidePanelActiveTab:
        state.sidePanelState === SidePanelState.search
          ? PROMPT_TAB
          : HISTORY_TAB,
      isSidePanelActiveMobileView: action.payload,
    }),
    setSidePanelActiveTab: (state, action) => ({
      ...state,
      sidePanelActiveTab: action.payload,
    }),
    setQuestionFromCategory: (state, action) => ({
      ...state,
      questionFromCatagory: action.payload,
    }),
    setSidePanelState: (state, action) => ({
      ...state,
      sidePanelState: action.payload,
    }),
  },
});

const {
  setIsSidePanelActive,
  setQuestionFromCategory,
  setIsSidePanelActiveMobileView,
  setSidePanelActiveTab,
  setSidePanelState,
} = sidePanelSlice.actions;

export default sidePanelSlice.reducer;

export {
  setIsSidePanelActive,
  setQuestionFromCategory,
  setIsSidePanelActiveMobileView,
  setSidePanelActiveTab,
  setSidePanelState,
  initialState,
};
