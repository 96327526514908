import { setIsSidePanelActive } from "../../data/api/SidePanel/sidePanelSlice";
import { useAppDispatch } from "../../redux/hooks";
import { ChatBubbles } from "../../svg/chatBubbles";
import { ChatBubblesMobile } from "../../svg/chatBubblesMobile";
import "./index.css";

function WelcomePage() {
  const dispatch = useAppDispatch();

  const showSidePanel = () => {
    dispatch(setIsSidePanelActive(true));
  };

  return (
    <>
      <section className="welcometext">
        <div>
          <div>
            Welcome to <span className="bold-search">ICE Search</span>,
          </div>
          <p>below are some examples of information you may request</p>
        </div>
        <div className="image-bubbles" data-testid="image-bubbles">
          <ChatBubbles />
        </div>
        <div className="image-bubbles-mobile">
          <ChatBubblesMobile />
        </div>
      </section>
      <section className="viewmore">
        <p>
          <a
            onClick={showSidePanel}
            className="link-underline-light cursor-pointer"
            data-testid="view-more"
          >
            View More Examples of Prompts
          </a>
        </p>
      </section>
    </>
  );
}

export default WelcomePage;
