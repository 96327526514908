import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchGetApi,
  RawRequestError,
  fetchDownloadApi,
  fetchPutApi,
  fetchUploadApi,
} from "../../../api/fetch";
import { CATEGORIES_URL, INTERVIEW } from "../../../constants";
import { serializeError } from "serialize-error";

export type InterviewCategories = {
  name: string;
  value: string;
};

export type InterviewHistoryResult = {
  query_id: string;
  roles: string;
  categories: string;
  level: string;
  date_time: string;
  updated_date_time: string;
  session_id: string;
};

export type InterviewHistoryResponse = InterviewHistoryResult[];

type InterviewChatResponse = {
  session_id: string;
  query_id: string;
  roles: string;
  level: string;
  categories: string;
  date_time: string;
  updated_date_time: string;
};

type InitialState = {
  isLoading: boolean;
  isLoadingCategories: boolean;
  isLoadingListing: boolean;
  isLoadingeditHRQuestions: boolean;
  interviewCategories: string[];
  showGaps: boolean;
  showShareValue: boolean;
  showSaveValue: boolean;
  interviewListing: [
    {
      id: string;
      name: string;
      inputs: { title: string; level: string };
      last_modified_date: string;
    }
  ];
  questions: string;
  gaps: string;
  queryId: string;
  title: string;
  role: string;
  level: string;
  description: string;
  file: string;
  session_id: string;
  interview_id: string;
  historyResults: InterviewHistoryResponse;
  isLoadingHistory: boolean;
  favoritesResults: InterviewHistoryResponse;
  interviewResults: InterviewChatResponse[];
  isLoadingFavorites: boolean;
  isFavorite: boolean;
  rating: number;
  interviewResultError: RawRequestError | null;
  updateFavoriteError: RawRequestError | null;
  updateRatingError: RawRequestError | null;
  getCategoriesError: RawRequestError | null;
  getListingError: RawRequestError | null;
  showResponse: boolean;
  interviewLevels: string[];
  categories: { [key: string]: string };
};

const initialState: InitialState = {
  isLoading: false,
  isLoadingCategories: false,
  isLoadingListing: false,
  isLoadingeditHRQuestions: false,
  interviewCategories: [],
  showGaps: false,
  showShareValue: false,
  showSaveValue: false,
  interviewListing: [
    {
      id: "",
      name: "",
      inputs: {
        title: "",
        level: "",
      },
      last_modified_date: "",
    },
  ],
  questions: "",
  gaps: "",
  queryId: "",
  role: "",
  title: "",
  level: "",
  description: "",
  file: "",
  session_id: "",
  interview_id: "",
  historyResults: [],
  isLoadingHistory: false,
  favoritesResults: [],
  isFavorite: false,
  interviewResults: [],
  isLoadingFavorites: false,
  rating: 0,
  interviewResultError: null,
  updateFavoriteError: null,
  updateRatingError: null,
  getCategoriesError: null,
  getListingError: null,
  showResponse: false,
  interviewLevels: [],
  categories: {},
};

export type InterviewPostRequest = {
  session_id?: string;
  title: string;
  job_description: string;
  level: string;
  categories: string;
  //numberOfOutput: string;
  additional_instructions?: string;
  file_data?: File;
  share: string;
  likes: string;
  gaps: string;
  interview_id?: string;
};

type FavoriteParams = {
  favorite: boolean;
  queryId: string;
};
type InterviewUpdateRatingParams = {
  queryId: string;
  rating: number;
};

type saveTeamParams = {
  session_id: string;
  interview_id: string;
  saved_status: boolean;
};

type shareTeamParams = {
  session_id: string;
  interview_id: string;
  shared_status: boolean;
};

type updateQuestionsParams = {
  session_id: string;
  interview_id: string;
  questions: string;
};

const fetchInterviewResults = createAsyncThunk(
  "interview/fetchInterviewResults",
  async (params: InterviewPostRequest, thunkAPI) => {
    const {
      title,
      job_description,
      share,
      likes,
      level,
      categories,
      additional_instructions,
      file_data,
      gaps,
    } = params;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("share", share);
    formData.append("likes", likes);
    formData.append("gaps", gaps);
    formData.append("job_description", job_description);
    formData.append("level", level);
    formData.append("categories", categories);
    //formData.append("numberOfOutput", numberOfOutput);
    formData.append("additional_instructions", additional_instructions || "");
    formData.append("file_data", file_data || "");

    return fetchUploadApi(formData, INTERVIEW)
      .then((res: any) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const fetchCategories = createAsyncThunk(
  "interview/fetchCategories",
  async (_: void, thunkAPI) =>
    fetchGetApi(CATEGORIES_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);

const getDownloadInterview = createAsyncThunk(
  "interview/getDownloadInterview",
  async (
    params: { interview_id: string; fileType: string; fileName: string },
    thunkAPI
  ) => {
    console.log("get download interview");
    const endpoint = `interview/queries/${params.interview_id}/download/${params.fileType}`;

    return fetchDownloadApi(endpoint)
      .then(async (res) => {
        if (params.fileType === "pdf") {
          return res.blob();
        }
        const text = await res.text();
        return new Blob([text], { type: "text/plain" });
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = params.fileName;
        a.style.display = "none";
        document.body.appendChild(a);

        a.click();
        console.log(a, "atag");
        return { data: params.fileName };
      })
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getInterviewHistory = createAsyncThunk(
  "interview/getInterviewHistory",
  async (_: void, thunkAPI) => {
    console.log("get interview history");
    const endpoint = `interview/history`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const updateInterviewFavorite = createAsyncThunk(
  "interview/updateInterviewFavorite",
  async (params: FavoriteParams, thunkAPI) => {
    const { favorite, queryId } = params;

    const reqData = {
      favorite,
      queryId,
    };

    console.log(reqData, "reqData");

    const FAVORITE = `interview/queries/${queryId}/favorite?favorite=${favorite}`;
    return fetchPutApi(reqData, FAVORITE)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const saveTeam = createAsyncThunk(
  "interview/saveTeam",
  async (params: saveTeamParams, thunkAPI) => {
    const { session_id, interview_id, saved_status } = params;
    const reqData = {
      session_id,
      interview_id,
      saved_status,
    };

    console.log(reqData, "reqData");

    const SAVETEAM = `interview/update`;
    return fetchPutApi(reqData, SAVETEAM)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const shareTeam = createAsyncThunk(
  "interview/shareTeam",
  async (params: shareTeamParams, thunkAPI) => {
    const { session_id, interview_id, shared_status } = params;

    const reqData = {
      session_id,
      interview_id,
      shared_status,
    };

    console.log(reqData, "reqData");

    const SHARETEAM = `interview/update`;
    return fetchPutApi(reqData, SHARETEAM)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const updateHRQuestions = createAsyncThunk(
  "interview/updateHRQuestions",
  async (params: updateQuestionsParams, thunkAPI) => {
    const { session_id, interview_id, questions } = params;

    const reqData = {
      session_id,
      interview_id,
      questions,
    };

    console.log(reqData, "reqData");

    const UPDATEQUESTIONS = `interview/update`;
    return fetchPutApi(reqData, UPDATEQUESTIONS)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const interviewLike = createAsyncThunk(
  "interview/like",
  async (params: { interview_id: string; like: boolean }, thunkAPI) => {
    const { interview_id, like } = params;
    // const reqData = {
    //   interview_id,
    //   like
    // };

    // console.log(reqData, "reqData");

    const endpoint = `interview/like/${interview_id}/${like}`;
    return fetchPutApi({}, endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getInterviewFavorites = createAsyncThunk(
  "interview/getInterviewFavorites",
  async (_: void, thunkAPI) => {
    const endpoint = `interview/favorites`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const updateInterviewRating = createAsyncThunk(
  "interview/updateInterviewRating",
  async (params: InterviewUpdateRatingParams, thunkAPI) => {
    const { rating, queryId } = params;

    const previousRating = (thunkAPI.getState() as any).interviewTabDetails
      .rating;
    thunkAPI.dispatch(updateRating(rating)); // optimistic update
    const ratingEndpoint = `interview/queries/${queryId}/rating?rating=${rating}`;
    return fetchPutApi({}, ratingEndpoint)
      .then((res) => res)
      .catch((e: Error) => {
        thunkAPI.dispatch(updateRating(previousRating)); // rollback
        return thunkAPI.rejectWithValue(serializeError(e));
      });
  }
);

const getInterviewQuery = createAsyncThunk(
  "interview/getInterviewQuery",
  async (params: { query_id: string }, thunkAPI) => {
    const endpoint = `getInterviewQuery/${params.query_id}`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const fetchInterviewListing = createAsyncThunk(
  "interview/fetchInterviewListing",
  async (params: { status: string }, thunkAPI) => {
    const endpoint = `interview/listing/${params.status}`;
    return fetchGetApi(endpoint)
      .then((res) => {
        console.log(res, "res");
        return res;
      })
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const viewInterviewDetails = createAsyncThunk(
  "interview/viewInterviewDetails",
  async (params: { query_id: string }, thunkAPI) => {
    const endpoint = `getInterviewQuery/${params.query_id}`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    setInterview: (state, action) => {
      state.interviewResults = [...state.interviewResults, action.payload];
    },
    setGetInterview: (state, action) => ({
      ...state,
      getDownloadInterview: action.payload,
    }),
    setFavorite: (state, action) => {
      state.isFavorite = action.payload;
    },
    updateFavorite: (state, action) => {
      console.log("update favorite " + action.payload);
      return {
        ...state,
        isFavorite: action.payload,
      };
    },
    updateRating: (state, action) => {
      console.log("update rating to " + action.payload);
      return {
        ...state,
        rating: action.payload,
      };
    },
    setDefaultValues: (state) => ({
      ...state,
      title: "",
      level: "",
      categories: {},
      description: "",
      questions: "",
      showResponse: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => ({
      ...state,
      isLoadingCategories: true,
    }));
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      const { data } = action.payload as any;
      let categoryResults = state.interviewCategories;
      if (categoryResults.length === 0) {
        return {
          ...state,
          interviewCategories: data.categories,
          interviewLevels: data.levels,
          isLoadingCategories: false,
          getCategoriesError: null,
        };
      } else
        return {
          ...state,
          isLoadingCategories: false,
          getCategoriesError: null,
        } as any;
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      return {
        ...state,
        isLoadingCategories: false,
        getCategoriesError: action.payload as RawRequestError,
      };
    });
    builder.addCase(fetchInterviewResults.pending, (state) => ({
      ...state,
      isLoading: true,
      interviewResultError: null,
    }));
    builder.addCase(fetchInterviewResults.fulfilled, (state, action) => {
      const { data } = action.payload;
      let historyResults = state.historyResults;
      // const existsInHistory = historyResults.find(
      //   (history) => history.session_id === data.session_id
      // );
      // if (!existsInHistory) {
      //   const history = {
      //     query_id: data.query_id,
      //     roles: data.roles,
      //     categories: data.categories,
      //     level: data.level,
      //     date_time: data.updated_date_time,
      //     updated_date_time: data.updated_date_time,
      //     session_id: data.session_id,
      //   };
      //   historyResults = [...state.historyResults, history];
      // }

      const filteredInterviewResults = state.interviewResults.filter(
        (_, index) => index !== state.interviewResults.length - 1
      );

      return {
        ...state,
        isLoading: false,
        questions: data.questions,
        gaps: data.gaps,
        showGaps: data.gaps ? true : false,
        queryId: data.query_id,
        level: data.level,
        title: data.title,
        categories: data.categories,
        description: data.job_description,
        session_id: data.session_id,
        interview_id: data.id,
        isFavorite: false,
        rating: 0,
        error: null,
        historyResults,
        showResponse: true,
        interviewResults: [...filteredInterviewResults, data],
        updateFavoriteError: null,
        updateRatingError: null,
        interviewResultError: null,
      };
    });
    builder.addCase(fetchInterviewResults.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      showResponse: false,
      interviewResultError: action.payload as RawRequestError,
    }));
    builder.addCase(getDownloadInterview.pending, (state) => ({
      ...state,
    }));
    builder.addCase(getDownloadInterview.fulfilled, (state, action) => {
      const file = action.payload.data;
      return {
        ...state,
        file,
        error: null,
      };
    });

    builder.addCase(getDownloadInterview.rejected, (state, action) => ({
      ...state,
      error: action.payload as RawRequestError,
    }));

    builder.addCase(getInterviewHistory.fulfilled, (state, action) => {
      const { data } = action.payload as any;
      return {
        ...state,
        historyResults: data,
        isLoadingHistory: false,
      } as any;
    });

    builder.addCase(getInterviewHistory.rejected, (state) => {
      return {
        ...state,
        isLoadingHistory: false,
      };
    });

    builder.addCase(getInterviewHistory.pending, (state) => ({
      ...state,
      isLoadingHistory: true,
    }));

    builder.addCase(getInterviewFavorites.fulfilled, (state, action) => {
      const { data } = action.payload as any;
      return {
        ...state,
        favoritesResults: data,
        isLoadingFavorites: false,
      } as any;
    });

    builder.addCase(getInterviewFavorites.rejected, (state) => {
      return {
        ...state,
        isLoadingFavorites: false,
      };
    });

    builder.addCase(getInterviewFavorites.pending, (state) => ({
      ...state,
      isLoadingFavorites: true,
    }));

    builder.addCase(updateInterviewFavorite.pending, (state) => ({
      ...state,
    }));

    builder.addCase(updateInterviewFavorite.fulfilled, (state) => {
      let favoritesResults = state.favoritesResults;
      if (state.interviewResults && state.interviewResults.length > 0) {
        const firstChat = state.interviewResults[0];
        const existsInFavorites = state.favoritesResults.find(
          (favorite) => favorite.session_id === firstChat.session_id
        );
        if (!existsInFavorites && state.isFavorite) {
          const favorite = {
            query_id: firstChat.query_id!,
            roles: firstChat.roles,
            categories: firstChat.categories,
            level: firstChat.level,
            date_time: firstChat.date_time!,
            updated_date_time: firstChat.updated_date_time!,
            session_id: firstChat.session_id!,
          };
          favoritesResults = [...state.favoritesResults, favorite];
        } else if (existsInFavorites && !state.isFavorite) {
          const filteredFavorites = state.favoritesResults.filter(
            (favorite) => favorite.session_id !== firstChat.session_id
          );
          favoritesResults = [...filteredFavorites];
        }
      }
      return {
        ...state,
        favoritesResults,
      };
    });

    builder.addCase(updateInterviewFavorite.rejected, (state, action) => {
      return {
        ...state,
        isFavorite: !state.isFavorite,
        isLoadingFavorite: false,
        favoriteError: action.payload as RawRequestError,
      };
    });
    builder.addCase(updateHRQuestions.pending, (state) => ({
      ...state,
      isLoadingeditHRQuestions: true,
      // isLoadingFavorite: true,
    }));
    builder.addCase(updateHRQuestions.fulfilled, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        isLoadingeditHRQuestions: false,
        questions: data.results.questions,
        error: null,
      };
    });
    builder.addCase(updateHRQuestions.rejected, (state, action) => {
      return {
        ...state,
        isLoadingeditHRQuestions: false,
        error: action.payload as RawRequestError,
      };
    });

    builder.addCase(updateInterviewRating.rejected, (state, action) => {
      state.updateRatingError = action.payload as RawRequestError;
    });

    builder.addCase(updateInterviewRating.pending, (state) => {
      state.updateRatingError = null;
    });

    builder.addCase(getInterviewQuery.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(getInterviewQuery.fulfilled, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        questions: data.questions,
        gaps: data.gaps,
        queryId: data.query_id,
        tile: data.roles,
        level: data.level,
        isFavorite: data.favorite,
        showResponse: true,
        rating: 0,
        error: null,
        interviewResults: [data],
      };
    });
    builder.addCase(getInterviewQuery.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload as RawRequestError,
    }));

    builder.addCase(fetchInterviewListing.pending, (state) => ({
      ...state,
      isLoadingListing: true,
    }));
    builder.addCase(fetchInterviewListing.fulfilled, (state, action) => {
      console.log(action.payload, "fetchInterviewListing.action.payload");
      const { data } = action.payload;

      return {
        ...state,
        interviewListing: data,
        isLoadingListing: false,
        getListingError: null,
      };
    });
    builder.addCase(fetchInterviewListing.rejected, (state, action) => {
      return {
        ...state,
        isLoadingListing: false,
        getListingError: action.payload as RawRequestError,
      };
    });

    builder.addCase(viewInterviewDetails.pending, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }));
    builder.addCase(viewInterviewDetails.fulfilled, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        questions: data.results.questions,
        gaps: data.results.gaps,
        showGaps: data.results.gaps ? true : false,
        showShareValue: data.shared_status,
        showSaveValue: data.saved_status,
        queryId: data.query_id,
        interview_id: data.id,
        session_id: data.session_id,
        title: data.inputs.title,
        level: data.inputs.level,
        description: data.inputs.job_description,
        isFavorite: data.favorite,
        categories: data.inputs.categories,
        showResponse: true,
        rating: 0,
        error: null,
        interviewResults: [data],
      };
    });
    builder.addCase(viewInterviewDetails.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload as RawRequestError,
    }));
  },
});

const {
  setInterview,
  setGetInterview,
  setFavorite,
  updateRating,
  setDefaultValues,
} = interviewSlice.actions;
export default interviewSlice.reducer;
export {
  fetchCategories,
  fetchInterviewResults,
  getDownloadInterview,
  getInterviewHistory,
  updateInterviewFavorite,
  setInterview,
  setGetInterview,
  setFavorite,
  getInterviewFavorites,
  updateInterviewRating,
  getInterviewQuery,
  fetchInterviewListing,
  saveTeam,
  viewInterviewDetails,
  setDefaultValues,
  shareTeam,
  updateHRQuestions,
  interviewLike,
};