import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { IoIosArrowDown } from "react-icons/io";
import { Spinner } from "react-bootstrap";

interface DropdownProps {
  value: { name: string; value: string };
  setValue: React.Dispatch<
    React.SetStateAction<{ name: string; value: string }>
  >;
  data: { name: string; _id?: string; value: string }[];
  placeHolder?: string;
  width?: string;
  showLoading?: boolean;
  showError?: boolean;
  height?: string;
}

const SelectDropDown: React.FC<DropdownProps> = ({
  value,
  setValue,
  data = [],
  placeHolder = "Select",
  width,
  showLoading,
  showError,
  height,
}) => {
  const [showOption, setShowOption] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (item: {
    name: string;
    _id?: string;
    value: string;
  }) => {
    setValue({
      name: item?.name === "" ? placeHolder : item?.name,
      value: item?._id || item?.value,
    });
    setShowOption((prev) => !prev);
  };

  return (
    <div
      className="dropdown-container"
      style={{ width: width, height: height }}
      ref={dropdownRef}
    >
      <div
        onClick={() => setShowOption((prev) => !prev)}
        className={`dropdown-header-style ${
          showOption && "active-dropdown-header-style"
        }`}
        style={{ height: height }}
      >
        <div className="dropdown-header-content">
          <div
            className="dropdown-text"
            style={{
              maxWidth: `calc(${width} - 30px)`,
              fontStyle:
                placeHolder === "Select" && !value.name ? "italic" : "normal",
              fontWeight: value?.name ? "600" : "normal",
            }}
          >
            {value?.name ? value?.name : placeHolder}
          </div>
        </div>
        <IoIosArrowDown
          className={`dropdown-icon ${showOption && "rotate-180"}`}
        />
      </div>
      {showOption && (
        <ul className="dropdown-options" data-testid="dropdown-options">
          {!showLoading &&
            !showError &&
            data.map((item, index) => (
              <li
                className={`dropdown-option ${
                  value.name === item?.name ? "selected" : ""
                }`}
                key={index}
                onClick={() => handleChange(item)}
              >
                {item?.name}
              </li>
            ))}
          {showLoading && (
            <div className="w-100 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          {showError && (
            <div className="w-100 error-txt d-flex justify-content-center">
              Error fetching data
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectDropDown;
