import { useState, useEffect } from "react";
import "./index.css";
import { ListGroup, Spinner } from "react-bootstrap";
import {
  getBucketHistory,
  contractHistoryResponseType,
  setBucketNameStatusAndId,
} from "../../../data/api/Contract/contractSlice";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";

interface ContractHistoryMap {
  [key: string]: ContractHistory[];
}

interface ContractHistory {
  bucket_id: string;
  bucket_name: string;
  session_id: string;
  updated_datetime: string;
  created_datetime: string;
  status: string;
}

function ContractHistory() {
  const dispatch = useAppDispatch();
  const [history, setHistory] = useState<ContractHistoryMap>({});
  const { historyResults, isLoadingHistory } = useAppSelector(
    (state) => state.contractDetails
  );

  useEffect(() => {
    setHistoryMap(historyResults);
  }, [historyResults]);

  useEffect(() => {
    console.log("dispatch get interview history");
    if (historyResults.length === 0) {
      dispatch(getBucketHistory());
    }
  }, []);

  const setHistoryMap = (
    response: contractHistoryResponseType,
    isFavorites = false
  ) => {
    if (response) {
      const historyMap: ContractHistoryMap = {};
      response.forEach((result) => {
        const date = new Date(result.updated_datetime);
        const dateString = `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
        if (historyMap[dateString]) {
          const historyArray = historyMap[dateString];
          historyArray.push(result);
          historyMap[dateString] = historyArray.sort((a, b) => {
            return (
              new Date(a.updated_datetime).getTime() -
              new Date(b.updated_datetime).getTime()
            );
          });
        } else {
          historyMap[dateString] = [result];
        }
      });
      if (!isFavorites) {
        setHistory(historyMap);
      }
    }
  };

  const getFormattedDate = (dateString: string) => {
    const date = new Date();
    const dateTokens = dateString.split("-");
    date.setMonth(parseInt(dateTokens[0]));
    date.setDate(parseInt(dateTokens[1]));
    date.setFullYear(parseInt(dateTokens[2]));
    return `${date.toLocaleString("en", {
      month: "short",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const getHistoryItemsJSX = (map: ContractHistoryMap, key: string) => {
    if (isLoadingHistory && key === "history") {
      return (
        <div className="w-100 loading-container d-flex flex-column align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
          <div className="loading-txt">Loading {key}</div>
        </div>
      );
    }
    return Object.keys(map)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .map((date) => {
        const historyArray = map[date];
        const header = (
          <div
            data-testid="contract-history-header"
            className="date-header"
            key={date}
          >
            {getFormattedDate(date)}
          </div>
        );
        const items = historyArray.map((history, index) => {
          return (
            <ListGroup.Item
              action
              data-testid="contract-history-item"
              className="contract-history-list-item"
              key={index}
              onClick={() =>
                dispatch(
                  setBucketNameStatusAndId({
                    bucket_id: history.bucket_id,
                    status: history.status,
                    bucket_name: history.bucket_name,
                  })
                )
              }
            >
              <div className="contract-role-txt">{history.bucket_name}</div>
              <div className="contract-history-list-txt">
                Status: {history.status}
              </div>
            </ListGroup.Item>
          );
        });
        return (
          <div key={`${key}-${date}`} className="contract-history-list-group">
            {header}
            <ListGroup>{items}</ListGroup>
          </div>
        );
      });
  };

  return (
    <div className="contract-history-tab" data-testid="contract-history-tab">
      <div className="contract-history-tab-header d-flex flex-column">
        <div className="header-panel">
          <div className="contract-history-tab-heading">
            Contract Extraction History
          </div>
        </div>
        <div className="contract-history-tab-subheading"></div>
      </div>
      <div className="contract-history-tab-content">
        <div className="contract-overflow-container">
          {getHistoryItemsJSX(history, "history")}
        </div>
      </div>
    </div>
  );
}

export default ContractHistory;
