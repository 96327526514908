function UpVoteFilledIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5181 11.5725L18.2889 11.3616C18.2288 11.7344 18.3307 12.1155 18.5675 12.4038C18.8043 12.692 19.1519 12.8582 19.5181 12.8581L19.5181 11.5725ZM1.2458 11.5725L1.2458 10.2868C0.557802 10.2868 1.67306e-06 10.8624 1.61098e-06 11.5725L1.2458 11.5725ZM4.5681 30L23.4383 30L23.4383 27.4287L4.5681 27.4287L4.5681 30ZM25.4317 10.2868L19.5181 10.2868L19.5181 12.8581L25.4317 12.8581L25.4317 10.2868ZM20.7473 11.7833L22.0862 3.4952L19.6277 3.0718L18.2889 11.3616L20.7473 11.7833ZM19.2191 0.00169851L18.8636 0.00169847L18.8636 2.573L19.2174 2.573L19.2191 0.00169851ZM13.6793 2.8644L9.5016 9.332L11.5746 10.7582L15.7523 4.2906L13.6793 2.8644ZM7.774 10.2868L1.2458 10.2868L1.2458 12.8581L7.774 12.8581L7.774 10.2868ZM1.61098e-06 11.5725L4.12113e-07 25.286L2.4917 25.286L2.4917 11.5725L1.61098e-06 11.5725ZM27.9184 26.2116L29.9117 15.9265L27.4699 15.4208L25.4765 25.706L27.9184 26.2116ZM9.5016 9.3303C9.1166 9.9266 8.4683 10.2849 7.774 10.2851L7.774 12.8564C9.3022 12.8564 10.7275 12.0679 11.5746 10.7565L9.5016 9.3303ZM22.0862 3.4935C22.2263 2.6239 21.9887 1.7348 21.4364 1.0623C20.8842 0.389699 20.0733 0.00189858 19.2191 0.00169851L19.2191 2.573C19.341 2.5733 19.4565 2.6288 19.5352 2.7249C19.6139 2.8209 19.6477 2.9478 19.6277 3.0718L22.0862 3.4935ZM25.4317 12.8564C26.7439 12.8564 27.724 14.0941 27.4682 15.4191L29.9117 15.9231C30.1794 14.5384 29.8315 13.1029 28.9638 12.0112C28.0961 10.9196 26.7999 10.2868 25.4317 10.2868L25.4317 12.8564ZM23.4383 29.9983C25.6157 29.9987 27.4908 28.4132 27.9184 26.2099L25.4765 25.7042C25.2824 26.7067 24.429 27.428 23.4383 27.427L23.4383 29.9983ZM18.8636 -9.73575e-07C16.7809 0.000198844 14.8361 1.0744 13.6809 2.8627L15.7523 4.2889C16.4458 3.2154 17.6134 2.5708 18.8636 2.5713L18.8636 -9.73575e-07ZM4.5681 27.4287C3.4219 27.4287 2.4917 26.4688 2.4917 25.286L4.12113e-07 25.286C1.84509e-07 27.8895 2.0452 30 4.5681 30L4.5681 27.4287Z" fill="#367ED4" />
            <path d="M7.89063 11.5725L7.89062 28.7144" stroke="#367ED4" stroke-width="1.5" />
        </svg>

    );
}

export { UpVoteFilledIcon }