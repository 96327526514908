import { SUPPORTED_IMAGE_TYPES } from "../../constants";
import { VisualQAFileInput } from "../../data/api/VisualQA/visualQASlice";
import { getFileExtension } from "../../utils/file";
import FileIcon from "../FileIcons";
import "./index.css";

type VisualQuestionBubbleFileInputProps = {
  file?: VisualQAFileInput;
};

function VisualQuestionBubbleFileInput({
  file,
}: VisualQuestionBubbleFileInputProps) {
  if (!file) {
    return;
  }
  if (file) {
    const fileExtension = getFileExtension(file.name) ?? "";
    const isImage = SUPPORTED_IMAGE_TYPES.includes(fileExtension);
    if (isImage) {
      return <img src={file.url} className="question-file-img" />;
    } else {
      return (
        <div className="d-flex align-items-center bubble-file-icon-name-container">
          <div className="bubble-file-icon-container">
            <FileIcon url={file.name} />
          </div>
          <div className="bubble-file-name-txt">{file.name}</div>
        </div>
      );
    }
  }
}

export default VisualQuestionBubbleFileInput;
