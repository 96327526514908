import {
  Form,
  InputGroup,
  Container,
  Button,
  Stack,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./index.css";
import { NewChatButton } from "../../svg/newChatButton";

import { useEffect, useRef, useState } from "react";
import { PromptSearch } from "../../svg/promptSearch";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import AnswerBubble from "../../components/AnswerBubble";
import VisualQuestionBubble from "../../components/VisualQuestionBubble";
import {
  clearChat,
  setChat,
  setVisualQAFavorite,
  updateVisualQAFavorite,
  selectChatSessionId,
  VisualQAChatFileParams,
  postChatDocument,
  VisualQAChatResponse,
  postChatImage,
} from "../../data/api/VisualQA/visualQASlice";
import {
  MAX_CHARACTERS,
  SUPPORTED_DOCUMENT_TYPES,
  SUPPORTED_IMAGE_TYPES,
} from "../../constants";
import { AddFileSelected } from "../../svg/addFileSelected";
import { AddFile } from "../../svg/addFile";
import { DefaultFavourite } from "../../svg/defaultFavorite";
import { VisualQASplash } from "../../svg/visualQASplash";
import { fetchTokenDetails } from "../../data/api/UserDetails/userDetailsSlice";
import FileDropContainer from "./fileDropContainer";
import { getFileExtension } from "../../utils/file";

function AskAnyQuestions() {
  const dispatch = useAppDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [question, setQuestion] = useState<string>("");
  const [showAddFile, setShowAddFile] = useState(false);
  const [isErrorInInput, setIsErrorinInput] = useState(false);
  const [fileInput, setFileInput] = useState<File | undefined>(undefined);
  const bubblesRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const characterCountDisplay = `${question.length}/${MAX_CHARACTERS}`;
  const {
    chatResults,
    isFavorite,
    isLoading,
    isLoadingFavorite,
    favoriteError,
    answerError,
    getChatBySessionIdError,
  } = useAppSelector((state: RootState) => state.visualQADetails);

  const sessionId = useAppSelector(selectChatSessionId);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitQuestion();
    }
  };

  const scrollToBottom = () => {
    if (bubblesRef.current) {
      const scrollHeight = bubblesRef.current.scrollHeight;
      bubblesRef.current.scrollTop = scrollHeight;
    }
  };

  useEffect(() => {
    if (favoriteError) {
      setShowAlert(true);
      setErrorMessage("Unable to set favorite");
    }
  }, [favoriteError]);

  useEffect(() => {
    if (answerError) {
      setShowAlert(true);
      setErrorMessage("Unable to fetch answer");
    }
  }, [answerError]);

  useEffect(() => {
    if (getChatBySessionIdError) {
      setShowAlert(true);
      setErrorMessage("Unable to load messages");
    }
  }, [getChatBySessionIdError]);

  useEffect(() => {
    if (chatResults) {
      console.log("answer results " + JSON.stringify(chatResults));
      scrollToBottom();
    }
  }, [chatResults]);

  useEffect(() => {
    if (textareaRef.current && question !== "") {
      adjustTextareaHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const resetTextareaSize = () => {
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.resize = "both";
        textareaRef.current.style.height = "auto";
      }
    }, 10);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentCharacterCount = e.target.value.length;
    if (currentCharacterCount <= MAX_CHARACTERS) {
      setQuestion(e.target.value);
      setIsErrorinInput(false);
    } else {
      setIsErrorinInput(true);
    }
  };

  const submitQuestion = () => {
    if (question) {
      const chatResponse: Partial<VisualQAChatResponse> = {
        query: question,
      };
      if (fileInput) {
        chatResponse.file = {
          name: fileInput.name,
          url: URL.createObjectURL(fileInput),
        };
      }
      dispatch(setChat(chatResponse));
      if (!fileInput) {
        const params: VisualQAChatFileParams = {
          query: encodeURIComponent(question),
          file: undefined,
        };
        if (sessionId) {
          params.session_id = sessionId;
        }
        dispatch(postChatDocument(params));
      } else {
        const params: VisualQAChatFileParams = {
          query: encodeURIComponent(question),
          file: fileInput,
        };
        if (sessionId) {
          params.session_id = sessionId;
        }
        const fileExtension = getFileExtension(fileInput.name);
        if (!fileExtension) {
          return;
        }
        if (SUPPORTED_IMAGE_TYPES.includes(fileExtension)) {
          dispatch(postChatImage(params));
        } else if (SUPPORTED_DOCUMENT_TYPES.includes(fileExtension)) {
          dispatch(postChatDocument(params));
        } else {
          console.error("Unsupported document type " + fileInput.name);
        }
      }

      dispatch(fetchTokenDetails());
      setQuestion("");
      setShowAddFile(false);
      setIsErrorinInput(false);
      resetTextareaSize();
    }
  };

  const onAddFileClick = () => {
    setShowAddFile(!showAddFile);
  };

  const onNewChatClick = () => {
    setFileInput(undefined);
    dispatch(clearChat());
  };

  const onFavoriteClick = () => {
    if (sessionId) {
      dispatch(setVisualQAFavorite(!isFavorite));
      dispatch(updateVisualQAFavorite({ sessionId, favorite: !isFavorite }));
    }
  };

  const handleRemoveFile = () => {
    dispatch(clearChat());
    setFileInput(undefined);
    //setFileInputs( (fileInputs) => fileInputs.filter((f) => f !== file))
  };

  const handleAddFile = (file: File) => {
    dispatch(clearChat());
    setFileInput(file);
    //setFileInputs( (fileInputs) => [...fileInputs, file]);
  };

  // const [chatData] = useState(false);
  return (
    <Container>
      <div className="visual-container d-flex justify-content-center">
        <div className="visual-subcontainer">
          {chatResults && chatResults.length > 0 && (
            <div className="bubbles-container flex-grow-1 d-flex flex-column justify-content-end">
              <div className="bubble-scroll-container" ref={bubblesRef}>
                {chatResults.map((result) => {
                  if (result.answer) {
                    return (
                      <div key={result.question_id}>
                        <VisualQuestionBubble
                          question={result.query!}
                          file={result.file}
                        />
                        <div className="visual-spacer"></div>
                        <AnswerBubble
                          answer={result.answer}
                          questionId={result.question_id}
                          rating={result.rating}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <VisualQuestionBubble
                        key={result.question_id}
                        question={result.query!}
                        file={result.file}
                      />
                    );
                  }
                })}
                {isLoading && (
                  <div className="w-100 d-flex justify-content-center spinner-container">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
              </div>
            </div>
          )}

          {(!chatResults || chatResults.length === 0) && (
            <div
              data-testid="qa-welcome"
              className="w-100 flex-grow-1 d-flex align-items-center justify-content-center welcome-container"
            >
              <div>
                <div className="welcome-txt">
                  Welcome to <span>ICE Ask Anything</span>,<br />
                  below are some examples of information you may request
                </div>
                <VisualQASplash />
              </div>
            </div>
          )}

          {showAlert && (
            <div data-testid="qa-alert">
              <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                <Alert.Heading>Error</Alert.Heading>
                <p>{errorMessage}</p>
              </Alert>
            </div>
          )}

          <section id="inputgroup" className="textarea-container">
            <InputGroup className="form d-flex align-items-start textarea-input-group">
              {showAddFile && (
                <FileDropContainer
                  fileInput={fileInput}
                  handleAddFile={handleAddFile}
                  handleRemoveFile={handleRemoveFile}
                />
              )}
              <Button
                className="transparent-button new-chat-button"
                variant="primary"
                id="new-chat"
                data-testid="new-chat"
                onClick={onNewChatClick}
              >
                <NewChatButton />
              </Button>
              &nbsp; &nbsp;
              <Stack>
                <div className="d-flex textarea-buttons-container">
                  {chatResults.length > 0 ? (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip {...props} id="tooltipid">
                          <div>
                            To upload new document/image, please start a New
                            session
                          </div>
                        </Tooltip>
                      )}
                    >
                      <button
                        className="add-image-button"
                        id="add-image"
                        data-testid="add-image"
                        onClick={onAddFileClick}
                        disabled={chatResults.length > 0}
                      >
                        {showAddFile ? (
                          <AddFileSelected />
                        ) : (
                          <AddFile
                            fillColor={
                              chatResults.length > 0 ? "#9e9e9e" : "#000000"
                            }
                          />
                        )}
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <button
                      className="add-image-button"
                      id="add-image"
                      data-testid="add-image"
                      onClick={onAddFileClick}
                    >
                      {showAddFile ? <AddFileSelected /> : <AddFile />}
                    </button>
                  )}
                  <Form.Control
                    as="textarea"
                    ref={textareaRef}
                    rows={1}
                    className={
                      isErrorInInput
                        ? "is-invalid visual-textarea"
                        : "visual-textarea"
                    }
                    placeholder="Ask me anything ..."
                    data-testid="qa-input"
                    onKeyDown={handleKeyPress}
                    onChange={handleChange}
                    disabled={isLoading}
                    value={question}
                  />
                  <Button
                    className="submit"
                    variant="primary"
                    id="submit-search"
                    data-testid="submit-search"
                    disabled={isLoading}
                    onClick={submitQuestion}
                  >
                    <PromptSearch />
                  </Button>
                </div>
                <div className="d-flex justify-content-end text-bottom-container">
                  <div
                    className={`${
                      isErrorInInput ? "question-character-count-error" : ""
                    }`}
                  >
                    {characterCountDisplay}
                  </div>
                </div>
              </Stack>
              <Button
                className="transparent-button fav-button-visual"
                onClick={onFavoriteClick}
                disabled={!sessionId || isLoadingFavorite}
              >
                <DefaultFavourite
                  className={
                    isFavorite ? "checked-favourite" : "unchecked-favourite"
                  }
                />
              </Button>
            </InputGroup>
          </section>
        </div>
      </div>
    </Container>
  );
}

export default AskAnyQuestions;
