function StatusGreen() {
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>AF618440-2300-491C-9902-F87EFF983078</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-173, -69)" fill="#006300">
                    <circle id="green-status" cx="181" cy="77" r="8"></circle>
                </g>
            </g>
        </svg>
    );
}

export { StatusGreen }