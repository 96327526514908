function ChatBubbles() {
  return (
    <svg
      x="347"
      y="328"
      width="746.701625px"
      height="378.297202px"
      viewBox="0 0 746.701625 378.297202"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="welcome-banner"
    >
      <title>9AF43399-A0E0-4954-B9EF-B63F9B37B98F</title>
      <defs>
        <path
          d="M154.263754,378.297202 C161.720989,378.297202 157.66371,356.194061 164.812706,355.092871 C233.313875,344.541348 285.765344,285.337359 285.765344,213.882672 C285.765344,134.970751 221.794593,71 142.882672,71 C63.9707513,71 0,134.970751 0,213.882672 C0,284.642834 51.4367893,343.389289 118.959768,354.771439 C126.738595,356.082693 146.111995,378.297202 154.263754,378.297202 Z"
          id="path-1"
        />
        <text x="50" y="50" fontSize="5rem" textAnchor="middle">
          Responsive Text
        </text>
        <filter
          x="-0.2%"
          y="-0.2%"
          width="100.3%"
          height="100.7%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <path
          d="M615.200035,378.297202 C622.657269,378.297202 618.599991,356.194061 625.748987,355.092871 C694.250156,344.541348 746.701625,285.337359 746.701625,213.882672 C746.701625,134.970751 682.730874,71 603.818953,71 C524.907032,71 460.936281,134.970751 460.936281,213.882672 C460.936281,284.642834 512.37307,343.389289 579.896048,354.771439 C587.674876,356.082693 607.048276,378.297202 615.200035,378.297202 Z"
          id="path-3"
        />
        <filter
          x="-0.2%"
          y="-0.2%"
          width="100.3%"
          height="100.7%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <path
          d="M385.200035,307.297202 C392.657269,307.297202 388.599991,285.194061 395.748987,284.092871 C464.250156,273.541348 516.701625,214.337359 516.701625,142.882672 C516.701625,63.9707513 452.730874,0 373.818953,0 C294.907032,0 230.936281,63.9707513 230.936281,142.882672 C230.936281,213.642834 282.37307,272.389289 349.896048,283.771439 C357.674876,285.082693 377.048276,307.297202 385.200035,307.297202 Z"
          id="path-5"
        />
        <filter
          x="-0.2%"
          y="-0.2%"
          width="100.3%"
          height="100.7%"
          filterUnits="objectBoundingBox"
          id="filter-6"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
      </defs>
      <g
        id="ICE-V.1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ICE-V.1---Style-Sheet"
          transform="translate(-31.000000, -1200.000000)"
        >
          <g id="Chat-Bubbles" transform="translate(31.000000, 1200.000000)">
            <g id="Oval-Copy-2">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-2)"
                xlinkHref="#path-1"
              />
              <path
                stroke="#9E9E9E"
                strokeWidth="2"
                d="M142.882672,72 C182.06249,72 217.533158,87.880759 243.208872,113.556473 C268.884585,139.232186 284.765344,174.702854 284.765344,213.882672 C284.765344,249.103941 271.931618,281.327783 250.685093,306.132749 C229.130021,331.297938 198.916474,348.827931 164.660468,354.104527 C162.865507,354.381012 161.577546,355.671639 160.687979,357.711712 C159.671225,360.043468 159.178097,363.373553 158.703141,366.692265 C158.243642,369.902971 157.806781,373.104486 156.798303,375.233756 C156.475764,375.914755 156.099231,376.478343 155.613708,376.850488 C155.236169,377.139865 154.791019,377.297202 154.263754,377.297202 C150.701181,377.297202 144.973525,372.787601 139.051105,367.873409 L138.35357,367.29365 L138.004351,367.002834 L137.11355,366.259034 L135.727695,365.097841 C129.192592,359.617771 122.846274,354.412468 119.12599,353.785351 C85.3396536,348.090087 55.6096532,330.452067 34.4264324,305.363939 C13.5707066,280.663677 1,248.741171 1,213.882672 C1,174.702854 16.880759,139.232186 42.5564725,113.556473 C68.2321861,87.880759 103.702854,72 142.882672,72 Z"
                strokeLinecap="square"
                fill="#FFFFFF"
                fillRule="evenodd"
              />
              <use xlinkHref="#path-1" />
            </g>
            <g
              id="Oval-Copy-3"
              transform="translate(603.818953, 224.648601) scale(-1, 1) translate(-603.818953, -224.648601) "
            >
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-4)"
                xlinkHref="#path-3"
              />
              <path
                stroke="#9E9E9E"
                strokeWidth="2"
                d="M603.818953,72 C642.998771,72 678.469439,87.880759 704.145152,113.556473 C729.820866,139.232186 745.701625,174.702854 745.701625,213.882672 C745.701625,249.103941 732.867899,281.327783 711.621374,306.132749 C690.066302,331.297938 659.852754,348.827931 625.596748,354.104527 C623.801787,354.381012 622.513826,355.671639 621.62426,357.711712 C620.607505,360.043468 620.114378,363.373553 619.639422,366.692265 C619.179923,369.902971 618.743061,373.104486 617.734584,375.233756 C617.412045,375.914755 617.035512,376.478343 616.549989,376.850488 C616.17245,377.139865 615.727299,377.297202 615.200035,377.297202 C611.665154,377.297202 606.000626,372.879421 600.128619,367.996329 L599.408691,367.396342 C599.288574,367.296046 599.168403,367.195624 599.048195,367.095108 L598.326627,366.491169 C598.086042,366.289663 597.845438,366.088053 597.604945,365.886609 L596.714799,365.14046 L595.82702,364.396211 C595.699777,364.289605 595.572645,364.18318 595.445642,364.076969 L594.685324,363.442526 C588.870359,358.602048 583.406185,354.349024 580.06227,353.785351 C546.275934,348.090087 516.545934,330.452067 495.362713,305.363939 C474.506987,280.663677 461.936281,248.741171 461.936281,213.882672 C461.936281,174.702854 477.81704,139.232186 503.492753,113.556473 C529.168467,87.880759 564.639135,72 603.818953,72 Z"
                strokeLinecap="square"
                fill="#FFFFFF"
                fillRule="evenodd"
              />
              <use xlinkHref="#path-3" />
            </g>
            <g
              id="Oval-Copy"
              transform="translate(373.818953, 153.648601) scale(-1, 1) translate(-373.818953, -153.648601) "
            >
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-6)"
                xlinkHref="#path-5"
              />
              <path
                stroke="#9E9E9E"
                strokeWidth="2"
                d="M373.818953,1 C412.998771,1 448.469439,16.880759 474.145152,42.5564725 C499.820866,68.2321861 515.701625,103.702854 515.701625,142.882672 C515.701625,178.103941 502.867899,210.327783 481.621374,235.132749 C460.066302,260.297938 429.852754,277.827931 395.596748,283.104527 C393.801787,283.381012 392.513826,284.671639 391.62426,286.711712 C390.607505,289.043468 390.114378,292.373553 389.639422,295.692265 C389.179923,298.902971 388.743061,302.104486 387.734584,304.233756 C387.412045,304.914755 387.035512,305.478343 386.549989,305.850488 C386.17245,306.139865 385.727299,306.297202 385.200035,306.297202 C381.665154,306.297202 376.000626,301.879421 370.128619,296.996329 L369.408691,296.396342 C369.288574,296.296046 369.168403,296.195624 369.048195,296.095108 L368.326627,295.491169 C368.086042,295.289663 367.845438,295.088053 367.604945,294.886609 L366.714799,294.14046 L365.82702,293.396211 C365.699777,293.289605 365.572645,293.18318 365.445642,293.076969 L364.685324,292.442526 C358.870359,287.602048 353.406185,283.349024 350.06227,282.785351 C316.275934,277.090087 286.545934,259.452067 265.362713,234.363939 C244.506987,209.663677 231.936281,177.741171 231.936281,142.882672 C231.936281,103.702854 247.81704,68.2321861 273.492753,42.5564725 C299.168467,16.880759 334.639135,1 373.818953,1 Z"
                strokeLinecap="square"
                fill="#FFFFFF"
                fillRule="evenodd"
              />
              <use xlinkHref="#path-5" />
            </g>
          </g>
        </g>
      </g>
      <foreignObject x="5" y="5" width="750" height="550">
        <div className="mx-auto mt-10 welcome-chat-bubbles">
          <span className="left-text">
            Provide a detailed customer story on security
          </span>
          <span className="centered-text">
            Give me a good example of a customer with a healthcare migration
            solution
          </span>
          <span className="right-text">
            What are the key objectives of the Rackspace Technical Community
          </span>
        </div>
      </foreignObject>
    </svg>
  );
}

export { ChatBubbles };
