function PptIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>7939D213-B6FA-4A7B-8A8C-09BAEB8C8FCE</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-134, -621)">
                    <g id="Powerpoint" transform="translate(134, 621)">
                        <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                            <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                            <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                        </g>
                        <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                        <polygon id="Path" fill="#FFFFFF" points="20.8873313 13.0013948 19.0494315 13.0013948 19.0494315 18 17.8131486 18 17.8131486 13.0013948 16 13.0013948 16 12 20.8873313 12"></polygon>
                        <path d="M6.23626373,15.885989 L6.23626373,18 L5,18 L5,12 L7.34065934,12 C7.79121104,12 8.18749829,12.0824168 8.52953297,12.2472527 C8.87156764,12.4120887 9.13461447,12.6462897 9.31868132,12.9498626 C9.50274817,13.2534356 9.59478022,13.5988992 9.59478022,13.9862637 C9.59478022,14.5741788 9.39354597,15.037773 8.99107143,15.3770604 C8.58859689,15.7163479 8.03159697,15.885989 7.32005494,15.885989 L6.23626373,15.885989 Z M6.23626373,14.8846154 L7.34065934,14.8846154 C7.66758405,14.8846154 7.91689474,14.8076931 8.0885989,14.6538462 C8.26030305,14.4999992 8.34615384,14.2802212 8.34615384,13.9945055 C8.34615384,13.700548 8.25961625,13.462913 8.08653846,13.2815934 C7.91346067,13.1002738 7.67445207,13.0068682 7.36950549,13.0013736 L6.23626373,13.0013736 L6.23626373,14.8846154 Z" id="Shape" fill="#FFFFFF"></path>
                        <path d="M11.7362637,15.885989 L11.7362637,18 L10.5,18 L10.5,12 L12.8406593,12 C13.291211,12 13.6874983,12.0824168 14.029533,12.2472527 C14.3715676,12.4120887 14.6346145,12.6462897 14.8186813,12.9498626 C15.0027482,13.2534356 15.0947802,13.5988992 15.0947802,13.9862637 C15.0947802,14.5741788 14.893546,15.037773 14.4910714,15.3770604 C14.0885969,15.7163479 13.531597,15.885989 12.8200549,15.885989 L11.7362637,15.885989 Z M11.7362637,14.8846154 L12.8406593,14.8846154 C13.1675841,14.8846154 13.4168947,14.8076931 13.5885989,14.6538462 C13.7603031,14.4999992 13.8461538,14.2802212 13.8461538,13.9945055 C13.8461538,13.700548 13.7596163,13.462913 13.5865385,13.2815934 C13.4134607,13.1002738 13.1744521,13.0068682 12.8695055,13.0013736 L11.7362637,13.0013736 L11.7362637,14.8846154 Z" id="Shape-Copy" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { PptIcon };