import { useState } from "react";
import { DefaultRatingStar } from "../../svg/deafultRatingStar";
import "./index.css";

type RatingProps = {
  rating: number;
  setRating: (rating: number) => void;
};

function FeedbackRating({ rating, setRating }: RatingProps) {
  const [hoverRating, setHoverRating] = useState(0);
  const [currentHoveringRating, setCurrentHoveringRating] = useState(0);
  const [currentRatingClass, setCurrentRatingClass] = useState("rating-none");

  const handleMouseEnter = (rating: number) => {
    setHoverRating(rating);
    switch (rating) {
      case 1:
        setCurrentHoveringRating(1);
        setCurrentRatingClass("rating-one");
        break;
      case 2:
        setCurrentHoveringRating(2);
        setCurrentRatingClass("rating-two");
        break;
      case 3:
        setCurrentHoveringRating(3);
        setCurrentRatingClass("rating-three");
        break;
      case 4:
        setCurrentHoveringRating(4);
        setCurrentRatingClass("rating-four");
        break;
      case 5:
        setCurrentHoveringRating(5);
        setCurrentRatingClass("rating-five");
        break;
      default:
        break;
    }
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
    setCurrentRatingClass("rating-none");
  };

  const handleClick = (rating: number) => {
    setRating(rating);
  };

  const renderStar = (starNumber: number) => {
    const filled = starNumber <= (hoverRating || rating);
    const starClass = filled ? `rating-${starNumber}` : "rating-none";
    return (
      <span
        key={starNumber}
        onMouseEnter={() => handleMouseEnter(starNumber)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleClick(starNumber)}
      >
        <DefaultRatingStar
          id={`star-${starNumber}`}
          className={
            currentHoveringRating >= starNumber ? currentRatingClass : starClass
          }
        />
      </span>
    );
  };

  return (
    <div className="align-feedback-rating">
      {[1, 2, 3, 4, 5].map(renderStar)}
    </div>
  );
}

export { FeedbackRating };
