import "./index.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import "@mdxeditor/editor/style.css";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import logo from "../../svg/fair-logo-red.svg";
import { SaveIcon } from "../../svg/saveIcon";
import { UpVoteFilledIcon } from "../../svg/upVoteFilled";
import { TeamsIcon } from "../../svg/teamsIcon";
import { UpVoteIcon } from "../../svg/upVoteIcon";
import { SaveFilledIcon } from "../../svg/saveFilledIcon";
import { TeamsIconFilled } from "../../svg/teamIconFilled";
import { Tab, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  saveTeam,
  shareTeam,
  interviewLike,
} from "../../data/api/Interview/interviewSlice";

import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
} from "@mdxeditor/editor";
//import { Rating } from "../Rating";
import {
  getDownloadInterview,
  // setFavorite,
  // updateInterviewFavorite,
  updateHRQuestions,
  // updateInterviewRating,
} from "../../data/api/Interview/interviewSlice";
import { useAppDispatch } from "../../redux/hooks";

const InterviewResponse = () => {
  const {
    questions,
    gaps,
    //queryId,
    // role,
    level,
    title,
    // isFavorite,
    // interviewResults,
    // rating,
    updateFavoriteError,
    isLoadingeditHRQuestions,
    updateRatingError,
    session_id,
    interview_id,
    showGaps,
    showShareValue,
    showSaveValue,
  } = useAppSelector((state: RootState) => state.interviewTabDetails);
  const dispatch = useAppDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [editedResponse, setEditedResponse] = useState(questions);
  const [originalResponse, setOriginalResponse] = useState(questions);
  const [isDownload, setIsDownload] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showSaveIcon, setShowSaveIcon] = useState(showSaveValue);
  const [showShareIcon, setShowShareIcon] = useState(showShareValue);
  const [showDownVote, setShowDownVote] = useState(false);
  const [saveShareInputValue, setSaveShareInputValue] = useState(
    title + ", " + level
  );
  const [resumeGapsBool] = useState(showGaps);

  const downloadResponse = (fileType: string) => {
    setIsDownload(false);
    let fileName = `HR Interview Questions-${title}-${level}.${fileType}`;
    dispatch(
      getDownloadInterview({
        interview_id,
        fileType,
        fileName,
      })
    );
  };

  const handleEdit = () => {
    setOriginalResponse(editedResponse);
    setIsEditable(true);
  };

  const handleSave = () => {
    setIsEditable(false);
    dispatch(
      updateHRQuestions({ session_id, interview_id, questions: editedResponse })
    );
  };

  const handleCancel = () => {
    setEditedResponse(originalResponse);
    setIsEditable(false);
  };

  // const onFavoriteClick = () => {
  //   if (queryId) {
  //     dispatch(setFavorite(!isFavorite));
  //     dispatch(updateInterviewFavorite({ queryId, favorite: !isFavorite }));
  //   }
  // };

  // const setRating = (rating: number) => {
  //   dispatch(
  //     updateInterviewRating({
  //       queryId,
  //       rating,
  //     })
  //   );
  // };

  const getErrorMessage = () => {
    if (updateFavoriteError) {
      return "Error setting favorite";
    }
    if (updateRatingError) {
      return "Error setting rating";
    }
    return "Error";
  };

  const showSaveModal = () => {
    setShowSave(true);
  };

  const hideSaveModal = () => {
    setShowSave(false);
  };

  const showShareModal = () => {
    setShowShare(true);
  };

  const hideShareModal = () => {
    setShowShare(false);
  };

  const saveTeamFn = () => {
    setSaveShareInputValue(title + ", " + level);
    setShowSave(false);
    setShowSaveIcon(!showSaveIcon);
    dispatch(
      saveTeam({ session_id, interview_id, saved_status: !showSaveIcon })
    );
  };

  const getLoadingComponent = () => {
    return (
      <div className="hr-loading-container w-100 h-100 d-flex justify-content-center">
        <div className="spinner-container d-flex flex-column align-items-center">
          <Spinner animation="border" variant="primary" />
          <div className="loading-txt">
            Editing your questions
            <br />
            This may take a few moments
          </div>
        </div>
      </div>
    );
  };

  const shareTeamFn = () => {
    setShowShare(false);
    setShowShareIcon(!showShareIcon);
    dispatch(
      shareTeam({ session_id, interview_id, shared_status: !showShareIcon })
    );
  };
  const downVoteTriggered = () => {
    dispatch(interviewLike({ interview_id, like: !showDownVote }));
    setShowDownVote(!showDownVote);
  };
  return (
    <div
      className="interview-response-container"
      data-testid="interview-response-container"
    >
      <div className="response-header" data-testid="response-header">
        <div className="response-heading">
          <img src={logo} />
          HR Interview Questions - {title}, {level}
        </div>
      </div>
      {isLoadingeditHRQuestions && getLoadingComponent()}
      <Tabs defaultActiveKey="questions" className="top-nav-tabs-hr">
        <Tab eventKey="questions" title="View Questions">
          <div
            className={`
          ${isEditable ? "response-content-editable" : "response-content"}`}
            data-testid="response-content"
          >
            {isEditable ? (
              <MDXEditor
                markdown={questions}
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  quotePlugin(),
                  thematicBreakPlugin(),
                ]}
                onChange={(value) => setEditedResponse(value)}
                className="editable-response"
              ></MDXEditor>
            ) : (
              <div className={`hr-markdown-container`}>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {questions}
                </ReactMarkdown>
              </div>
            )}
          </div>
        </Tab>
        {resumeGapsBool ? (
          <Tab eventKey="gaps" title="View Resume Gaps">
            <div
              className={`
          ${isEditable ? "response-content-editable" : "response-content"}`}
              data-testid="response-content"
            >
              {isEditable ? (
                <MDXEditor
                  markdown={gaps}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                  ]}
                  onChange={(value) => setEditedResponse(value)}
                  className="editable-response"
                ></MDXEditor>
              ) : (
                <div className={`hr-markdown-container`}>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {gaps}
                  </ReactMarkdown>
                </div>
              )}
            </div>
          </Tab>
        ) : (
          ""
        )}
      </Tabs>

      {(updateFavoriteError || updateRatingError) && (
        <Alert variant="danger" dismissible>
          <Alert.Heading>Error</Alert.Heading>
          <p>{getErrorMessage()}</p>
        </Alert>
      )}
      <div
        className={`response-footer position-relative ${
          isEditable ? "justify-content-end" : "justify-content-between"
        }`}
        data-testid="response-footer"
      >
        {!isEditable && (
          <div className="d-flex gap-10 align-items-center">
            <div
              className="download-text"
              onClick={() => setIsDownload(!isDownload)}
            >
              Download
            </div>
            {isDownload && (
              <div className="download-options">
                <div onClick={() => downloadResponse("pdf")}>PDF</div>
                <div onClick={() => downloadResponse("txt")}>TXT</div>
              </div>
            )}
            <div className="edit-text" onClick={handleEdit}>
              Edit
            </div>
          </div>
        )}

        <div className="cursor-pointer">
          {!isEditable ? (
            <>
              <Modal show={showSave} onHide={hideSaveModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Save Questionnaire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="dropdown-label">Questionnaire Name</div>
                    <input
                      type="text"
                      className="role-textbox"
                      value={saveShareInputValue}
                      readOnly={true}
                      // onChange={(e) => setRole(e.target.value)}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={hideSaveModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={saveTeamFn}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={showShare} onHide={hideShareModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Team Share</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="dropdown-label">Questionnaire Name</div>
                    <input
                      type="text"
                      className="role-textbox"
                      value={saveShareInputValue}
                      readOnly={true}
                      // onChange={(e) => setRole(e.target.value)}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={hideShareModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={shareTeamFn}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
              <span onClick={showSaveModal}>
                {showSaveIcon ? <SaveFilledIcon /> : <SaveIcon />}
              </span>
              &nbsp;
              <span onClick={showShareModal}>
                {showShareIcon ? <TeamsIconFilled /> : <TeamsIcon />}
              </span>
              &nbsp;
              <span onClick={downVoteTriggered}>
                {showDownVote ? <UpVoteFilledIcon /> : <UpVoteIcon />}
              </span>
            </>
          ) : (
            // <Rating
            //   rating={rating}
            //   isFavourite={isFavorite}
            //   setFavourite={() => {}}
            //   setRating={setRating}
            //   searchResult={interviewResults}
            //   type="interview"
            //   onFavoriteClick={onFavoriteClick}
            // />

            <div className="save-btn-container d-flex">
              <Button
                className="text-primary cancel-btn"
                variant="transparent"
                onClick={handleCancel}
                data-testid="cancel-button"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                data-testid="update-button"
                onClick={handleSave}
                className="update-btn"
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterviewResponse;
