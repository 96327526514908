import "./index.css";
import { TeamShared } from "../../../svg/teamShared";
import { SavedIcon } from "../../../svg/saved";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { SearchIcon } from "../../../svg/searchIcon";
import SelectDropDown from "../../../components/SelectDropdown";
import QuestionnaireTable from "../questionnaireTable";
import { INTERVIEW_LEVELS } from "../../../constants";
import { fetchInterviewListing } from "../../../data/api/Interview/interviewSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";

function LandingPage({ setNewQuestionnaire, setViewData, setQueryId }: any) {
  const dispatch = useAppDispatch();

  const { interviewListing } = useAppSelector(
    (state: RootState) => state.interviewTabDetails
  );

  const [tabName, setTabName] = useState("team_shared");
  const [jobTitles, setJobTitles] = useState<{ name: string; value: string }[]>(
    [{ name: "All", value: "All" }]
  );
  const [selectedJobTitle, setSelectedJobTitle] = useState<{
    name: string;
    value: string;
  }>({
    name: "All",
    value: "All",
  });
  const [level, setLevel] = useState<{ name: string; value: string }>({
    name: "All",
    value: "All",
  });
  const [searchValue, setSearchValue] = useState("");

  const [displayedFiles, setDisplayedFiles] = useState<
    Array<{
      id: string;
      name: string;
      inputs: { title: string; level: string };
      last_modified_date: string;
    }>
  >([]);

  useEffect(() => {
    dispatch(fetchInterviewListing({ status: tabName }));
  }, [tabName]);

  useEffect(() => {
    const uniqueTitles = Array.from(
      new Set(interviewListing.map((item) => item.inputs.title))
    ).map((title) => ({
      name: title,
      value: title,
    }));

    setJobTitles([{ name: "All", value: "All" }, ...uniqueTitles]);

    const filtered = interviewListing.filter(
      (item) =>
        (item.name.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
          item.inputs.title
            .toLowerCase()
            .includes(searchValue.toLowerCase().trim())) &&
        (level.name !== "All" ? item.inputs.level === level.name : true) &&
        (selectedJobTitle.name !== "All"
          ? item.inputs.title === selectedJobTitle.name
          : true)
    );

    setDisplayedFiles(filtered);
  }, [searchValue, level, selectedJobTitle, interviewListing]);

  return (
    <div className="hr-interview-component">
      <div className="hr-interview-header">
        HR Interview
        <Button
          variant="primary"
          className="create-new-question-btn"
          onClick={() => setNewQuestionnaire(true)}
        >
          Create New Questionnaire
        </Button>
      </div>
      <div className="tab-switch-container">
        <div className="d-flex">
          <button
            className={tabName === "team_shared" ? "team-btn" : "saved-btn"}
            onClick={() => setTabName("team_shared")}
          >
            <TeamShared
              fillColor={tabName === "team_shared" ? "white" : "#367ED4"}
            />
            Team
          </button>
          <button
            className={tabName === "saved" ? "team-btn" : "saved-btn"}
            onClick={() => setTabName("saved")}
          >
            <SavedIcon fillColor={tabName === "saved" ? "white" : "#367ED4"} />
            Saved
          </button>
        </div>
        <div className="hr-search-file-box">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search"
            className="search-file-input"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          ></input>
        </div>
      </div>
      <div className="tab-container">
        <div className="hr-filter-container">
          <div>
            <div className="hr-dropdown-label">Job Title:</div>
            <SelectDropDown
              value={selectedJobTitle}
              setValue={setSelectedJobTitle}
              data={jobTitles}
              placeHolder="Select"
              width="200px"
            />
          </div>
          <div>
            <div className="hr-dropdown-label">Level:</div>
            <SelectDropDown
              value={level}
              setValue={setLevel}
              data={INTERVIEW_LEVELS}
              placeHolder="Select"
              width="85px"
            />
          </div>
        </div>
        <QuestionnaireTable
          setNewQuestionnaire={setNewQuestionnaire}
          setViewData={setViewData}
          setQueryId={setQueryId}
          displayedFiles={displayedFiles}
        />
      </div>
    </div>
  );
}
export default LandingPage;
