import { RootState } from "../redux/store";
import { ResponseConfig } from "../types";

export function getResponseConfig(state: RootState): ResponseConfig {
  const responseSettings = state.userDetails.response_settings;

  return {
    creativity: responseSettings?.generative_creativity?.toLowerCase() ?? "liberal",
    length: responseSettings?.response_length?.toLowerCase() ?? "verbose",
    speed: responseSettings?.response_speed?.toLowerCase() ?? "normal"
  }
}