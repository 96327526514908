function SaveFilledIcon({ }) {
    return (
        <svg width="23" height="32" viewBox="0 0 23 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="14" height="20" transform="translate(1 1)" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 1H4.5C2.567 1 1 2.54978 1 4.46154V31L11.5 21.7692L22 31V4.46154C22 2.54978 20.433 1 18.5 1Z" fill="#367ED4" />
            <path d="M18.5 1H4.5C2.567 1 1 2.54978 1 4.46154V31L11.5 21.7692L22 31V4.46154C22 2.54978 20.433 1 18.5 1" stroke="#367ED4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    );
}

export { SaveFilledIcon };
