import "./index.css";
import { SetStateAction, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SelectDropDown from "../../components/SelectDropdown";
import InterviewResponse from "../../components/InterviewResponse";
import { Alert, Button, Spinner } from "react-bootstrap";
// import { FiPlusCircle } from "react-icons/fi";
import { ArrowDownIcon } from "../../svg/arrowDownIcon";
import { ArrowUpIcon } from "../../svg/arrowUpIcon";
import {
  fetchCategories,
  fetchInterviewResults,
  viewInterviewDetails,
  setDefaultValues,
} from "../../data/api/Interview/interviewSlice";
import { RootState } from "../../redux/store";
import logo from "../../svg/fair-logo-red.svg";
import { UploadIcon } from "../../svg/uploadIcon";
import { useDropzone, FileRejection } from "react-dropzone";
import { CloseCircle } from "../../svg/closeCircle";
import LandingPage from "./landingPage";

function HRInterview() {
  interface CategoriesData {
    [key: string]: string;
  }

  const {
    interviewCategories,
    isLoading,
    showResponse,
    isLoadingCategories,
    // getCategoriesError,
    interviewResultError,
    interviewResults,
    interviewLevels,
    title,
    level,
    categories,
    description,
  } = useAppSelector((state: RootState) => state.interviewTabDetails);

  const [role, setRole] = useState("");
  const dispatch = useAppDispatch();

  // const [interviewCategoriesData, setInterviewCategoriesData] = useState<
  //   { name: string; value: string }[]
  // >([]);

  const [interviewLevelsData, setInterviewLevelsData] = useState<
    { name: string; value: string }[]
  >([]);

  const [selectedLevel, setSelectedLevel] = useState<{
    name: string;
    value: string;
  }>({
    name: "",
    value: "",
  });

  // const [selectedCategory, setSelectedCategory] = useState<{
  //   name: string;
  //   value: string;
  // }>({
  //   name: "All",
  //   value: "All",
  // });

  // const [selectedQuestions, setSelectedQuestions] = useState<{
  //   name: string;
  //   value: string;
  // }>({
  //   name: "5",
  //   value: "5",
  // });

  const [jobDescription, setJobDescription] = useState("");
  // const [additionalOption, setAdditionalOption] = useState(false);
  const [expandContainer, setExpandContainer] = useState(true);
  const [enableCollapse, setEnableCollapse] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [gapsChecked, setGapsChecked] = useState(false);
  const [newQuestionnaire, setNewQuestionnaire] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [queryId, setQueryId] = useState("");
  const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [textBoxValues, setTextBoxValues] = useState<CategoriesData>({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    if (viewData && queryId) {
      dispatch(
        viewInterviewDetails({
          query_id: queryId,
        })
      );
    }
  }, [viewData]);

  useEffect(() => {
    setRole(title);
    setSelectedLevel({
      name: level,
      value: level,
    });
    const initialCheckedState: { [key: string]: boolean } = {};
    const initialTextBoxValues: CategoriesData = {};
    interviewCategories.forEach((category) => {
      initialCheckedState[category] = categories.hasOwnProperty(category);
      initialTextBoxValues[category] = categories[category] || "";
    });
    setCheckedState(initialCheckedState);
    setTextBoxValues(initialTextBoxValues);
    setJobDescription(description);
  }, [title, level, categories]);

  // useEffect(() => {
  //   const updateCategoriesData = () => {
  //     console.log(interviewCategories)
  //     const categoriesData = [
  //       { name: "All", value: "All" },
  //       ...interviewCategories.map((category) => ({
  //         name: category,
  //         value: category,
  //       })),
  //       { name: "None", value: "None" },
  //     ];
  //     setInterviewCategoriesData(categoriesData);
  //   };

  //   updateCategoriesData();
  // }, [interviewCategories]);

  useEffect(() => {
    const updateLevelsData = () => {
      if (interviewLevels === undefined) return;
      const levelsData = [
        ...interviewLevels.map((category) => ({
          name: category,
          value: category,
        })),
      ];
      setInterviewLevelsData(levelsData);
    };

    updateLevelsData();
  }, [interviewLevels]);

  useEffect(() => {
    if (interviewResultError) {
      setShowAlert(true);
      setErrorMessage("Unable to fetch results");
    } else {
      setShowAlert(false);
    }
  }, [interviewResultError]);

  useEffect(() => {
    if (showResponse && interviewResults) {
      setExpandContainer(false);
    }
  }, [showResponse, interviewResults]);

  const handleDescriptionChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setJobDescription(event.target.value);
  };

  const handleClearDescription = () => {
    setJobDescription("");
  };

  const generateResponse = () => {
    let categoriesString = getSelectedCategoriesString();

    dispatch(
      fetchInterviewResults({
        file_data: file || undefined,
        categories: categoriesString,
        share: "",
        likes: "",
        title: role,
        level: selectedLevel.value,
        job_description: jobDescription,
        // numberOfOutput: '5',
        additional_instructions: instructions,
        gaps: gapsChecked.toString(),
      })
    );
  };

  const viewDetails = () => {
    setExpandContainer(true);
    setEnableCollapse(true);
  };

  const getLoadingComponent = () => {
    return (
      <div className="hr-loading-container w-100 h-100 d-flex justify-content-center">
        <div className="spinner-container d-flex flex-column align-items-center">
          <Spinner animation="border" variant="primary" />
          <div className="loading-txt">
            Generating your questions
            <br />
            This may take a few moments
          </div>
        </div>
      </div>
    );
  };

  const onDropAccepted = (acceptedFiles: File[]) => {
    setIsUploading(true);
    setTimeout(() => {
      setFile(acceptedFiles[0]);
      setIsUploading(false);
    }, 1000);
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    console.log(fileRejections);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropAccepted,
    onDropRejected: onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
  });

  const handleRemoveFile = () => {
    setFile(null);
  };

  const viewResume = () => {
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    }
  };

  const handleCheckboxChange = (category: string) => {
    setCheckedState((prevState) => {
      const newState = {
        ...prevState,
        [category]: !prevState[category],
      };
      updateSelectAllState(newState);
      return newState;
    });
  };

  const handleSelectAllChange = () => {
    const newCheckedState = { ...checkedState };
    interviewCategories.forEach((category) => {
      newCheckedState[category] = !selectAllChecked;
    });
    setCheckedState(newCheckedState);
    setSelectAllChecked(!selectAllChecked);
  };

  const updateSelectAllState = (newState: { [x: string]: unknown }) => {
    const allChecked = interviewCategories.every(
      (category) => newState[category]
    );
    setSelectAllChecked(allChecked);
  };

  useEffect(() => {
    updateSelectAllState(checkedState);
  }, [checkedState]);

  const handleTextBoxChange = (category: string, value: string) => {
    setTextBoxValues((prevState) => ({
      ...prevState,
      [category]: value,
    }));
  };

  const getSelectedCategoriesString = () => {
    return interviewCategories
      .filter((category) => checkedState[category])
      .map((category) => `${category}:${textBoxValues[category] || 0}`)
      .join(",");
  };

  const closeDetailView = () => {
    dispatch(setDefaultValues());
    setNewQuestionnaire(false);
    setViewData(false);
    setExpandContainer(true);
    setEnableCollapse(false);
    setFile(null);
  };

  return (
    <>
      {!newQuestionnaire ? (
        <LandingPage
          setNewQuestionnaire={setNewQuestionnaire}
          setViewData={setViewData}
          setQueryId={setQueryId}
        />
      ) : (
        <div className="hr-interview-component">
          <div className="hr-interview-header">
            Create New Questionnaire
            <Button
              variant="outline-primary"
              className="close-btn"
              onClick={closeDetailView}
            >
              Close
            </Button>
          </div>
          {isLoading && getLoadingComponent()}
          <div
            className={`hr-interview-content ${
              expandContainer ? "" : "hr-interview-content-collapsed"
            } `}
          >
            <div
              className={`${
                expandContainer
                  ? "interview-container"
                  : "interview-container-collapsed"
              } `}
            >
              <div
                className={`${
                  expandContainer
                    ? "interview-options"
                    : "interview-options-collapsed"
                } `}
              >
                <div className="heading-style">
                  {/* <div className="interview-title">Question Details</div> */}
                  {!expandContainer ? (
                    <div
                      className="d-flex align-items-center gap-5 cursor-pointer"
                      onClick={viewDetails}
                    >
                      <div className="view-details">View Details</div>
                      <ArrowDownIcon />
                    </div>
                  ) : enableCollapse ? (
                    <div
                      className="d-flex align-items-center gap-5 cursor-pointer"
                      onClick={() => setExpandContainer(false)}
                    >
                      <div className="view-details">Hide Details</div>
                      <ArrowUpIcon />
                    </div>
                  ) : null}
                </div>
                {expandContainer && (
                  <div>
                    <div className="dropdown-container-select">
                      <div>
                        <div className="dropdown-label required">Job Title</div>
                        <input
                          type="text"
                          className="role-textbox"
                          placeholder="Enter Title"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        />
                      </div>
                      <div>
                        <div className="dropdown-label required">Level:</div>
                        <SelectDropDown
                          value={selectedLevel}
                          setValue={setSelectedLevel}
                          data={interviewLevelsData}
                          showLoading={isLoadingCategories}
                          placeHolder="Select"
                          width="85px"
                        />
                      </div>
                      {/* <div>
                    <div className="dropdown-label">Categories:</div>
                    <SelectDropDown
                      value={selectedCategory}
                      setValue={setSelectedCategory}
                      data={interviewCategoriesData}
                      placeHolder="Select"
                      width="200px"
                      showLoading={isLoadingCategories}
                      showError={!!getCategoriesError}
                    />
                  </div>
                  <div>
                    <div className="dropdown-label">Questions per Category</div>
                    <SelectDropDown
                      value={selectedQuestions}
                      setValue={setSelectedQuestions}
                      data={NO_OF_QUESTIONS}
                      placeHolder="5"
                      width="85px"
                    />
                  </div> */}
                    </div>
                    <div className="dropdown-label required">
                      Select Categories
                    </div>
                    {isLoadingCategories ? (
                      <div className="w-100 d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <table className="extracted-file-table">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={selectAllChecked}
                                onChange={handleSelectAllChange}
                              />
                            </th>
                            <th>Category Name</th>
                            <th>No. of Questions</th>
                          </tr>
                        </thead>
                        {/* {uploadedFiles > 0 && ( */}
                        <tbody>
                          {interviewCategories.map((category, index) => (
                            <tr
                              key={index}
                              // onClick={(e) =>
                              //   viewContractDetails(e, file.file_name, file.file_id)
                              // }
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  checked={!!checkedState[category]}
                                  onChange={() =>
                                    handleCheckboxChange(category)
                                  }
                                />
                              </td>
                              <td className="file-name-color">{category}</td>
                              <td>
                                <input
                                  type="text"
                                  className="question-textbox"
                                  value={textBoxValues[category] || ""}
                                  onChange={(e) =>
                                    handleTextBoxChange(
                                      category,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* )} */}
                      </table>
                    )}
                    <div className="dropdown-label mt-8 required">
                      Job Description:
                    </div>
                    <textarea
                      className="job-description-textarea"
                      placeholder="Enter or copy and paste a job description here"
                      value={jobDescription}
                      onChange={handleDescriptionChange}
                    ></textarea>
                    <div className="dropdown-label mt-8">
                      Additional Instructions (Optional)
                    </div>
                    <textarea
                      value={instructions}
                      onChange={(e) => setInstructions(e.target.value)}
                      className="additional-textarea"
                      placeholder="Enter instructions for a more specific response (e.g. ask more technical questions)"
                    ></textarea>
                    <div className="dropdown-label mt-8">
                      Resume: (Optional)
                    </div>
                    {file && (
                      <div className="uploaded-resume-container">
                        <div className="uploaded-resume">
                          <div className="source-file-name">{file.name}</div>
                          <div
                            className="remove-file"
                            onClick={handleRemoveFile}
                          >
                            <CloseCircle size="20px" />
                          </div>
                        </div>
                        <button
                          className="view-resume-btn"
                          onClick={viewResume}
                        >
                          View Resume
                        </button>
                      </div>
                    )}
                    {!file && (
                      <div
                        {...getRootProps()}
                        className="resume-dropzone"
                        data-testid="resume-dropzone"
                      >
                        {!isUploading ? (
                          <>
                            <input {...getInputProps()} />
                            <div className="resume-drag-drop">
                              <UploadIcon />
                              Drag and drop a PDF file or{" "}
                              <button className="browse-button">
                                Browse Files
                              </button>
                            </div>
                          </>
                        ) : (
                          <div>Uploading ...</div>
                        )}
                      </div>
                    )}
                    {/* {additionalOption && (
                  <>
                    <div className="dropdown-label mt-8">
                      Additional Instructions:
                    </div>
                    <textarea
                      value={instructions}
                      onChange={(e) => setInstructions(e.target.value)}
                      className="additional-textarea"
                      placeholder="Enter instructions for a more specific response (e.g. ask more technical questions)"
                    ></textarea>
                  </>
                )}
                <div
                  className={`d-flex gap-10 justify-content-between align-items-center ${!jobDescription.trim() ? "disabled-opacity" : ""
                    }`}
                >
                  <div
                    className="d-flex gap-3"
                    onClick={() => setAdditionalOption(true)}
                  >
                    {!additionalOption && (
                      <>
                        <div
                          className={`add-icon ${!jobDescription.trim() ? "" : "cursor-pointer"
                            }`}
                        >
                          <FiPlusCircle size={22} />
                        </div>
                        <button
                          className="additional-details"
                          disabled={!jobDescription.trim()}
                        >
                          Additional Response Details
                        </button>
                      </>
                    )}
                  </div> */}
                    {file ? (
                      <>
                        <input
                          type="checkbox"
                          onChange={(e) => setGapsChecked(e.target.checked)}
                        ></input>
                        <span className="dropdown-label">
                          &nbsp; Define Resume Gaps
                        </span>
                      </>
                    ) : (
                      <>
                        <input
                          type="checkbox"
                          disabled={true}
                          onChange={(e) => setGapsChecked(e.target.checked)}
                        ></input>
                        <span className="dropdown-label">
                          &nbsp; Define Resume Gaps
                        </span>
                      </>
                    )}
                    <div className="d-flex justify-content-end gap-10">
                      <button
                        className={`clear-description`}
                        onClick={handleClearDescription}
                        disabled={!jobDescription.trim()}
                      >
                        Clear Description
                      </button>
                      <div
                        className={
                          jobDescription.trim() &&
                          (!role.trim() || !selectedLevel.name)
                            ? //  !selectedQuestions.name
                              "disabled-opacity"
                            : ""
                        }
                      >
                        <Button
                          className={`generate-questions`}
                          disabled={
                            !jobDescription.trim() ||
                            !role.trim() ||
                            !selectedLevel.name
                            // !selectedQuestions.name
                          }
                          onClick={generateResponse}
                          variant="primary"
                        >
                          Generate Questions
                        </Button>
                      </div>
                    </div>
                  </div>
                  // </div>
                )}
              </div>
              {showResponse && !interviewResultError && <InterviewResponse />}
              {showAlert && !showResponse && (
                <div className="mt-7">
                  <Alert variant="danger">
                    <div className="d-flex gap-6 align-items-center">
                      <img src={logo} height={40} alt="Logo" />
                      {errorMessage}
                    </div>
                  </Alert>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HRInterview;
