import { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Button,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { EditIcon } from "../../svg/editIcon";
import { UpdateInput } from "../UpdateInput";
import NoResponse from "../NoResponse";
import { RackerName } from "../../svg/RackerName";
import { FairUser } from "../../svg/fairUser";
import { PublicType } from "../../svg/publicType";
import { CompanyType } from "../../svg/companyType";
import { ConfidentialType } from "../../svg/confidentialType";
import {
  PUBLIC,
  INTERNAL,
  CONFIDENTIAL,
  CUSTOMER,
  EYESONLY,
} from "../../constants";
import { CustomerType } from "../../svg/customerType";
import { EyesOnlyType } from "../../svg/eyesOnlyType";
import FileIcon from "../FileIcons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  Source,
  deleteQuestion,
  fetchPutRatingResults,
  fetchSearchResults,
  setFavorite,
  setRating,
  fetchPutFavoriteResults,
} from "../../data/api/Search/searchSlice";
import { RootState } from "../../redux/store";
import { SINGLE_MESSAGE } from "../../constants";
import "./index.css";
import { Rating } from "../Rating";

// type Metadata = {
//   answer: string;
//   output_text_infotypes: string[];
//   query: string;
//   question_id: string;
//   status: string;
//   source?: Source[];
// };

type SearchResultData = {
  search_id: string;
  query: string;
  answer: string;
  metadata: {
    source: Source[];
    data_classification: string;
    favorite: boolean;
    rating: number;
  };
};

type SearchResponseProps = {
  searchResult: {
    query: string;
    data: SearchResultData;
  };
  resultId: number;
  index: number;
  rackerName?: string;
};

type AnswerSectionProps = {
  onEditQuestion: (searchTerm: string) => void;
} & SearchResponseProps;

const linkSplit = (link: string) => {
  let linkArr = link.split("/");
  console.log(linkArr[linkArr.length - 2], "linkArr[linkArr.length - 2]");
  return linkArr[linkArr.length - 2];
};

export function AnswerSection({ searchResult }: AnswerSectionProps) {
  const { data } = searchResult;

  return (
    <section
      id="search-response"
      data-testid="search-response"
      key={data.search_id}
    >
      {data.answer && (
        <>
          <div className="default-padding d-flex">
            {<FairUser />}
            <div className={`markdown-container ${"custom-margin"}`}>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {data.answer}
              </ReactMarkdown>
            </div>
          </div>
          {/* {output_text_infotypes.length > 0
                && (
                  <div className="masked-text" data-testid="masked-response">
                    * We have masked the following from the response:
                    {' '}
                    {output_text_infotypes.join(', ')}
                  </div>
                )} */}
          {data?.metadata.source && data?.metadata.source.length > 0 && (
            <>
              <div className="divider-docs">Related Documents</div>
              <div className="grid-container">
                {data?.metadata.source?.map((key, index) => (
                  // <a className="link-font" href={key.source_link} rel="noreferrer" target="_blank" key={key.source_link}>
                  //   <FileIcon url={key.source_link} />
                  //   <span>{key.source}</span>
                  // </a>
                  <Dropdown
                    key={key.title ? key.title : linkSplit(key.link)}
                    onToggle={() => window.open(key.link, "_blank")}
                  >
                    <Dropdown.Toggle
                      id={`dropdown-${key.link}-${index}`}
                      className="supporting-doc-btn no-hover"
                    >
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 300 }}
                        // overlay={()=>renderTooltip(key.data_set,key.data_classification)}
                        overlay={(props: any) => (
                          <Tooltip id="button-tooltip" {...props}>
                            {!key?.data_set && !key?.data_classification ? (
                              <div className="tooltip-dataset">N/A</div>
                            ) : (
                              <>
                                <div className="tooltip-dataset">
                                  {key.data_set}{" "}
                                </div>
                                <div className="tooltip-dataset">
                                  <AnswerClassification
                                    dataClassification={key.data_classification}
                                  />
                                </div>
                              </>
                            )}
                          </Tooltip>
                        )}
                      >
                        <a
                          href={key.link}
                          onClick={(e: any) => {
                            e.preventDefault();
                          }}
                          key={key.link}
                        >
                          <div>
                            <FileIcon url={key.document_type} />
                          </div>
                          <span>
                            &nbsp;{key.title ? key.title : linkSplit(key.link)}
                          </span>
                        </a>
                      </OverlayTrigger>
                    </Dropdown.Toggle>
                  </Dropdown>
                ))}
              </div>
            </>
          )}
        </>
      )}
      {/* {
          !data.answer && data.query.length > 1 ? (
            <NoResponse
              type={MULTIPLE_MESSAGE}
              question={data.questions[innerIndex].query}
              resultId={resultId}
              index={index}
              onEditQuestion={onEditQuestion}
              deleteQuestion={() => dispatch(deleteQuestion(resultId))}
              data-testid="no-response"
            />
          ) : null
        } */}
    </section>
  );
}

function AnswerClassification(dataClassification: {
  dataClassification: string;
}) {
  const internalType = INTERNAL;
  const confidentialType = CONFIDENTIAL;
  const customerType = CUSTOMER;
  const publicType = PUBLIC;
  const eyesOnlyType = EYESONLY;
  let answerClassification: string = dataClassification.dataClassification;

  switch (answerClassification) {
    case "Rackspace Confidential - Internal":
      return (
        <span className="classification-font">
          <CompanyType />
          &nbsp;{internalType}
        </span>
      );
      break;
    case "Rackspace Public":
      return (
        <span className="classification-font">
          <PublicType />
          &nbsp;{publicType}
        </span>
      );
      break;
    case "Rackspace Confidential - Authorized":
      return (
        <span className="classification-font">
          <ConfidentialType />
          &nbsp;{confidentialType}
        </span>
      );
      break;
    case "Rackspace Confidential - Customer Facing":
      return (
        <span className="classification-font">
          <CustomerType />
          &nbsp;{customerType}
        </span>
      );
      break;
    case "EyesOnly":
      return (
        <span className="classification-font">
          <EyesOnlyType />
          &nbsp;{eyesOnlyType}
        </span>
      );
      break;
  }
}

function SearchResponse({
  searchResult,
  resultId,
  index,
  rackerName,
}: SearchResponseProps) {
  const dispatch = useAppDispatch();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { isSidePanelActive } = useAppSelector(
    (state: RootState) => state.sidePanelDetails
  );
  const [isCopied, setIsCopied] = useState(false);

  const handleQuestionChange = () => {
    setShowEdit(true);
  };

  const onEditQuestion = (searchTerm: string) => {
    dispatch(
      fetchSearchResults({
        query: searchTerm,
      })
    );
    dispatch(deleteQuestion(resultId));
  };

  const setPropmtRating = (rating: number) => {
    dispatch(fetchPutRatingResults({ rating: rating, id: resultId }));
    dispatch(setRating({ rating: rating, id: resultId }));
  };

  const setPromptFavorite = (isFavorite: boolean, searchResult: any) => {
    dispatch(fetchPutFavoriteResults({ favorite: isFavorite, id: resultId }));
    dispatch(
      setFavorite({
        favorite: isFavorite,
        id: resultId,
        searchResult: searchResult,
      })
    );
  };

  const stripMarkdown = (text: string) => {
    return text
      .replace(/[#*]/g, "")
      .replace(/^\s*\n/gm, "\n")
      .replace(/\n\n+/g, "\n\n");
  };

  const copyToClipboard = async () => {
    const plainText = stripMarkdown(searchResult?.data?.answer);
    await navigator.clipboard.writeText(plainText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <section
      className={`response-body ${
        !isSidePanelActive ? "search-response-non-split-body" : ""
      }`}
    >
      <Card>
        <Card.Body className="p-0">
          {!searchResult?.data?.answer ? (
            <NoResponse
              type={SINGLE_MESSAGE}
              question={searchResult.data.query}
              resultId={resultId}
              index={index}
              onEditQuestion={onEditQuestion}
              deleteQuestion={() => dispatch(deleteQuestion(resultId))}
            />
          ) : (
            <div
              data-testid="question-tag"
              className="question-tag d-flex align-items-center"
            >
              <RackerName rackerName={rackerName} />
              {showEdit ? (
                <UpdateInput
                  question={searchResult.data.query}
                  setShowEdit={(value) => setShowEdit(value)}
                  onEditQuestion={onEditQuestion}
                  searchId={resultId}
                />
              ) : (
                <span className="ms-5">{searchResult.data.query}</span>
              )}
              {index === 0 && !showEdit && (
                <Button
                  onClick={handleQuestionChange}
                  className="align-right edit-icon"
                >
                  <EditIcon />
                </Button>
              )}
            </div>
          )}
          <AnswerSection
            searchResult={searchResult}
            resultId={resultId}
            index={index}
            onEditQuestion={onEditQuestion}
          />
        </Card.Body>
        <Card.Footer className="footer-class">
          <span>
            {" "}
            <AnswerClassification
              dataClassification={
                searchResult.data.metadata.data_classification
              }
            />
          </span>
          <div className="align-thumbs">
            <Rating
              rating={searchResult.data.metadata.rating || 0}
              isFavourite={searchResult.data.metadata.favorite || false}
              setFavourite={setPromptFavorite}
              setRating={setPropmtRating}
              searchResult={searchResult.data}
              onFavoriteClick={() => {}}
              type="search"
              copyToClipboard={copyToClipboard}
              copied={isCopied}
            />
          </div>
        </Card.Footer>
      </Card>
    </section>
  );
}

export default SearchResponse;
