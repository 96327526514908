import "./index.css";

export const ContractConfirmPopup = ({
  isOpen,
  onClose,
  submit,
  title,
  buttonText,
  children,
}: any) => {
  return (
    <div
      className={`contract-popup-container ${isOpen ? "open" : ""}`}
      data-testid="contract-popup-container"
    >
      <div className="contract-popup">
        <div className="contract-popup-header">
          <div className="contract-popup-title">{title}</div>
        </div>
        {children}
        <div className="contract-popup-footer">
          <button className="contract-popup-close-button" onClick={onClose}>
            Cancel
          </button>
          <button
            className={`contract-revert-btn`}
            data-testid="contract-revert-btn"
            onClick={submit}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContractConfirmPopup;
