import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'serialize-error';
import { RawRequestError, fetchGetApi } from '../../../api/fetch';
import { History } from '../../../types';
import { HISTORY_URL } from '../../../constants';

type InitialState = {
  isLoading: boolean,
  history: History[],
  error: RawRequestError | null,
};

const initialState: InitialState = {
  isLoading: false,
  history: [] as History[],
  error: null,
};

// fetch history details
const fetchHistory = createAsyncThunk(
  'login/fetchHistory',
  async (_: void, thunkAPI) => fetchGetApi(HISTORY_URL)
    .then((res) => res)
    .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e))),
);

const searchSlice = createSlice({
  name: 'login/fetchHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHistory.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(
        fetchHistory.fulfilled,
      (state, action) => {
        const history  = action.payload.data.result;
        return {
          ...state,
          isLoading: false,
          history: history,
          error: null,
        };
      },
    );
    builder.addCase(fetchHistory.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        ...state,
        isLoading: false,
        history: [],
        error: action.payload as RawRequestError,
      };
    });
  },
});

export default searchSlice.reducer;
export {
    fetchHistory, initialState,
};
