function StatusYellow() {
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>A093A714-B291-4BCB-8FE5-5E8278049358</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-209, -69)" fill="#F2D540">
                    <circle id="yellow-status" cx="217" cy="77" r="8"></circle>
                </g>
            </g>
        </svg>
    );
}

export { StatusYellow }