function ContractIcon() {
  return (
    <svg
      width="51px"
      height="50px"
      viewBox="0 0 51 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>6AA4E994-CEBE-42BE-A606-5E82F1AF1824</title>
      <g
        id="Contract-Extraction"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contract-Extraction-Landing"
          transform="translate(-694, -410)"
          fill="#9E9E9E"
          fill-rule="nonzero"
        >
          <g id="contract-icon" transform="translate(694, 410)">
            <path
              d="M9.58577281,10 L35,10 L35,12.4867283 L9.58577281,12.4867283 L9.58577281,10 Z M9.58577281,22.4336415 L22.4294879,22.4336415 L22.4294879,24.9203698 L9.58577281,24.9203698 L9.58577281,22.4336415 Z M34.0120219,16.2168208 L9.58577281,16.2168208 L9.58577281,18.7035491 L31.4721876,18.7035491 L34.0120219,16.2168208 Z M17.2005106,42.4393707 C18.0680467,43.4126569 19.3775905,43.8938973 20.6854365,43.7200358 L33.0812258,43.7200358 C33.695296,43.7200358 34.1930983,43.2329468 34.1930983,42.6320922 C34.1930983,42.0312375 33.695296,41.5441485 33.0812258,41.5441485 L20.6854365,41.5441485 C19.238414,41.5192812 19.495733,40.2899049 19.6990468,39.9091247 C19.9641173,39.4622061 20.1706427,38.9844183 20.3137535,38.4870269 C20.5091832,37.6634804 20.1054513,36.8156769 19.3353057,36.4323677 C18.0995103,35.8875823 16.6440965,36.2162677 15.7773139,37.2358917 C15.230908,37.7876346 14.471658,38.631568 13.7441757,39.4521883 C14.1936899,37.7285748 14.7766287,35.5169408 15.3484488,33.359704 C15.6884088,32.3399162 15.164785,31.2364871 14.149215,30.8325664 C13.0244535,30.4549559 11.7928477,30.9933471 11.3298242,32.0650511 C10.64364,33.3565956 5.21134896,42.2637455 5.15734372,42.3538894 C4.95361954,42.6876031 4.94732054,43.1023138 5.1408195,43.441804 C5.33431846,43.7812941 5.69821828,43.9939872 6.09544142,43.9998843 C6.49266456,44.0055401 6.86286337,43.8035225 7.06658754,43.4698088 C7.26990136,43.1356546 11.6062039,36.0282743 12.973807,33.655003 C11.9445308,37.540516 10.8104209,41.8643148 10.7437085,42.2528661 C10.589374,42.8399457 10.8537021,43.4557235 11.3901829,43.7588909 C11.9882927,44.049132 12.7113835,43.9058349 13.1469414,43.410749 C13.3470784,43.2335696 13.7219383,42.81238 14.6670299,41.7384242 C15.5475813,40.7250808 16.4494496,39.7296587 17.3720567,38.752796 C17.7183255,38.3953288 17.9279929,38.5538577 17.7246791,38.9051081 C16.9151467,39.9060962 16.7151619,41.2545152 17.2005106,42.4393707"
              id="Shape"
            ></path>
            <path
              d="M40.8571429,30.7734375 L40.8571429,46.875 L3.14285714,46.875 L3.14285714,3.125 L40.8571429,3.125 L40.8571429,10.9265625 L42.9471429,8.8484375 C43.2688664,8.5329215 43.6215812,8.25025909 44,8.0046875 L44,1.5625 C44,0.699555078 43.2964475,0 42.4285714,0 L1.57142857,0 C0.703552536,0 0,0.699555078 0,1.5625 L0,48.4375 C0,49.3004449 0.703552536,50 1.57142857,50 L42.4285714,50 C43.2964475,50 44,49.3004449 44,48.4375 L44,30.3546875 C43.0361423,30.8625234 41.9214604,31.0110421 40.8571429,30.7734375"
              id="Path"
            ></path>
            <path
              d="M50.1009515,15.3726125 L49.2862842,14.5545108 C50.1453354,13.4144751 50.0733215,11.8215175 49.1149392,10.7643379 C47.8599248,9.65353022 45.9477763,9.76664802 44.8313155,11.0177461 L25.3213566,30.6068237 L24.0175773,35.5216909 C23.9754996,35.6528207 24.0103066,35.7965702 24.1076439,35.8936574 C24.2049812,35.9907445 24.3483599,36.0247229 24.478651,35.9815798 L29.3557513,34.6566617 L47.5681617,16.3674742 L48.3376562,17.141777 C48.4461892,17.2506318 48.5071738,17.3983521 48.5071738,17.5523921 C48.5071738,17.706432 48.4461892,17.8541523 48.3376562,17.9630072 L40.678537,25.6544145 C40.1920466,26.1433878 40.1923953,26.9358198 40.6793159,27.4243611 C41.1662364,27.9129024 41.9553419,27.9125522 42.4418323,27.423579 L50.1009515,19.7321716 C51.2996828,18.527671 51.2996828,16.5755489 50.1009515,15.3710482"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { ContractIcon };
