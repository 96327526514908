import { useEffect, useState } from "react";
import { RootState } from "./redux/store";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import Search from "./pages/SearchPage";
import Questions from "./pages/QuestionsAnswersPage";
import NavbarComponent from "./components/NavbarComponent";
import {
  fetchResponseSettings,
  fetchTokenDetails,
  fetchUserDetails,
} from "./data/api/UserDetails/userDetailsSlice";
//import { fetchHistory } from './data/api/History/historySlice';
import { fetchHistory } from "./data/api/Search/searchSlice";
//import { fetchFavorites } from './data/api/Favorite/favoriteSlice';
import { fetchFavorites } from "./data/api/Search/searchSlice";
import SidePanel from "./components/SidePanel";
import SidePanelMobileView from "./components/SidePanelMobileView";
import ModalPopup from "./components/Modal";
import Settings from "./components/Settings";
import { Tab, Tabs } from "react-bootstrap";
import {
  setIsSidePanelActiveMobileView,
  setIsSidePanelActive,
  SidePanelState,
  setSidePanelState,
} from "./data/api/SidePanel/sidePanelSlice";
import { ClosePanel } from "./svg/closePanel";
import { OpenPanel } from "./svg/openPanel";
import "./App.css";
// import AdminSection from "./pages/Admin/Sidebar";
import HRInterview from "./pages/HRInterviewPage";
import ContractExtraction from "./pages/ContractExtractionPage";
import AWSInvoiceReader from "./pages/InvoiceReaderPage";
import AskAnyQuestions from "./pages/AskAnythingPage";
import Feedback from "./components/Feedback";

function App() {
  const dispatch = useAppDispatch();
  const {
    user,
    userDetailsError: requestError,
    token,
    hrRole,
  } = useAppSelector((state: RootState) => state.userDetails);

  const { isSidePanelActive } = useAppSelector(
    (state: RootState) => state.sidePanelDetails
  );

  const sidePanelState = useAppSelector(
    (state) => state.sidePanelDetails.sidePanelState
  );

  // const [activeTab, setActiveTab] = useState("search");

  const isMobileView =
    (window.innerWidth || document.documentElement.clientWidth) < 768;
  const showSidePanel = () => {
    if (isMobileView) {
      dispatch(setIsSidePanelActiveMobileView(true));
    } else {
      dispatch(setIsSidePanelActive(true));
    }
  };

  const hideSidePanel = () => {
    dispatch(setIsSidePanelActive(false));
  };
  useEffect(() => {
    if (!Object.keys(user).length) {
      dispatch(fetchUserDetails());
      dispatch(fetchTokenDetails());
      dispatch(fetchHistory());
      dispatch(fetchFavorites());
      dispatch(fetchResponseSettings());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (requestError?.code === 401) {
      window.open("/api/login", "_self");
    }
  }, [requestError]);

  useEffect(() => {
    if (hrRole?.name === "Talent Acquisition - Global ATS") {
      dispatch(setSidePanelState(SidePanelState.interview));
    }
  }, [hrRole]);

  const onTabSelect = (eventKey: string | null) => {
    let sidePanelState: SidePanelState;
    switch (eventKey) {
      case "search":
        sidePanelState = SidePanelState.search;
        break;
      case "qna":
        sidePanelState = SidePanelState.qa;
        break;
      case "ask":
        sidePanelState = SidePanelState.ask;
        hideSidePanel();
        break;
      case "interview":
        sidePanelState = SidePanelState.interview;
        break;
      case "contract":
        sidePanelState = SidePanelState.contract;
        hideSidePanel();
        break;
      case "invoice":
        sidePanelState = SidePanelState.invoice;
        hideSidePanel();
        break;
      // case "admin":
      //   sidePanelState = SidePanelState.admin;
      //   hideSidePanel();
      //   break;
    }
    dispatch(setSidePanelState(sidePanelState!));
  };

  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const renderToggleIcon = () => {
    if (
      sidePanelState === SidePanelState.ask ||
      sidePanelState === SidePanelState.invoice
    ) {
      return null;
    }
    if (isSidePanelActive) {
      return (
        <span
          className="cursor-pointer"
          onClick={hideSidePanel}
          data-testid="close-panel"
        >
          <ClosePanel />
        </span>
      );
    }
    return (
      <span
        className="cursor-pointer"
        onClick={showSidePanel}
        data-testid="open-panel"
      >
        <OpenPanel />
      </span>
    );
  };
  return user.firstName ? (
    <div className="row homepage" data-testid="main-app">
      <div
        className={`left-panel ${
          isSidePanelActive
            ? // || isSettingOpen
              "col-8"
            : "col-12"
        }`}
      >
        <NavbarComponent
          userDetails={user}
          tokenDetails={token}
          setIsSettingOpen={setIsSettingOpen}
          isSettingOpen={isSettingOpen}
          setIsFeedbackOpen={setIsFeedbackOpen}
          isFeedbackOpen={isFeedbackOpen}
        />
        <div>
          <Tabs
            className="top-nav-tabs"
            defaultActiveKey={
              hrRole?.name === "Talent Acquisition - Global ATS"
                ? "interview"
                : "search"
            }
            onSelect={onTabSelect}
          >
            {hrRole?.name !== "Talent Acquisition - Global ATS" ? (
              <Tab eventKey="search" title="Search">
                <Search />
              </Tab>
            ) : (
              ""
            )}
            {hrRole?.name !== "Talent Acquisition - Global ATS" ? (
              <Tab eventKey="qna" title="Data Set Q&A">
                <Questions />
              </Tab>
            ) : (
              ""
            )}

            {hrRole?.name !== "Talent Acquisition - Global ATS" ? (
              <Tab eventKey="ask" title="Ask Anything">
                <AskAnyQuestions />
              </Tab>
            ) : (
              ""
            )}
            {hrRole?.name === "app-ice-admin-dev" ||
            hrRole?.name === "app-ice-demo" ||
            hrRole?.name === "Talent Acquisition - Global ATS" ? (
              <Tab eventKey="interview" title="HR Interview">
                <HRInterview />
              </Tab>
            ) : (
              ""
            )}
            {hrRole?.name === "app-ice-admin-dev" ||
            hrRole?.name === "app-ice-demo" ? (
              <Tab eventKey="contract" title="Contract Extraction">
                <ContractExtraction />
              </Tab>
            ) : (
              ""
            )}
            {hrRole?.name === "app-ice-admin-dev" ||
            hrRole?.name === "app-ice-demo" ? (
              <Tab eventKey="invoice" title="File Extraction">
                <AWSInvoiceReader />
              </Tab>
            ) : (
              ""
            )}

            {/* <Tab eventKey="admin" title="Admin">
              <AdminSection />
            </Tab> */}
            <Tab title={<>{renderToggleIcon()}</>}></Tab>
          </Tabs>
        </div>

        {requestError?.code === 403 && <ModalPopup show />}
      </div>
      <div
        className={`col-4 border right-panel ${
          isSidePanelActive ? "active" : "in-active"
        }`}
      >
        <SidePanel />
      </div>
      <SidePanelMobileView />
      <Settings
        setIsSettingOpen={setIsSettingOpen}
        isSettingOpen={isSettingOpen}
      />
      <Feedback
        setIsFeedbackOpen={setIsFeedbackOpen}
        isFeedbackOpen={isFeedbackOpen}
      />
    </div>
  ) : (
    <div className="loading-answer">
      <div
        className="mt-5 mx-auto spinner-border loader-color"
        role="status"
        data-testid="app-loader"
      />
    </div>
  );
}

export default App;
