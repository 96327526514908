function CompanyType() {
    return (
        <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>C020F197-109E-431C-BBDF-1F1D8E6D0BEE</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-206, -546)" fill="#F2D540">
                    <rect id="customer-copy" x="206" y="546" width="8" height="8"></rect>
                </g>
            </g>
        </svg>
    );
}

export { CompanyType }