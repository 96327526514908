type DefaultRatingStarProps = {
  id: string;
  className: string
}

function DefaultRatingStar({id, className}: DefaultRatingStarProps) {

  return (
    <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>star outline</title>
      <g id={id} className={className} stroke-width="1" fill-rule="evenodd">
        <g id="Style-Sheet" transform="translate(-271, -89)" fill-rule="nonzero" stroke-width="2">
          <path d="M281.884096,90.0183039 C282.214649,90.0615119 282.263661,90.1030629 282.292182,90.157968 L284.672654,94.7405565 C285.009776,95.3895427 285.656513,95.8493599 286.413818,95.9539095 L291.736714,96.6887618 C291.824585,96.7008928 291.90221,96.7415683 291.952201,96.8052137 C291.986479,96.8488539 292.006688,96.9044784 291.997651,96.963568 C291.989795,97.0149349 291.961671,97.0605944 291.923078,97.0963352 L288.071394,100.663383 C287.505545,101.187417 287.255316,101.943612 287.388437,102.681022 L288.297696,107.717774 C288.308228,107.776116 288.289955,107.831839 288.257104,107.875972 C288.209285,107.940215 288.133398,107.982172 288.046748,107.996291 C287.969376,108.008899 287.889537,107.997877 287.819873,107.963081 L283.05893,105.585049 C282.39594,105.253894 281.603889,105.253894 280.940899,105.585049 L276.179956,107.963081 C276.092453,108.006788 275.994635,108.011648 275.907374,107.985878 C275.83386,107.964167 275.764621,107.920094 275.725194,107.848843 C275.702977,107.808694 275.694011,107.762767 275.702133,107.717774 L276.611392,102.681022 C276.744513,101.943612 276.494285,101.187416 275.928435,100.663383 L272.076752,97.0963352 C272.029741,97.052799 272.000813,96.996641 272.000018,96.9375476 C271.999232,96.8791518 272.026166,96.8228904 272.071481,96.7787234 C272.122787,96.7287164 272.190945,96.6987252 272.263115,96.6887618 L277.586011,95.9539095 C278.343316,95.8493599 278.990053,95.3895428 279.327176,94.7405565 L281.707647,90.157968 C281.74472,90.0866003 281.812059,90.0415129 281.884096,90.0183039 Z" id="star-outline"></path>
        </g>
      </g>
    </svg>
  );
}

export { DefaultRatingStar };
