function ConfidentialType() {
    return (
        <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>75991977-F0C8-4D3D-99CA-A60AECF94FC1</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-313, -546)" fill="#E60013">
                    <rect id="confidential" x="313" y="546" width="8" height="8"></rect>
                </g>
            </g>
        </svg>
    );
}

export { ConfidentialType }