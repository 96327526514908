function StatusRed() {
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>E90E4580-BE9A-4535-9E17-0CC01620E4E5</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-242, -69)" fill="#E60013">
                    <circle id="red-status" cx="250" cy="77" r="8"></circle>
                </g>
            </g>
        </svg>
    );
}

export { StatusRed }