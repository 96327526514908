import { useState } from 'react';
import { ChevronRight } from '@rss-engineering/fabric-icons';
import { SIDE_PANEL_QUESTIONS } from '../../../constants';
import QuestionsDrawer from './QuestionsDrawer';
import '../index.css';

function PromptLibrary() {
  const [show, setShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleShow = (index: number) => {
    setSelectedCategory(index);
    setShow(true);
  };

  return (
    <>
      <div className="p-6">
        Select a category below to view prompts
      </div>
      <div className="align-items-center">
        {SIDE_PANEL_QUESTIONS.map((category, index) => (
          <div
            key={`category_${category.name}`}
            onClick={() => handleShow(index)}
            className="d-flex flex-row  border-top border-bottom p-6 categories"
            data-testid={`category_${index}`}
          >

            <span
              className="category-tile"
              data-testid={`category-tile-${category.name.replace(/ /g, '-').toLowerCase()}`}
            >
              {category.name}
            </span>
            <ChevronRight
              className="ms-auto cursor-pointer categories-open-icon"
              title="categories-open-icon"
              size={18}
              data-testid="close-library"
            />
          </div>
        ))}
      </div>
      <QuestionsDrawer
        selectedCategory={selectedCategory}
        showDrawer={show}
        handleClose={() => setShow(false)}
      />

    </>
  );
}

export default PromptLibrary;
