function PdfIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>0BDD875B-2319-454C-8E62-36F048BE382D</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-75, -621)">
                    <g id="PDF-Copy" transform="translate(75, 621)">
                        <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                            <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                            <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                        </g>
                        <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                        <path d="M6.23626373,15.885989 L6.23626373,18 L5,18 L5,12 L7.34065934,12 C7.79121104,12 8.18749829,12.0824168 8.52953297,12.2472527 C8.87156764,12.4120887 9.13461447,12.6462897 9.31868132,12.9498626 C9.50274817,13.2534356 9.59478022,13.5988992 9.59478022,13.9862637 C9.59478022,14.5741788 9.39354597,15.037773 8.99107143,15.3770604 C8.58859689,15.7163479 8.03159697,15.885989 7.32005494,15.885989 L6.23626373,15.885989 Z M6.23626373,14.8846154 L7.34065934,14.8846154 C7.66758405,14.8846154 7.91689474,14.8076931 8.0885989,14.6538462 C8.26030305,14.4999992 8.34615384,14.2802212 8.34615384,13.9945055 C8.34615384,13.700548 8.25961625,13.462913 8.08653846,13.2815934 C7.91346067,13.1002738 7.67445207,13.0068682 7.36950549,13.0013736 L6.23626373,13.0013736 L6.23626373,14.8846154 Z" id="Shape" fill="#FFFFFF"></path>
                        <path d="M11.0041209,18 L11.0041209,12 L12.8502747,12 C13.3777499,12 13.8495858,12.1188175 14.2657967,12.356456 C14.6820076,12.5940946 15.006867,12.9320033 15.2403846,13.3701923 C15.4739023,13.8083813 15.5906593,14.3063159 15.5906593,14.864011 L15.5906593,15.1401099 C15.5906593,15.697805 15.4759627,16.1936791 15.2465659,16.6277473 C15.0171692,17.0618154 14.6936834,17.3983505 14.2760989,17.6373627 C13.8585144,17.8763748 13.3873653,17.9972527 12.8626374,18 L11.0041209,18 Z M12.2403846,13.0013736 L12.2403846,17.0068681 L12.8379121,17.0068681 C13.321431,17.0068681 13.6909328,16.8489027 13.9464286,16.532967 C14.2019244,16.2170314 14.3324176,15.7651128 14.3379121,15.1771978 L14.3379121,14.8598901 C14.3379121,14.2499969 14.2115397,13.7877763 13.9587912,13.4732143 C13.7060427,13.1586523 13.3365409,13.0013736 12.8502747,13.0013736 L12.2403846,13.0013736 Z" id="Shape" fill="#FFFFFF"></path>
                        <polygon id="Path" fill="#FFFFFF" points="20.6098437 15.5480721 18.2362119 15.5480721 18.2362119 18 17 18 17 12 20.9065743 12 20.9065743 13.0013948 18.2362119 13.0013948 18.2362119 14.5508617 20.6098437 14.5508617"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export { PdfIcon };