import { Offcanvas, Spinner, Alert, Form } from "react-bootstrap";
import "./index.css";
import { useState, useEffect } from "react";
import { RootState } from "../../redux/store";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { updateResponseSettings } from "../../data/api/UserDetails/userDetailsSlice";
import { StatusGreen } from "../../svg/statusGreen";
import { StatusRed } from "../../svg/statusRed";
import { StatusYellow } from "../../svg/statusYellow";
import { SettingsIcon } from "../../svg/settings";
import {
  RESPONSE_GEN_CREATIVITY_LIBERAL,
  RESPONSE_GEN_CREATIVITY_NONE,
  RESPONSE_GEN_CREATIVITY_SPRINKLING,
  RESPONSE_LENGTH_CONCISE,
  RESPONSE_LENGTH_VERBOSE,
  RESPONSE_SPEED_FAST,
  RESPONSE_SPEED_NORMAL,
  EXTERNAL_DATASETS,
  INTERNAL_DATASETS,
} from "../../constants";

type SettingsProps = {
  type?: string;
  setIsSettingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSettingOpen: boolean;
};

function Settings({ type, isSettingOpen, setIsSettingOpen }: SettingsProps) {
  const dispatch = useAppDispatch();
  const {
    response_settings,
    isLoadingResponseSettings,
    isUpdatingResponseSettings,
    updateResponseSettingsError,
    token,
    datasets,
    updatedDatasets,
  } = useAppSelector((state: RootState) => state.userDetails);

  const [initialSettings, setInitialSettings] = useState({
    response_speed: RESPONSE_SPEED_NORMAL,
    response_length: RESPONSE_LENGTH_VERBOSE,
    generative_creativity: RESPONSE_GEN_CREATIVITY_NONE,
  });
  const [responseSpeed, setResponseSpeed] = useState(RESPONSE_SPEED_NORMAL);
  const [responseLength, setResponseLength] = useState(RESPONSE_LENGTH_VERBOSE);
  const [generativeCreativity, setGenerativeCreativity] = useState(
    RESPONSE_GEN_CREATIVITY_NONE
  );
  const [tokenStatusIcon, setTokenStatusIcon] = useState<React.ReactNode>(
    <StatusGreen />
  );
  const [isSettingsChanged, setIsSettingsChanged] = useState(false);
  const [selectedDataSet, setSelectedDataSet] = useState<string | null>();
  const [previousCheckedItems, setPreviousCheckedItems] = useState<string[]>(
    []
  );
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  useEffect(() => {
    const allDatasetsUnselected = Object.values(datasets).every((category) =>
      category.datasets.every((dataset) => !dataset.selected)
    );
    if (allDatasetsUnselected) {
      if (Object.keys(datasets).length !== 0 && type !== "qa")
        setIsSettingOpen(true);
      setSelectedDataSet(datasets ? Object.keys(datasets)[1] : null);
      setCheckedItems(Object.values(datasets)[1]?.datasets?.map((d) => d.name));
    } else {
      const selectedExternalCategory = Object.keys(datasets).find(
        (category) =>
          category === EXTERNAL_DATASETS &&
          datasets[category].datasets.some((dataset) => dataset.selected)
      );

      const selectedInternalCategory = Object.keys(datasets).find(
        (category) =>
          category === INTERNAL_DATASETS &&
          datasets[category].datasets.some((dataset) => dataset.selected)
      );

      if (selectedExternalCategory) {
        setSelectedDataSet(EXTERNAL_DATASETS);
        const selectedDatasets = datasets[EXTERNAL_DATASETS].datasets.filter(
          (dataset) => dataset.selected
        );
        const selectedItems = selectedDatasets.map((dataset) => dataset.name);
        setCheckedItems(selectedItems);
        setPreviousCheckedItems(selectedItems);
      }

      if (selectedInternalCategory) {
        setSelectedDataSet(INTERNAL_DATASETS);
        const selectedDatasets = datasets[INTERNAL_DATASETS].datasets.filter(
          (dataset) => dataset.selected
        );
        const selectedItems = selectedDatasets.map((dataset) => dataset.name);
        setCheckedItems(selectedItems);
        setPreviousCheckedItems(selectedItems);
      }
    }
  }, [datasets]);

  useEffect(() => {
    if (updatedDatasets) {
      let category = null;
      for (const [categoryName, categoryData] of Object.entries(datasets)) {
        if (
          updatedDatasets.some((datasetName) =>
            categoryData.datasets.some(
              (dataset) => dataset.name === datasetName
            )
          )
        ) {
          category = categoryName;
          break;
        }
      }
      setSelectedDataSet(category);
      setCheckedItems(updatedDatasets);
      setPreviousCheckedItems(updatedDatasets);
      setIsSettingOpen(false);
    }
  }, [updatedDatasets]);

  useEffect(() => {
    if (
      response_settings.response_speed &&
      response_settings.response_length &&
      response_settings.generative_creativity
    ) {
      setInitialSettings(response_settings);
      setResponseSpeed(response_settings.response_speed);
      setResponseLength(response_settings.response_length);
      setGenerativeCreativity(response_settings?.generative_creativity);
    } else {
      setResponseSpeed(initialSettings.response_speed);
      setResponseLength(initialSettings.response_length);
      setGenerativeCreativity(initialSettings?.generative_creativity);
    }
  }, [response_settings]);

  useEffect(() => {
    const tokenPercentage = (token.reamaing_quota / token.total_quota) * 100;
    if (tokenPercentage >= 31) {
      setTokenStatusIcon(<StatusGreen />);
    }
    if (tokenPercentage >= 11 && tokenPercentage <= 30) {
      setTokenStatusIcon(<StatusYellow />);
    }
    if (tokenPercentage <= 10) {
      setTokenStatusIcon(<StatusRed />);
    }
  }, [token]);

  useEffect(() => {
    setIsSettingsChanged(
      responseSpeed !== initialSettings.response_speed ||
        responseLength !== initialSettings.response_length ||
        generativeCreativity !== initialSettings.generative_creativity ||
        !arraysEqual(checkedItems, previousCheckedItems)
    );
  }, [
    responseSpeed,
    responseLength,
    generativeCreativity,
    initialSettings,
    checkedItems,
  ]);

  const updatingResponseSettings = (
    responseSpeed: string,
    responseLength: string,
    generativeCreativity: string
  ) => {
    dispatch(
      updateResponseSettings({
        response_speed: responseSpeed,
        response_length: responseLength,
        generative_creativity: generativeCreativity,
        datasets: checkedItems,
      })
    );
  };

  const handleSelectDataSet = (ds: string) => {
    setTimeout(() => {
      setSelectedDataSet(ds);
      if (ds === EXTERNAL_DATASETS)
        setCheckedItems(
          datasets[EXTERNAL_DATASETS].datasets.map((d) => d.name)
        );
      else
        setCheckedItems(
          datasets[INTERNAL_DATASETS].datasets.map((d) => d.name)
        );
    }, 0);
  };

  const handleSelectCheckbox = (name: string, e: any, ds: string) => {
    if (e.target.checked) {
      if (ds !== selectedDataSet) {
        setSelectedDataSet(ds);
        setCheckedItems([name]);
      } else {
        setCheckedItems([...checkedItems, name]);
      }
    } else {
      if (ds !== selectedDataSet) {
        setCheckedItems(checkedItems.filter((item) => item !== name));
      } else if (checkedItems.length === 1) {
        setSelectedDataSet(null);
        setCheckedItems([]);
      } else {
        setCheckedItems(checkedItems.filter((item) => item !== name));
      }
    }
  };

  function arraysEqual(arr1: any[], arr2: any[]) {
    if (arr1.length !== arr2.length) return false;

    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    if (set1.size !== set2.size) return false;

    for (const item of set1) {
      if (!set2.has(item)) return false;
    }

    return true;
  }

  return (
    <Offcanvas
      className="setting-offcanvas"
      show={isSettingOpen}
      placement="end"
      onHide={() => setIsSettingOpen(!isSettingOpen)}
    >
      <Offcanvas.Header>
        <Offcanvas.Title className="setting-title">
          <SettingsIcon />
          &nbsp;Settings
        </Offcanvas.Title>
        <Offcanvas.Title className="offcanvas-title-class">
          {tokenStatusIcon}&nbsp; Quota ($):{" "}
          {token.reamaing_quota
            ? `${token.reamaing_quota} of ${token.total_quota}`
            : ""}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="setting-offcanvas-body">
        {isLoadingResponseSettings && (
          <div className="w-100 d-flex flex-column align-items-center justify-content-center load-settings-container">
            <Spinner animation="border" variant="primary" />
            Loading Settings...
          </div>
        )}

        {!isLoadingResponseSettings && (
          <>
            <div className="welcome-settings-container">
              <div className="datasets-component">
                <div className="welcome-text">Welcome to ICE</div>
                <div>Please select your data sets and response settings.</div>
                <div>(You can return and change these settings anytime)</div>
                <div className="datasets-sub-title">
                  <span>Select Data Sets</span>
                </div>
                {datasets &&
                  Object.keys(datasets)
                    .reverse()
                    .map((ds) => {
                      return (
                        <div key={ds} className="settings-menu">
                          <Form.Check
                            className="mb-3 fw-bold mt-5"
                            checked={selectedDataSet === ds}
                            onChange={() => handleSelectDataSet(ds)}
                            label={ds}
                            name="radiobutton"
                            type="radio"
                            id={`radio-${ds}`}
                          />
                          <div className="settings-checkbox">
                            {ds === EXTERNAL_DATASETS
                              ? datasets[EXTERNAL_DATASETS].datasets.map(
                                  (item, index) => (
                                    <Form.Check
                                      key={index}
                                      checked={checkedItems?.includes(
                                        item.name
                                      )}
                                      onChange={(e) =>
                                        handleSelectCheckbox(item.name, e, ds)
                                      }
                                      label={item.name}
                                      type="checkbox"
                                      id={`checkbox-${item}`}
                                    />
                                  )
                                )
                              : ds === INTERNAL_DATASETS
                              ? datasets[INTERNAL_DATASETS]?.datasets?.map(
                                  (item, index) => (
                                    <Form.Check
                                      key={index}
                                      checked={checkedItems?.includes(
                                        item.name
                                      )}
                                      onChange={(e) =>
                                        handleSelectCheckbox(item.name, e, ds)
                                      }
                                      label={item.name}
                                      type="checkbox"
                                      id={`checkbox-${item}`}
                                    />
                                  )
                                )
                              : null}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="settings-padding">
              <div className="setting-sub-title">
                <span>Response Settings</span>
              </div>
              <div className="setting-offcanvas-body-data">
                <div>
                  <span className="toggle-heading"> Response Speed </span>
                  <div>
                    <span
                      className={`toggle-btn cursor-pointer response-speed-normal ${
                        responseSpeed === RESPONSE_SPEED_NORMAL
                          ? "active"
                          : "inactive"
                      }`}
                      onClick={() => setResponseSpeed(RESPONSE_SPEED_NORMAL)}
                    >
                      Normal
                    </span>
                    <span
                      className={`toggle-btn cursor-pointer response-speed-fast ${
                        responseSpeed === RESPONSE_SPEED_FAST
                          ? "active"
                          : "inactive"
                      }`}
                      onClick={() => setResponseSpeed(RESPONSE_SPEED_FAST)}
                    >
                      Fast
                    </span>
                  </div>
                  <span className="lower-accuracy">(Lower Accuracy)</span>
                  <span className="higher-accuracy">(Higher Accuracy)</span>
                </div>
                <div className="response-length">
                  <span className="toggle-heading"> Response Length </span>
                  <span
                    className={`toggle-btn cursor-pointer response-length-verbose ${
                      responseLength === RESPONSE_LENGTH_VERBOSE
                        ? "active"
                        : "inactive"
                    }`}
                    onClick={() => setResponseLength(RESPONSE_LENGTH_VERBOSE)}
                  >
                    Verbose
                  </span>
                  <span
                    className={`toggle-btn cursor-pointer response-length-concise ${
                      responseLength === RESPONSE_LENGTH_CONCISE
                        ? "active"
                        : "inactive"
                    }`}
                    onClick={() => setResponseLength(RESPONSE_LENGTH_CONCISE)}
                  >
                    Concise
                  </span>
                </div>
                <div className="generative-creativity">
                  <span className="toggle-heading">
                    {" "}
                    Generative Creativity{" "}
                  </span>
                  <span
                    className={`toggle-btn response-speed-none cursor-pointer ${
                      generativeCreativity === RESPONSE_GEN_CREATIVITY_NONE
                        ? "active"
                        : "inactive"
                    }`}
                    onClick={() =>
                      setGenerativeCreativity(RESPONSE_GEN_CREATIVITY_NONE)
                    }
                  >
                    None
                  </span>
                  <span
                    className={`toggle-btn response-speed-sprinkling cursor-pointer ${
                      generativeCreativity ===
                      RESPONSE_GEN_CREATIVITY_SPRINKLING
                        ? "active"
                        : "inactive"
                    }`}
                    onClick={() =>
                      setGenerativeCreativity(
                        RESPONSE_GEN_CREATIVITY_SPRINKLING
                      )
                    }
                  >
                    Sprinkling
                  </span>
                  <span
                    className={`toggle-btn response-speed-liberal cursor-pointer ${
                      generativeCreativity === RESPONSE_GEN_CREATIVITY_LIBERAL
                        ? "active"
                        : "inactive"
                    }`}
                    onClick={() =>
                      setGenerativeCreativity(RESPONSE_GEN_CREATIVITY_LIBERAL)
                    }
                  >
                    Liberal
                  </span>
                </div>
              </div>
            </div>
          </>
        )}

        {!isLoadingResponseSettings && (
          <div className="d-flex align-items-center mt-12 gap-12 settings-padding">
            <div
              className="text-primary cancel-btn"
              data-testid="cancel-button"
              onClick={() => {
                setIsSettingOpen(!isSettingOpen);
              }}
            >
              Close
            </div>
            {isUpdatingResponseSettings ? (
              <button
                className="saving-btn bg-primary text-white border-0 disabled-opacity"
                disabled
              >
                <Spinner animation="border" className="save-spinner" />
                Saving
              </button>
            ) : (
              <button
                data-testid="save-button"
                className={`save-btn bg-primary text-white border-0 ${
                  !isSettingsChanged ? "disabled-opacity" : ""
                }`}
                disabled={!isSettingsChanged}
                onClick={() => {
                  updatingResponseSettings(
                    responseSpeed,
                    responseLength,
                    generativeCreativity
                  );
                }}
              >
                Save
              </button>
            )}
          </div>
        )}

        {updateResponseSettingsError && (
          <Alert variant="danger" dismissible>
            <Alert.Heading>Error</Alert.Heading>
            <p>Error saving settings</p>
          </Alert>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Settings;
