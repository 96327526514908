import { InfoCircleFilled } from '@rss-engineering/fabric-icons';
import Modal from 'react-bootstrap/Modal';
import './index.css';

export interface ModalProps {
  show: boolean;
}

function ModalPopup({
  show,
}: ModalProps) {
  return (
    <Modal
      data-testid="racker-no-perms-modal"
      show={show}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="modal-body">
        <div className="text-center">
          <InfoCircleFilled title="access-icon" size={51} className="m-6" />
        </div>
        <div className="text-left mb-8 mt-7">
          Hello Racker, thank you for your interest in ICE.
        </div>
        <div className="text-left mb-8">
          We are currently beta testing the site with a small select group of users.
          Once testing has completed we’ll be opening up access to everyone.
        </div>
        <div className="text-left mb-8">
          Thank you for your patience!
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalPopup;
