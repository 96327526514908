function RemoveIcon() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>C1829123-6586-4CCC-8BFB-8DE0C8057ED7</title>
        <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="VQ&amp;A---Image---Image-Added" transform="translate(-277, -693)">
                <g id="remove-icon" transform="translate(277, 693)">
                    <circle id="Oval" stroke="#E60013" fill="#FFFFFF" cx="10" cy="10" r="9.5"></circle>
                    <path d="M10.952381,5.23809524 C10.952381,4.71210976 10.5259855,4.28571429 10,4.28571429 C9.47401452,4.28571429 9.04761905,4.71210976 9.04761905,5.23809524 L9.04761905,9.04761905 L5.23809524,9.04761905 C4.71210976,9.04761905 4.28571429,9.47401452 4.28571429,10 C4.28571429,10.5259855 4.71210976,10.952381 5.23809524,10.952381 L9.04761905,10.952381 L9.04761905,14.7619048 C9.04761905,15.2878902 9.47401452,15.7142857 10,15.7142857 C10.5259855,15.7142857 10.952381,15.2878902 10.952381,14.7619048 L10.952381,10.952381 L14.7619048,10.952381 C15.2878902,10.952381 15.7142857,10.5259855 15.7142857,10 C15.7142857,9.47401452 15.2878902,9.04761905 14.7619048,9.04761905 L10.952381,9.04761905 L10.952381,5.23809524 Z" id="Path" fill="#E60013" transform="translate(10, 10) rotate(-315) translate(-10, -10)"></path>
                </g>
            </g>
        </g>
    </svg>
  )
}

export { RemoveIcon };