type DefaultFavouriteProps = {
  className: string
}

function DefaultFavourite({ className }: DefaultFavouriteProps) {
  return (
    <svg width="22px" height="20px" viewBox="0 0 28 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>EDFB146C-BAE7-4089-85EC-9BE02CA06ACC</title>
      <g id='favourite' className={className} stroke-width="1" fill-rule="evenodd">
        <g id="Style-Sheet" transform="translate(-124, -400)" fill-rule="nonzero" stroke-width="2">
          <path d="M143.988129,401.000003 C142.123195,400.998371 140.336218,401.773485 139.030515,403.150404 L138,404.225604 L136.969485,403.150404 C135.66226,401.776051 133.876101,401.002016 132.011871,401.002016 C130.147641,401.002016 128.361482,401.776051 127.054258,403.150404 C124.315247,406.033602 124.315247,410.654403 127.054258,413.5376 L138,425.000003 L148.945742,413.5376 C151.684753,410.654403 151.684753,406.033602 148.945742,403.150404 C147.638841,401.775312 145.852641,401.000535 143.988129,401.000003 L143.988129,401.000003 Z" id="favorite-outline"></path>
        </g>
      </g>
    </svg>
  );
}

export { DefaultFavourite };
