import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "@mdxeditor/editor/style.css";
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";
import logo from "../../svg/fair-logo-red.svg";
import { Rating } from "../Rating";
import { useEffect, useState } from "react";
import "./index.css";
import { Document, Page, pdfjs } from "react-pdf";
import { CloseCircle } from "../../svg/closeCircle";
import { useAppDispatch } from "../../redux/hooks";
import {
  getDownloadContract,
  updateContract,
} from "../../data/api/Contract/contractSlice";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
} from "@mdxeditor/editor";
import { Button, Spinner } from "react-bootstrap";
import ContractConfirmPopup from "../ContractConfirmPopup";
import {
  revertExtractedData,
  getDocumentSource,
} from "../../data/api/Contract/contractSlice";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContractResponse = ({
  // file,
  viewSource,
  setViewSource,
  fileName,
  fileId,
  bucketId,
}: {
  // file?: File;
  viewSource: boolean;
  setViewSource: any;
  fileName: string;
  fileId: string;
  bucketId: string;
}) => {
  const dispatch = useAppDispatch();

  const {
    extracted_data,
    summary,
    isEditing,
    editedResponseSummary,
    editedResponseData,
    isLoading,
    // revertedSummary,
    // revertedExtractedData,
    getSourceFile,
    isDocumentSourceLoading,
  } = useAppSelector((state: RootState) => state.contractDetails);

  const [numPages, setNumPages] = useState<number>(0);
  const [isDownload, setIsDownload] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [editedResponse, setEditedResponse] = useState("");
  const [originalResponse, setOriginalResponse] = useState("");
  const [editedData, setEditedData] = useState("");
  const [originalData, setOriginalData] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    setOriginalResponse(editedResponse);
    setEditedResponse(editedResponse);
    if (editedResponseSummary) setIsEditable(false);
  }, [editedResponseSummary]);

  useEffect(() => {
    setOriginalData(editedData);
    setEditedData(editedData);
    if (editedResponseData) setIsEditable(false);
  }, [editedResponseData]);

  useEffect(() => {
    setOriginalResponse(summary);
    setEditedResponse(summary);
    setIsEditable(false);
  }, [summary]);

  useEffect(() => {
    setOriginalData(extracted_data);
    setEditedData(extracted_data);
    setIsEditable(false);
  }, [extracted_data]);

  // useEffect(() => {
  //   setOriginalData(revertedExtractedData);
  //   setOriginalResponse(revertedSummary);
  // }, [revertedSummary, revertedExtractedData]);

  const downloadContract = (fileType: string) => {
    console.log("fileidddd", fileId);
    setIsDownload(false);
    let fileName = `Contract Details.${fileType}`;
    dispatch(
      getDownloadContract({
        fileType,
        fileName,
        fileId,
      })
    );
  };

  const getDocumentLoadingComponent = () => {
    return (
      <div className="document-loading-container w-100 h-100 d-flex justify-content-center">
        <div className="document-spinner-container d-flex flex-column align-items-center">
          <Spinner animation="border" variant="primary" />
          <div className="loading-txt">
            Getting document source
            <br />
            This may take a few moments
          </div>
        </div>
      </div>
    );
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleSave = () => {
    dispatch(
      updateContract({
        fileId,
        summary: editedResponse,
        extracted_details: editedData,
      })
    );
  };

  const handleCancel = () => {
    setEditedResponse(originalResponse);
    setEditedData(originalData);
    setIsEditable(false);
  };

  const revertToOriginal = () => {
    dispatch(revertExtractedData({ fileId, bucketId }));
    setShowPopup(false);
  };

  const documentSourceViewed = () => {
    setViewSource(true);
    dispatch(getDocumentSource({ fileId, bucketId, fileName }));
  };

  const getLoadingComponent = () => {
    return (
      <div className="extract-data-loading-container w-100 h-100 d-flex justify-content-center">
        <div className="spinner-container d-flex flex-column align-items-center">
          <Spinner animation="border" variant="primary" />
          <div className="loading-txt">
            Loading extracted data
            <br />
            This may take a few moments
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="contract-response-container"
      data-testid="contract-response-container"
    >
      <div className="d-flex gap-12">
        <div className={viewSource ? "width-50per" : "width-100per"}>
          <div className="contract-response-header">
            <div className="contract-response-heading">
              <img
                src={logo}
                alt="FairLogoRound"
                height="42px"
                width="42.07px"
              />
              Contract Summary {!viewSource && "- " + fileName}
            </div>
            <div className="edited-tag">
              {isEditable
                ? "Editing Document"
                : (originalResponse !== summary ||
                    originalData !== extracted_data) &&
                  "Edited"}
            </div>
          </div>
          <div
            className={`
          ${
            isEditable
              ? "response-content-editable"
              : viewSource
              ? "contract-response-content source-view"
              : "contract-response-content"
          }`}
          >
            {isEditable ? (
              <>
                <MDXEditor
                  markdown={originalResponse}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    tablePlugin(),
                  ]}
                  onChange={(value) => setEditedResponse(value)}
                  className="contract-editable-response"
                ></MDXEditor>
                <MDXEditor
                  markdown={originalData}
                  plugins={[
                    headingsPlugin(),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    tablePlugin(),
                  ]}
                  onChange={(value) => setEditedData(value)}
                  className="contract-editable-data"
                ></MDXEditor>
              </>
            ) : (
              <div className={`contract-markdown-container`}>
                {isLoading && getLoadingComponent()}
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {originalResponse}
                </ReactMarkdown>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {originalData}
                </ReactMarkdown>
              </div>
            )}
          </div>
          <div
            className={`contract-response-footer position-relative ${
              isEditable ? "justify-content-end" : "justify-content-between"
            }`}
            data-testid="response-footer"
          >
            {!isEditable && (
              <div className="d-flex gap-10 align-items-center">
                <div
                  className="download-text"
                  onClick={() => setIsDownload(!isDownload)}
                >
                  Download
                </div>
                {isDownload && (
                  <div className="download-options">
                    <div onClick={() => downloadContract("pdf")}>PDF</div>
                    <div onClick={() => downloadContract("csv")}>CSV</div>
                  </div>
                )}
                {!viewSource && (
                  <div className="edit-text" onClick={documentSourceViewed}>
                    View Document Source
                  </div>
                )}
                <div className="edit-text" onClick={handleEdit}>
                  Edit Document
                </div>
                {(originalResponse !== summary ||
                  originalData !== extracted_data) && (
                  <div className="edit-text" onClick={() => setShowPopup(true)}>
                    Revert to Original
                  </div>
                )}
              </div>
            )}
            <div className="cursor-pointer">
              {!isEditable ? (
                <Rating
                  rating={0}
                  isFavourite={false}
                  setFavourite={() => {}}
                  setRating={() => {}}
                  searchResult={{}}
                  type="interview"
                  onFavoriteClick={() => {}}
                />
              ) : (
                <div className="save-btn-container d-flex">
                  <Button
                    className="text-primary cancel-btn"
                    variant="transparent"
                    onClick={handleCancel}
                    data-testid="cancel-button"
                  >
                    Cancel
                  </Button>
                  {isEditing ? (
                    <div className="update-btn d-flex align-items-center justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <Button
                      variant="primary"
                      data-testid="update-button"
                      onClick={handleSave}
                      className="update-btn"
                    >
                      Save
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {viewSource && (
          <div className={viewSource ? "width-50per" : "width-100per"}>
            <div className="source-response-header">
              <div className="source-view-header">
                <div>
                  <div>Document Source</div>
                  <div className="uploaded-file-name">{fileName}</div>
                </div>
                <div
                  onClick={() => setViewSource(false)}
                  className="close-view-source"
                  data-testid="close-view-source-button"
                >
                  <CloseCircle size="24px" />
                </div>
              </div>
            </div>
            <div className="source-response-content source-document-view">
              {isDocumentSourceLoading && getDocumentLoadingComponent()}
              {/* {CONTRACT_RESPONSE.source} */}
              {getSourceFile && (
                <div className="pdf-viewer-container">
                  <div
                    className={`pdf-viewer ${
                      viewSource ? "scrollable-container" : ""
                    }`}
                    data-testid="pdf-page"
                  >
                    <Document
                      file={getSourceFile}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (_, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          renderMode="canvas"
                          scale={1}
                        />
                      ))}
                    </Document>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`source-response-footer position-relative`}
              data-testid="response-footer"
            ></div>
          </div>
        )}
      </div>
      {showPopup && (
        <ContractConfirmPopup
          isOpen={showPopup}
          onClose={() => setShowPopup(false)}
          submit={() => revertToOriginal()}
          title="Revert to Original Document"
          buttonText="Revert"
        >
          <div className="contract-popup-content">
            <p>Are you sure you want to revert this document?</p>
            <p>You will lose any edits you’ve made.</p>
          </div>
        </ContractConfirmPopup>
      )}
    </div>
  );
};

export default ContractResponse;
