function VisualQASplash() {
  return (
    <svg
      width="746px"
      height="379px"
      viewBox="0 0 746 379"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>7922E65A-6749-4F4A-9444-BEB8B883BA14</title>
      <defs>
        <path
          d="M154.118803,378 C161.569031,378 157.515564,355.914223 164.657843,354.813899 C233.094646,344.270666 285.49683,285.113189 285.49683,213.714639 C285.49683,134.864714 221.586188,70.9442202 142.748415,70.9442202 C63.9106423,70.9442202 0,134.864714 0,213.714639 C0,284.419209 51.3884576,343.119512 118.847989,354.492719 C126.619507,355.802943 145.974704,378 154.118803,378 Z"
          id="path-1"
        ></path>
        <filter
          x="-0.2%"
          y="-0.2%"
          width="100.4%"
          height="100.7%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          ></feComposite>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          ></feColorMatrix>
        </filter>
        <path
          d="M614.621973,378 C622.072201,378 618.018734,355.914223 625.161013,354.813899 C693.597816,344.270666 746,285.113189 746,213.714639 C746,134.864714 682.089358,70.9442202 603.251585,70.9442202 C524.413812,70.9442202 460.50317,134.864714 460.50317,213.714639 C460.50317,284.419209 511.891628,343.119512 579.351159,354.492719 C587.122677,355.802943 606.477874,378 614.621973,378 Z"
          id="path-3"
        ></path>
        <filter
          x="-0.2%"
          y="-0.2%"
          width="100.4%"
          height="100.7%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          ></feComposite>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          ></feColorMatrix>
        </filter>
        <path
          d="M384.838089,307.05578 C392.288316,307.05578 388.23485,284.970003 395.377128,283.869679 C463.813931,273.326446 516.216115,214.168969 516.216115,142.770419 C516.216115,63.9204939 452.305473,0 373.4677,0 C294.629928,0 230.719285,63.9204939 230.719285,142.770419 C230.719285,213.474989 282.107743,272.175292 349.567275,283.548499 C357.338793,284.858723 376.693989,307.05578 384.838089,307.05578 Z"
          id="path-5"
        ></path>
        <filter
          x="-0.2%"
          y="-0.2%"
          width="100.4%"
          height="100.7%"
          filterUnits="objectBoundingBox"
          id="filter-6"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          ></feComposite>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.178463382 0"
            type="matrix"
            in="shadowOffsetOuter1"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="ICE-V.2"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Q&amp;A-Splash" transform="translate(-107, -264)">
          <g id="Group-2" transform="translate(107, 264)">
            <g id="Chat-Bubbles">
              <g id="Oval-Copy-2">
                <use
                  fill="black"
                  fill-opacity="1"
                  filter="url(#filter-2)"
                  xlinkHref="#path-1"
                ></use>
                <path
                  stroke="#9E9E9E"
                  stroke-width="2"
                  d="M142.748415,71.9442202 C181.891141,71.9442202 217.328222,87.8124022 242.979626,113.46776 C268.631085,139.123174 284.49683,174.565801 284.49683,213.714639 C284.49683,248.908062 271.675235,281.106432 250.448778,305.891788 C228.914109,331.037033 198.729165,348.553144 164.505581,353.825559 C162.780556,354.091312 161.525315,355.291903 160.642571,357.193734 C159.664899,359.300084 159.149537,362.292263 158.701727,365.370979 L158.594165,366.117132 L158.379891,367.605443 C157.955941,370.502646 157.499494,373.288726 156.546283,375.149519 C156.233579,375.759959 155.871069,376.263326 155.411279,376.5952 C155.0465,376.858497 154.619634,377 154.118803,377 C150.622676,377 145.042453,372.687295 139.239692,367.860099 L138.513322,367.254295 L137.421189,366.339146 C137.299753,366.237205 137.178305,366.135213 137.056861,366.033203 L135.964439,365.115576 L135.600822,364.810456 C129.07168,359.334539 122.731117,354.133276 119.014236,353.506635 C85.2598767,347.815885 55.5580215,330.191832 34.3948512,305.123587 C13.5588244,280.442851 1,248.545579 1,213.714639 C1,174.565801 16.8657444,139.123174 42.517204,113.46776 C68.1686078,87.8124022 103.605689,71.9442202 142.748415,71.9442202 Z"
                  stroke-linejoin="square"
                  fill="#FFFFFF"
                  fill-rule="evenodd"
                ></path>
                <use xlinkHref="#path-1"></use>
              </g>
              <g
                id="Oval-Copy-3"
                transform="translate(603.2516, 224.4721) scale(-1, 1) translate(-603.2516, -224.4721)"
              >
                <use
                  fill="black"
                  fill-opacity="1"
                  filter="url(#filter-4)"
                  xlinkHref="#path-3"
                ></use>
                <path
                  stroke="#9E9E9E"
                  stroke-width="2"
                  d="M603.251585,71.9442202 C642.394311,71.9442202 677.831392,87.8124022 703.482796,113.46776 C729.134256,139.123174 745,174.565801 745,213.714639 C745,248.908062 732.178405,281.106432 710.951948,305.891788 C689.417279,331.037033 659.232335,348.553144 625.008751,353.825559 C623.215204,354.101869 621.928164,355.391594 621.039278,357.43042 C620.023459,359.760389 619.530775,363.08792 619.056405,366.403048 L618.883061,367.605443 C618.459111,370.502646 618.002664,373.288726 617.049453,375.149519 C616.736749,375.759959 616.374239,376.263326 615.914449,376.5952 C615.54967,376.858497 615.122804,377 614.621973,377 C611.125846,377 605.545623,372.687295 599.742862,367.860099 L599.016492,367.254295 L597.924359,366.339146 C597.681487,366.135264 597.438566,365.931176 597.195729,365.727156 L596.467609,365.115576 L596.103992,364.810456 C589.57485,359.334539 583.234287,354.133276 579.517406,353.506635 C545.763047,347.815885 516.061192,330.191832 494.898021,305.123587 C474.061995,280.442851 461.50317,248.545579 461.50317,213.714639 C461.50317,174.565801 477.368915,139.123174 503.020374,113.46776 C528.671778,87.8124022 564.108859,71.9442202 603.251585,71.9442202 Z"
                  stroke-linejoin="square"
                  fill="#FFFFFF"
                  fill-rule="evenodd"
                ></path>
                <use xlinkHref="#path-3"></use>
              </g>
              <g
                id="Oval-Copy"
                transform="translate(373.4677, 153.5279) scale(-1, 1) translate(-373.4677, -153.5279)"
              >
                <use
                  fill="black"
                  fill-opacity="1"
                  filter="url(#filter-6)"
                  xlinkHref="#path-5"
                ></use>
                <path
                  stroke="#9E9E9E"
                  stroke-width="2"
                  d="M373.4677,1 C412.610427,1 448.047508,16.868182 473.698911,42.5235399 C499.350371,68.1789535 515.216115,103.621581 515.216115,142.770419 C515.216115,177.963842 502.39452,210.162212 481.168064,234.947568 C459.633395,260.092812 429.448451,277.608923 395.224867,282.881338 C393.43132,283.157648 392.144279,284.447374 391.255393,286.4862 C390.239575,288.816169 389.74689,292.1437 389.272371,295.459871 C388.813314,298.667979 388.376888,301.866909 387.369381,303.994458 C387.047208,304.67479 386.671119,305.237842 386.186143,305.609625 C385.809135,305.898639 385.364611,306.05578 384.838089,306.05578 C381.321863,306.05578 375.695841,301.672492 369.856355,296.82238 L369.140378,296.226421 C368.662521,295.827916 368.183757,295.427364 367.705118,295.026898 L366.815385,294.281576 L366.320108,293.866236 C366.068987,293.655623 365.818145,293.445418 365.567721,293.235863 L364.817839,292.609395 C358.831019,287.617193 353.164489,283.140852 349.733522,282.562415 C315.979162,276.871665 286.277307,259.247611 265.114137,234.179367 C244.27811,209.498631 231.719285,177.601359 231.719285,142.770419 C231.719285,103.621581 247.58503,68.1789535 273.236489,42.5235399 C298.887893,16.868182 334.324974,1 373.4677,1 Z"
                  stroke-linejoin="square"
                  fill="#FFFFFF"
                  fill-rule="evenodd"
                ></path>
                <use xlinkHref="#path-5"></use>
              </g>
            </g>
            <text
              id="What's-the-out-of-po"
              font-family="OpenSans-Regular, Open Sans"
              font-size="20"
              font-weight="normal"
              fill="#9E9E9E"
            >
              <tspan x="260.947001" y="125.9300788">
                Tell me about the image{" "}
              </tspan>
              <tspan x="300.006571" y="152.930079">
                I just uploaded{" "}
              </tspan>
            </text>
            <text
              id="What-are-AI-governan"
              font-family="OpenSans-Regular, Open Sans"
              font-size="20"
              font-weight="normal"
              fill="#9E9E9E"
            >
              <tspan x="53.2476446" y="210.860158">
                Help me generate{" "}
              </tspan>
              <tspan x="85.3550665" y="240.860158">
                some code{" "}
              </tspan>
            </text>
            <text
              id="Tell-me-about-a-docu"
              font-family="OpenSans-Regular, Open Sans"
              font-size="20"
              font-weight="normal"
              fill="#9E9E9E"
            >
              <tspan x="515.585636" y="210.860158">
                Give me information{" "}
              </tspan>
              <tspan x="520.74091" y="237.860158">
                about my uploaded
              </tspan>
              <tspan x="555.74091" y="265.860158">
                document
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { VisualQASplash };
