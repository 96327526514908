function HtmlIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>B6EB8413-C351-426D-AFC8-4DDA29FB6EDB</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-44, -621)">
                    <g id="HTML" transform="translate(44, 621)">
                        <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                            <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                            <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                        </g>
                        <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                        <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="6.2 18 5.06073633 18 5.06073633 15.4285714 2.83919832 15.4285714 2.83919832 18 1.7 18 1.7 12 2.83919832 12 2.83919832 14.431361 5.06073633 14.431361 5.06073633 12 6.2 12"></polygon>
                        <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="11.9289386 13.0013948 10.2366962 13.0013948 10.2366962 18 9.09839143 18 9.09839143 13.0013948 7.42893864 13.0013948 7.42893864 12 11.9289386 12"></polygon>
                        <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="14.7733001 12 16.3144693 16.3516702 17.8474118 12 19.4710614 12 19.4710614 18 18.2306652 18 18.2306652 16.3598969 18.3542793 13.5289 16.734814 18 15.8858979 18 14.270546 13.5330134 14.3941601 16.3598969 14.3941601 18 13.1578773 18 13.1578773 12"></polygon>
                        <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="21.9362828 17.0069029 24.5612562 17.0069029 24.5612562 18 20.7 18 20.7 12 21.9362828 12"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { HtmlIcon };