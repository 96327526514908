import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  RawRequestError,
  fetchGetApi,
  fetchPostApi,
  fetchPutApi,
  fetchUploadApi,
} from "../../../api/fetch";
import { CHAT, VISUAL_CHAT } from "../../../constants";
import { serializeError } from "serialize-error";
import { RootState } from "../../../redux/store";

export type VisualQAChatParams = {
  query: string;
  data_set: string;
  session_id?: string;
};

export type VisualQAChatFileParams = {
  query: string;
  file: File | undefined;
  session_id?: string;
};

type VisualQAReqest = {
  query: string;
  session_id?: string;
  response_config: any;
  dataset_type: string;
};

export type VisualQAFileInput = {
  name: string;
  url: string;
};

export type VisualQAChatResponse = {
  session_id?: string;
  question_id?: string;
  query?: string;
  answer?: string;
  file?: VisualQAFileInput;
  rating: string | null;
  date_time: string;
};

type VisualQAHistoryResult = {
  session_id: string;
  query: string;
  date_time: string;
};

export type VisualQAHistoryResponse = {
  result: VisualQAHistoryResult[];
};

type VisualQAFavoritesResponse = VisualQAHistoryResponse;

type ChatQuestionData = {
  question_id: string;
  query: string;
  answer: string;
  date_time: string;
  rating: string | null;
};

type GetChatBySessionIdResponse = {
  session_id: string;
  question_data: ChatQuestionData[];
  favorite: boolean;
};

type InitialState = {
  isLoading: boolean;
  isFavorite: boolean;
  isLoadingFavorite: boolean;
  ratingResults: Array<{
    questionId: string;
    isLoading: boolean;
    error: RawRequestError | null;
  }>;
  chatResults: VisualQAChatResponse[];
  historyResults: VisualQAHistoryResponse;
  favoritesResults: VisualQAFavoritesResponse;
  dataSets: string[];
  isLoadingDataSets: boolean;
  isLoadingFavorites: boolean;
  isLoadingHistory: boolean;
  answerError: RawRequestError | null;
  favoriteError: RawRequestError | null;
  getChatBySessionIdError: RawRequestError | null;
  getDataSetsError: RawRequestError | null;
};

type VisualQAFavoriteParams = {
  sessionId: string;
  favorite: boolean;
};

type VisualQARatingParams = {
  sessionId: string;
  questionId: string;
  rating: number;
};

const initialState: InitialState = {
  isLoading: false,
  isFavorite: false,
  isLoadingFavorite: false,
  ratingResults: [],
  chatResults: [],
  historyResults: { result: [] },
  favoritesResults: { result: [] },
  dataSets: [],
  isLoadingDataSets: false,
  isLoadingFavorites: false,
  isLoadingHistory: false,
  answerError: null,
  favoriteError: null,
  getChatBySessionIdError: null,
  getDataSetsError: null,
};

const updateVisualQAFavorite = createAsyncThunk(
  "ask/updateVisualQAFavorite",
  async (params: VisualQAFavoriteParams, thunkAPI) => {
    const { favorite, sessionId } = params;

    const favoriteEndpoint = `chat/${sessionId}/favorite?favorite=${favorite}`;
    return fetchPutApi({}, favoriteEndpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getVisualQAHistory = createAsyncThunk(
  "ask/getVisualQAHistory",
  async (_: void, thunkAPI) => {
    console.log("get ask history");
    const endpoint = `chat/history`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getVisualQAFavorites = createAsyncThunk(
  "ask/getVisualQAFavorites",
  async (_: void, thunkAPI) => {
    const endpoint = `chat/favorite`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const getChatBySessionId = createAsyncThunk(
  "ask/getChatBySessionId",
  async (params: { sessionId: string }, thunkAPI) => {
    const endpoint = `chat/${params.sessionId}`;

    return fetchGetApi(endpoint)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const updateVisualQARating = createAsyncThunk(
  "ask/updateVisualQARating",
  async (params: VisualQARatingParams, thunkAPI) => {
    const { questionId, sessionId, rating } = params;

    console.log("update ask rating " + rating);
    thunkAPI.dispatch(
      setRatingResult({ questionId, isLoading: true, error: null })
    );
    const favoriteEndpoint = `chat/${sessionId}/question/${questionId}/rating?rating=${rating}`;
    return fetchPutApi({}, favoriteEndpoint)
      .then((response) => thunkAPI.fulfillWithValue({ questionId, response }))
      .catch((e: Error) =>
        thunkAPI.rejectWithValue({ questionId, error: serializeError(e) })
      );
  }
);

// Generates pending, fulfilled and rejected action types
const getChat = createAsyncThunk(
  "ask/getChat",
  async (params: VisualQAChatParams, thunkAPI) => {
    const reqData: VisualQAReqest = {
      query: params.query,
      response_config: {
        creativity: "liberal",
        length: "verbose",
        speed: "normal",
      },
      dataset_type: params.data_set,
      session_id: params.session_id ?? "",
    };
    return fetchPostApi(reqData, CHAT)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const postChatDocument = createAsyncThunk(
  "ask/postChatDocument",
  async (params: VisualQAChatFileParams, thunkAPI) => {
    let endpoint = `${VISUAL_CHAT}/document?query=${encodeURIComponent(
      params.query
    )}`;
    if (params.session_id) {
      endpoint = `${endpoint}&session_id=${params.session_id}`;
    }

    const { file } = params;
    const formData = new FormData();
    formData.append("file_data", file || "");

    return fetchUploadApi(formData, endpoint)
      .then((res: any) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const postChatImage = createAsyncThunk(
  "ask/postImage",
  async (params: VisualQAChatFileParams, thunkAPI) => {
    let endpoint = `${VISUAL_CHAT}/image?query=${encodeURIComponent(
      params.query
    )}`;
    if (params.session_id) {
      endpoint = `${endpoint}&session_id=${params.session_id}`;
    }

    const { file } = params;
    const formData = new FormData();
    formData.append("file_data", file || "");

    return fetchUploadApi(formData, endpoint)
      .then((res: any) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const visualQASlice = createSlice({
  name: "ask",
  initialState,
  reducers: {
    setChat: (state, action) => {
      state.chatResults = [...state.chatResults, action.payload];
    },
    clearChat: (state) => {
      return {
        ...initialState,
        dataSets: state.dataSets,
        favoritesResults: state.favoritesResults,
        historyResults: state.historyResults,
      };
    },
    setVisualQAFavorite: (state, action) => {
      state.isFavorite = action.payload;
    },
    setRatingResult: (state, action) => {
      const filteredRatings = state.ratingResults.filter(
        (rating) => rating.questionId !== action.payload.questionId
      );
      state.ratingResults = [...filteredRatings, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChat.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getChat.fulfilled, (state, action) => {
      return handleChatResponse(state, action);
    });
    builder.addCase(getChat.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      answerError: action.payload as RawRequestError,
    }));
    builder.addCase(updateVisualQAFavorite.pending, (state) => ({
      ...state,
      isLoadingFavorite: true,
    }));
    builder.addCase(updateVisualQAFavorite.fulfilled, (state) => {
      let favoritesResults = state.favoritesResults;
      if (state.chatResults && state.chatResults.length > 0) {
        const firstChat = state.chatResults[0];
        const existsInFavorites = state.favoritesResults.result.find(
          (favorite) => favorite.session_id === firstChat.session_id
        );
        if (!existsInFavorites && state.isFavorite) {
          const favorite = {
            session_id: firstChat.session_id!,
            query: firstChat.query!,
            date_time: firstChat.date_time!,
          };
          favoritesResults = {
            result: [...state.favoritesResults.result, favorite],
          };
        } else if (existsInFavorites && !state.isFavorite) {
          const filteredFavorites = state.favoritesResults.result.filter(
            (favorite) => favorite.session_id !== firstChat.session_id
          );
          favoritesResults = { result: [...filteredFavorites] };
        }
      }
      return {
        ...state,
        favoritesResults,
        isLoadingFavorite: false,
      };
    });
    builder.addCase(updateVisualQAFavorite.rejected, (state, action) => {
      return {
        ...state,
        isFavorite: !state.isFavorite,
        isLoadingFavorite: false,
        favoriteError: action.payload as RawRequestError,
      };
    });
    builder.addCase(updateVisualQARating.fulfilled, (state, action) => {
      const questionId = action.payload.questionId;
      state.ratingResults = state.ratingResults.map((ratingResult) => {
        if (ratingResult.questionId === questionId) {
          return {
            ...ratingResult,
            isLoading: false,
            error: null,
          };
        }
        return ratingResult;
      });
    });
    builder.addCase(updateVisualQARating.rejected, (state, action) => {
      const questionId = (action.payload as any).questionId;
      state.ratingResults = state.ratingResults.map((ratingResult) => {
        if (ratingResult.questionId === questionId) {
          return {
            ...ratingResult,
            isLoading: false,
            error: (action.payload as any).error,
          };
        }
        return ratingResult;
      });
    });
    builder.addCase(getVisualQAHistory.fulfilled, (state, action) => {
      const { data } = action.payload as any;
      return {
        ...state,
        historyResults: data,
        isLoadingHistory: false,
      } as any;
    });
    builder.addCase(getVisualQAFavorites.fulfilled, (state, action) => {
      const { data } = action.payload as any;
      return {
        ...state,
        favoritesResults: data,
        isLoadingFavorites: false,
      } as any;
    });
    builder.addCase(getVisualQAHistory.pending, (state) => {
      return {
        ...state,
        isLoadingHistory: true,
      };
    });
    builder.addCase(getVisualQAFavorites.pending, (state) => {
      return {
        ...state,
        isLoadingFavorites: true,
      };
    });
    builder.addCase(getVisualQAHistory.rejected, (state) => {
      return {
        ...state,
        isLoadingHistory: false,
      };
    });
    builder.addCase(getVisualQAFavorites.rejected, (state) => {
      return {
        ...state,
        isLoadingFavorites: false,
      };
    });
    builder.addCase(getChatBySessionId.fulfilled, (state, action) => {
      const response = action.payload.data as GetChatBySessionIdResponse;
      const mappedResults: VisualQAChatResponse[] = response.question_data.map(
        (data: ChatQuestionData) => {
          return {
            session_id: response.session_id,
            question_id: data.question_id,
            query: data.query,
            answer: data.answer,
            rating: data.rating,
            date_time: data.date_time,
          };
        }
      );
      return {
        ...state,
        chatResults: mappedResults,
        isFavorite: response.favorite,
        getChatBySessionIdError: null,
      } as any;
    });
    builder.addCase(getChatBySessionId.rejected, (state, action) => {
      return {
        ...state,
        getChatBySessionIdError: action.payload as RawRequestError,
      };
    });
    builder.addCase(postChatDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postChatDocument.fulfilled, (state, action) => {
      return handleChatResponse(state, action);
    });
    builder.addCase(postChatDocument.rejected, (state, action) => {
      state.isLoading = false;
      state.answerError = action.payload as RawRequestError;
    });
    builder.addCase(postChatImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postChatImage.fulfilled, (state, action) => {
      return handleChatResponse(state, action);
    });
    builder.addCase(postChatImage.rejected, (state, action) => {
      state.isLoading = false;
      state.answerError = action.payload as RawRequestError;
    });
  },
});

const { setChat, clearChat, setVisualQAFavorite, setRatingResult } =
  visualQASlice.actions;
export default visualQASlice.reducer;
export {
  setChat,
  clearChat,
  setVisualQAFavorite,
  getChat,
  updateVisualQAFavorite,
  updateVisualQARating,
  setRatingResult,
  getVisualQAFavorites,
  getVisualQAHistory,
  getChatBySessionId,
  postChatDocument,
  postChatImage,
};

function handleChatResponse(state: InitialState, action: any) {
  const { data } = action.payload as any;
  let historyResults = state.historyResults;
  const existsInHistory = historyResults.result.find(
    (history) => history.session_id === data.session_id
  );
  if (!existsInHistory) {
    const history = {
      session_id: data.session_id,
      query: data.query,
      date_time: data.date_time,
    };
    historyResults = { result: [...state.historyResults.result, history] };
  }
  // Add the file to the respone so that we can display it in the question bubble
  if (state.chatResults && state.chatResults.length > 0) {
    const lastElement = state.chatResults[state.chatResults.length - 1];
    data.file = lastElement.file;
  }
  // Remove the last element and replace it with the new response that contains an answer
  const filteredChatResults = state.chatResults.filter(
    (_, index) => index !== state.chatResults.length - 1
  );
  return {
    ...state,
    isLoading: false,
    chatResults: [...filteredChatResults, data],
    historyResults,
    answerError: null,
  } as any;
}

export const selectChatSessionId = (state: RootState) => {
  return state.visualQADetails.chatResults.find((result) => !!result.session_id)
    ?.session_id;
};
