function CustomerType() {
    return (
        <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>1AB83F40-7CF6-47E7-8099-D49FA5111AD1</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-120, -546)" fill="#367ED4">
                    <rect id="customer" x="120" y="546" width="8" height="8"></rect>
                </g>
            </g>
        </svg>
    );
}

export { CustomerType }