import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serializeError } from "serialize-error";
import { RawRequestError, fetchGetApi, fetchPutApi } from "../../../api/fetch";
import {
  UserDetails,
  Token,
  Role,
  ResponseSettings,
  DatasetSettings,
  RequestSettings,
} from "../../../types";
import {
  USER_DATA_URL,
  TOKEN_URL,
  RESPONSE_SETTINGS_URL,
  UPDATE_RESPONSE_SETTINGS_URL,
} from "../../../constants";
import { RootState } from "../../../redux/store";

type InitialState = {
  isLoading: boolean;
  isLoadingResponseSettings: boolean;
  isUpdatingResponseSettings: boolean;
  user: UserDetails;
  error: RawRequestError | null;
  updateResponseSettingsError: RawRequestError | null;
  token: Token;
  hrRole: Role;
  response_settings: ResponseSettings;
  datasets: DatasetSettings;
  updatedDatasets: string[];
  userDetailsError: RawRequestError | null;
};

const initialState: InitialState = {
  isLoading: false,
  isLoadingResponseSettings: false,
  isUpdatingResponseSettings: false,
  user: {} as UserDetails,
  error: null,
  updateResponseSettingsError: null,
  token: {} as Token,
  hrRole: { name: "" } as Role,
  response_settings: {} as ResponseSettings,
  datasets: {} as DatasetSettings,
  updatedDatasets: [],
  userDetailsError: null,
};

// fetch user details
const fetchUserDetails = createAsyncThunk(
  "login/fetchUserDetails",
  async (_: void, thunkAPI) =>
    fetchGetApi(USER_DATA_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);

const fetchTokenDetails = createAsyncThunk(
  "login/fetchTokenDetails",
  async (_: void, thunkAPI) =>
    fetchGetApi(TOKEN_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);

const fetchResponseSettings = createAsyncThunk(
  "login/fetchResponseSettings",
  async (_: void, thunkAPI) =>
    fetchGetApi(RESPONSE_SETTINGS_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);

const updateResponseSettings = createAsyncThunk(
  "login/updateResponseSettings",
  async (params: RequestSettings, thunkAPI) =>
    fetchPutApi(params, UPDATE_RESPONSE_SETTINGS_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);
const searchSlice = createSlice({
  name: "login/fetchUserDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      const { user } = action.payload;
      const roles = action.payload.roles;
      const hrRole = roles.find((obj: Role) => {
        return (
          obj.name === "app-ice-admin-dev" ||
          obj.name === "app-ice-demo" ||
          obj.name === "Talent Acquisition - Global ATS"
        );
      }) || { name: "" };
      return {
        ...state,
        isLoading: false,
        user: {
          firstName: user.firstname,
          lastName: user.lastname,
        },
        hrRole: hrRole,
        userDetailsError: null,
      };
    });
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { user } = action.payload;
      return {
        ...state,
        isLoading: false,
        user: {
          firstName: user?.firstname,
          lastName: user?.lastname,
        },
        userDetailsError: action.payload as RawRequestError,
      };
    });
    builder.addCase(fetchTokenDetails.pending, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(fetchTokenDetails.fulfilled, (state, action) => {
      const token = action.payload.data;
      return {
        ...state,
        isLoading: false,
        token: {
          consumed_quota: token.consumed_quota,
          reamaing_quota: token.reamaing_quota,
          tier_value: token.tier_value,
          total_quota: token.total_quota,
        },
        error: null,
      };
    });
    builder.addCase(fetchTokenDetails.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const token = action.payload.data;
      return {
        ...state,
        isLoading: false,
        token: {
          consumed_quota: token?.consumed_quota,
          reamaing_quota: token?.reamaing_quota,
          tier_value: token?.tier_value,
          total_quota: token?.total_quota,
        },
        error: action.payload as RawRequestError,
      };
    });
    builder.addCase(fetchResponseSettings.pending, (state) => ({
      ...state,
      isLoading: true,
      isLoadingResponseSettings: true,
    }));
    builder.addCase(fetchResponseSettings.fulfilled, (state, action) => {
      const response_settings = action.payload.data.response_setting;
      return {
        ...state,
        isLoading: false,
        isLoadingResponseSettings: false,
        response_settings: {
          generative_creativity: response_settings.generative_creativity,
          response_length: response_settings.response_length,
          response_speed: response_settings.response_speed,
        },
        datasets: action.payload.data.datasets,
        error: null,
      };
    });
    builder.addCase(fetchResponseSettings.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response_settings = action.payload.data;
      return {
        ...state,
        isLoading: false,
        isLoadingResponseSettings: false,
        response_settings: {
          generative_creativity: response_settings?.generative_creativity,
          response_length: response_settings?.response_length,
          response_speed: response_settings?.response_speed,
        },
        error: action.payload as RawRequestError,
      };
    });
    builder.addCase(updateResponseSettings.pending, (state) => ({
      ...state,
      isLoading: true,
      isUpdatingResponseSettings: true,
      updateResponseSettingsError: null,
    }));
    builder.addCase(updateResponseSettings.fulfilled, (state, action) => {
      const response_settings = action.payload.data;
      return {
        ...state,
        isLoading: false,
        isUpdatingResponseSettings: false,
        response_settings: {
          generative_creativity: response_settings.generative_creativity,
          response_length: response_settings.response_length,
          response_speed: response_settings.response_speed,
        },
        updatedDatasets: action.payload.data.datasets,
        error: null,
        updateResponseSettingsError: null,
      };
    });
    builder.addCase(updateResponseSettings.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response_settings = action.payload.data;
      return {
        ...state,
        isLoading: false,
        isUpdatingResponseSettings: false,
        response_settings: {
          generative_creativity: response_settings?.generative_creativity,
          response_length: response_settings?.response_length,
          response_speed: response_settings?.response_speed,
        },
        updatedDatasets: [],
        error: action.payload as RawRequestError,
        updateResponseSettingsError: action.payload as RawRequestError,
      };
    });
  },
});

export default searchSlice.reducer;
export {
  fetchUserDetails,
  initialState,
  fetchTokenDetails,
  fetchResponseSettings,
  updateResponseSettings,
};

export const selectRackerInitials = (state: RootState) => {
  const { firstName, lastName } = state.userDetails.user || {};
  if (firstName && lastName) {
    return (firstName[0] + lastName[0]).toUpperCase();
  }
  return "";
};
