function PptxIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>4EEFC964-A8DC-4102-A579-CA2684641789</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-163, -621)">
                    <g id="pptx" transform="translate(163, 621)">
                        <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                            <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                            <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                        </g>
                        <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                        <polygon id="Path-Copy" fill="#FFFFFF" points="21.5919955 14.0686745 22.7170043 12 24.1386728 12 22.3914801 14.9753197 24.1840618 18 22.7458689 18 21.5919955 15.8983476 20.438193 18 19 18 20.7925817 14.9753197 19.0453181 12 20.4669866 12"></polygon>
                        <polygon id="Path" fill="#FFFFFF" points="18.0256306 13.0013948 16.1877308 13.0013948 16.1877308 18 14.9514479 18 14.9514479 13.0013948 13.1382993 13.0013948 13.1382993 12 18.0256306 12"></polygon>
                        <path d="M3.23626373,15.885989 L3.23626373,18 L2,18 L2,12 L4.34065934,12 C4.79121104,12 5.18749829,12.0824168 5.52953297,12.2472527 C5.87156764,12.4120887 6.13461447,12.6462897 6.31868132,12.9498626 C6.50274817,13.2534356 6.59478022,13.5988992 6.59478022,13.9862637 C6.59478022,14.5741788 6.39354597,15.037773 5.99107143,15.3770604 C5.58859689,15.7163479 5.03159697,15.885989 4.32005494,15.885989 L3.23626373,15.885989 Z M3.23626373,14.8846154 L4.34065934,14.8846154 C4.66758405,14.8846154 4.91689474,14.8076931 5.0885989,14.6538462 C5.26030305,14.4999992 5.34615384,14.2802212 5.34615384,13.9945055 C5.34615384,13.700548 5.25961625,13.462913 5.08653846,13.2815934 C4.91346067,13.1002738 4.67445207,13.0068682 4.36950549,13.0013736 L3.23626373,13.0013736 L3.23626373,14.8846154 Z" id="Shape" fill="#FFFFFF"></path>
                        <path d="M8.80541339,15.885989 L8.80541339,18 L7.56914965,18 L7.56914965,12 L9.909809,12 C10.3603607,12 10.7566479,12.0824168 11.0986826,12.2472527 C11.4407173,12.4120887 11.7037641,12.6462897 11.887831,12.9498626 C12.0718978,13.2534356 12.1639299,13.5988992 12.1639299,13.9862637 C12.1639299,14.5741788 11.9626956,15.037773 11.5602211,15.3770604 C11.1577465,15.7163479 10.6007466,15.885989 9.8892046,15.885989 L8.80541339,15.885989 Z M8.80541339,14.8846154 L9.909809,14.8846154 C10.2367337,14.8846154 10.4860444,14.8076931 10.6577486,14.6538462 C10.8294527,14.4999992 10.9153035,14.2802212 10.9153035,13.9945055 C10.9153035,13.700548 10.8287659,13.462913 10.6556881,13.2815934 C10.4826103,13.1002738 10.2436017,13.0068682 9.93865514,13.0013736 L8.80541339,13.0013736 L8.80541339,14.8846154 Z" id="Shape-Copy" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { PptxIcon };