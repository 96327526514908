function UploadIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>DD9559AF-00E3-4180-9D60-09594EF8449E</title>
      <g
        id="Contract-Extraction"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Contract-Extraction-Landing"
          transform="translate(-516, -195)"
          fill="#9E9E9E"
          fill-rule="nonzero"
        >
          <g id="Group" transform="translate(516, 190)">
            <g id="upload-icon" transform="translate(0, 5)">
              <path
                d="M23.1428571,12.0000029 C23.5636455,12.0000029 23.9136144,12.3032174 23.9861902,12.7030731 L24,12.8571455 L24,21.428572 C24,22.7651936 22.9801918,23.8636264 21.6762171,23.9882287 L21.4285714,24.0000029 L2.57142857,24.0000029 C1.23480665,24.0000029 0.136373691,22.9801921 0.01177128,21.6762177 L0,21.428572 L0,12.8571455 C0,12.3837587 0.383755937,12.0000029 0.857142857,12.0000029 C1.27793124,12.0000029 1.62790006,12.3032174 1.70047599,12.7030731 L1.71428571,12.8571455 L1.71428571,21.428572 C1.71428571,21.8493603 2.01750027,22.1993291 2.41735606,22.2719049 L2.57142857,22.2857147 L21.4285714,22.2857147 C21.8493598,22.2857147 22.1993287,21.9825001 22.2719045,21.5826445 L22.2857143,21.428572 L22.2857143,12.8571455 C22.2857143,12.3837587 22.6694702,12.0000029 23.1428571,12.0000029 Z M11.4256555,0.207590428 C11.8144958,-0.086725634 12.3699182,-0.0703928922 12.7416662,0.271329013 C12.7747558,0.30266008 12.7747558,0.30266008 12.8059383,0.335889546 L18.9266556,7.05454852 L19.0202066,7.17774459 C19.2358371,7.52220838 19.1813261,7.98204064 18.8702642,8.26541889 C18.5203193,8.58421942 17.9781941,8.55897208 17.6593935,8.20902739 L12.8571429,2.93657071 L12.8571429,19.8857153 L12.8433331,20.0397877 C12.7707572,20.4396434 12.4207884,20.7428579 12,20.7428579 C11.5266131,20.7428579 11.1428571,20.3591021 11.1428571,19.8857153 L11.1428571,3.02228498 L6.37389717,8.21185882 L6.25946177,8.31594332 C5.93542819,8.5612067 5.47257615,8.54759608 5.16278614,8.26282782 C4.81427235,7.94246352 4.79145276,7.40023077 5.11181712,7.05171709 L11.288333,0.332491815 L11.4256555,0.207590428 Z"
                id="upload"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { UploadIcon };
