function AddFile({ fillColor = "#000000" }: { fillColor?: string }) {
  return (
    <svg
      width="22px"
      height="24px"
      viewBox="0 0 22 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>ED5E6317-0400-4C20-B995-DBF5E0BB8715</title>
      <g
        id="ICE-V.2"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="VQ&amp;A---Image---Tooltip"
          transform="translate(-163, -774)"
          fill={fillColor}
          fill-rule="nonzero"
        >
          <path
            d="M166.473684,793.428571 L172.263158,787.714286 L174.44,789.862857 C173.803158,790.902857 173.421053,792.125714 173.421053,793.428571 L166.473684,793.428571 Z M169.947368,783.714286 C169.947368,782.765714 169.171579,782 168.210526,782 C167.249474,782 166.473684,782.765714 166.473684,783.714286 C166.473684,784.662857 167.249474,785.428571 168.210526,785.428571 C169.171579,785.428571 169.947368,784.662857 169.947368,783.714286 M173.525263,794.571429 L165.315789,794.571429 L165.315789,776.285714 L173.421053,776.285714 L173.421053,782 L179.210526,782 L179.210526,786.674286 C179.592632,786.617143 179.974737,786.571429 180.368421,786.571429 C180.762105,786.571429 181.144211,786.617143 181.526316,786.674286 L181.526316,780.857143 L174.578947,774 L165.315789,774 C164.036814,774 163,775.023349 163,776.285714 L163,794.571429 C163,795.84 164.030526,796.857143 165.315789,796.857143 L174.358947,796.857143 C173.953684,796.16 173.664211,795.394286 173.525263,794.571429 M179.210526,788.857143 L179.210526,792.285714 L175.736842,792.285714 L175.736842,794.571429 L179.210526,794.571429 L179.210526,798 L181.526316,798 L181.526316,794.571429 L185,794.571429 L185,792.285714 L181.526316,792.285714 L181.526316,788.857143 L179.210526,788.857143 Z"
            id="add-image-icon"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export { AddFile };
