function PublicType() {
    return (
        <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>066C828E-2866-4360-B343-C63DB291A783</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-44, -546)" fill="#00A800">
                    <rect id="public" x="44" y="546" width="8" height="8"></rect>
                </g>
            </g>
        </svg>
    );
}

export { PublicType }