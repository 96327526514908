import { configureStore } from "@reduxjs/toolkit";
import searchSlice from "../data/api/Search/searchSlice";
import userDetailsSlice from "../data/api/UserDetails/userDetailsSlice";
import sidePanelSlice from "../data/api/SidePanel/sidePanelSlice";
import historySlice from "../data/api/History/historySlice";
import favoriteSlice from "../data/api/Favorite/favoriteSlice";
import qaSlice from "../data/api/QA/qaSlice";
import interviewSlice from "../data/api/Interview/interviewSlice";
import contractSlice from "../data/api/Contract/contractSlice";
import invoiceSlice from "../data/api/Invoice/invoiceSlice";
import visualQASlice from "../data/api/VisualQA/visualQASlice";

const store = configureStore({
  reducer: {
    searchDetails: searchSlice,
    userDetails: userDetailsSlice,
    sidePanelDetails: sidePanelSlice,
    historyDetails: historySlice,
    favoriteDetails: favoriteSlice,
    qaDetails: qaSlice,
    interviewTabDetails: interviewSlice,
    contractDetails: contractSlice,
    invoiceDetails: invoiceSlice,
    visualQADetails: visualQASlice,
  },
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
