import { useEffect, useState } from "react";
import { FAVOURITE_SIDE_TAB, HISTORY_SIDE_TAB } from "../../../constants";
import "./index.css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  QAHistoryResponse,
  getChatBySessionId,
  getQAFavorites,
  getQAHistory,
} from "../../../data/api/QA/qaSlice";
import { RootState } from "../../../redux/store";
import { ListGroup, Spinner } from "react-bootstrap";

interface QAHistoryMap {
  [key: string]: QAHistory[];
}

interface QAHistory {
  session_id: string;
  query: string;
  date_time: string;
}

function QAHistory() {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(HISTORY_SIDE_TAB);
  const [history, setHistory] = useState<QAHistoryMap>({});
  const [favorites, setFavorites] = useState<QAHistoryMap>({});

  const {
    historyResults,
    favoritesResults,
    isLoadingFavorites,
    isLoadingHistory,
  } = useAppSelector((state: RootState) => state.qaDetails);

  useEffect(() => {
    console.log("dispatch get qa history");
    if (historyResults.result.length === 0) {
      dispatch(getQAHistory());
    }
    if (favoritesResults.result.length === 0) {
      dispatch(getQAFavorites());
    }
  }, []);

  useEffect(() => {
    setHistoryMap(historyResults);
  }, [historyResults]);

  useEffect(() => {
    setHistoryMap(favoritesResults, true);
  }, [favoritesResults]);

  const onHistoryClick = (history: QAHistory) => {
    console.log("get chat " + JSON.stringify(history));
    dispatch(getChatBySessionId({ sessionId: history.session_id }));
  };

  const setHistoryMap = (response: QAHistoryResponse, isFavorites = false) => {
    if (response.result) {
      const historyMap: QAHistoryMap = {};
      response.result.forEach((result) => {
        const date = new Date(result.date_time);
        const dateString = `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
        if (historyMap[dateString]) {
          const historyArray = historyMap[dateString];
          historyArray.push(result);
          historyMap[dateString] = historyArray.sort((a, b) => {
            return (
              new Date(a.date_time).getTime() - new Date(b.date_time).getTime()
            );
          });
        } else {
          historyMap[dateString] = [result];
        }
      });
      console.log("hist map " + JSON.stringify(historyMap));
      if (isFavorites) {
        setFavorites(historyMap);
      } else {
        setHistory(historyMap);
      }
    }
  };

  const getFormattedDate = (dateString: string) => {
    const date = new Date();
    const dateTokens = dateString.split("-");
    date.setMonth(parseInt(dateTokens[0]));
    date.setDate(parseInt(dateTokens[1]));
    date.setFullYear(parseInt(dateTokens[2]));
    return `${date.toLocaleString("en", {
      month: "short",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const getHistoryItemsJSX = (
    map: QAHistoryMap,
    key: "history" | "favorites"
  ) => {
    if (
      (isLoadingHistory && key === "history") ||
      (isLoadingFavorites && key === "favorites")
    ) {
      return (
        <div className="w-100 loading-container d-flex flex-column align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
          <div className="loading-txt">Loading {key}</div>
        </div>
      );
    }
    return Object.keys(map)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .map((date) => {
        const historyArray = map[date];
        const header = (
          <div
            data-testid="qa-history-header"
            className="date-header"
            key={date}
          >
            {getFormattedDate(date)}
          </div>
        );
        const items = historyArray.map((history) => {
          return (
            <ListGroup.Item
              action
              data-testid="qa-history-item"
              className="qa-history-list-item"
              key={history.session_id}
              onClick={() => onHistoryClick(history)}
            >
              <div className="qa-history-list-txt">{history.query}</div>
            </ListGroup.Item>
          );
        });
        return (
          <div key={`${key}-${date}`} className="qa-history-list-group">
            {header}
            <ListGroup>{items}</ListGroup>
          </div>
        );
      });
  };

  return (
    <div className="qa-history-tab">
      <div className="qa-history-tab-header d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <div className="qa-history-tab-heading">
            {" "}
            {selectedTab === HISTORY_SIDE_TAB
              ? "Q&A History"
              : "Favorites"}{" "}
          </div>
          <div className="qa-history-toggle d-flex">
            <div
              className={`qa-history-tab-toggle-container d-flex align-items-center justify-content-center cursor-pointer ${
                selectedTab === HISTORY_SIDE_TAB ? "active" : "inactive"
              }`}
              onClick={() => setSelectedTab(HISTORY_SIDE_TAB)}
            >
              <div>History</div>
            </div>
            <div
              data-testid="qa-favorites-button"
              className={`qa-history-tab-toggle-container d-flex align-items-center justify-content-center cursor-pointer ${
                selectedTab === FAVOURITE_SIDE_TAB ? "active" : "inactive"
              }`}
              onClick={() => setSelectedTab(FAVOURITE_SIDE_TAB)}
            >
              <div>Favorites</div>
            </div>
          </div>
        </div>
        <div className="qa-history-tab-subheading">
          Select a chat below to view the results
        </div>
      </div>
      <div className="qa-history-tab-content">
        <div className="qa-overflow-container">
          {selectedTab === HISTORY_SIDE_TAB
            ? getHistoryItemsJSX(history, "history")
            : getHistoryItemsJSX(favorites, "favorites")}
        </div>
      </div>
    </div>
  );
}

export default QAHistory;
