import { SUPPORTED_IMAGE_TYPES } from "../../constants";
import { QAFileInput } from "../../data/api/QA/qaSlice";
import { getFileExtension } from "../../utils/file";
import FileIcon from "../FileIcons";
import "./index.css";

type QuestionBubbleFileInputProps = {
  files?: QAFileInput[];
};

function QuestionBubbleFileInput({ files }: QuestionBubbleFileInputProps) {
  if (!files || files.length === 0) {
    return;
  }
  if (files.length === 1) {
    const file = files[0];
    const fileExtension = getFileExtension(file.name) ?? "";
    const isImage = SUPPORTED_IMAGE_TYPES.includes(fileExtension);
    if (isImage) {
      return <img src={file.url} className="question-file-img" />;
    } else {
      return (
        <div className="d-flex align-items-center bubble-file-icon-name-container">
          <div className="bubble-file-icon-container">
            <FileIcon url={file.name} />
          </div>
          <div className="bubble-file-name-txt">{file.name}</div>
        </div>
      );
    }
  } else if (files.length === 2) {
    const file1 = files[0];
    const file2 = files[1];
    const fileExtension1 = getFileExtension(file1.name) ?? "";
    const fileExtension2 = getFileExtension(file2.name) ?? "";
    const isImage1 = SUPPORTED_IMAGE_TYPES.includes(fileExtension1);
    const isImage2 = SUPPORTED_IMAGE_TYPES.includes(fileExtension2);
    if (isImage1 && isImage2) {
      return (
        <div className="d-flex">
          {files.map((file) => {
            return <img src={file.url} className="question-file-img" />;
          })}
        </div>
      );
    } else if (!isImage1 && !isImage2) {
      return (
        <div className="d-flex flex-column align-items-end">
          {files.map((file) => {
            return (
              <div className="d-flex align-items-center bubble-file-icon-name-container">
                <div className="bubble-file-icon-container">
                  <FileIcon url={file.name} />
                </div>
                <div className="bubble-file-name-txt">{file.name}</div>
              </div>
            );
          })}
        </div>
      );
    } else {
      const imageFile = isImage1 ? file1 : file2;
      const docFile = isImage1 ? file2 : file1;
      return (
        <div className="d-flex flex-column align-items-end">
          <img src={imageFile.url} className="question-file-img" />
          <div className="d-flex align-items-center bubble-file-icon-name-container">
            <div className="bubble-file-icon-container">
              <FileIcon url={docFile.name} />
            </div>
            <div className="bubble-file-name-txt">{docFile.name}</div>
          </div>
        </div>
      );
    }
  }
}

export default QuestionBubbleFileInput;
