function PngIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>DF24BD28-E56F-489F-9F5C-53BBEE787FD0</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-283, -621)">
                    <g id="PNG" transform="translate(283, 621)">
                        <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                            <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                            <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                        </g>
                        <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                        <path d="M21.7647059,17.1818182 C21.5481273,17.4411778 21.2419806,17.642379 20.8462567,17.7854278 C20.4505328,17.9284767 20.0120345,18 19.5307487,18 C19.0253985,18 18.5822212,17.889707 18.2012032,17.6691176 C17.8201853,17.4485283 17.5260706,17.1283443 17.3188503,16.7085561 C17.11163,16.288768 17.0053476,15.7954574 17,15.2286096 L17,14.8315508 C17,14.2486602 17.098261,13.7439861 17.2947861,13.3175134 C17.4913111,12.8910406 17.7747308,12.5648407 18.1450535,12.3389037 C18.5153762,12.1129668 18.9491954,12 19.4465241,12 C20.1390409,12 20.6804793,12.1651053 21.0708556,12.4953209 C21.4612319,12.8255364 21.692513,13.3061466 21.7647059,13.9371658 L20.5935829,13.9371658 C20.5401067,13.6029395 20.4217924,13.3582895 20.2386364,13.2032086 C20.0554804,13.0481276 19.8034775,12.9705882 19.4826203,12.9705882 C19.0735274,12.9705882 18.7620332,13.12433 18.5481283,13.4318182 C18.3342235,13.7393064 18.2259358,14.196521 18.223262,14.8034759 L18.223262,15.1764706 C18.223262,15.7887731 18.339571,16.2513353 18.5721925,16.5641711 C18.804814,16.8770069 19.1457197,17.0334225 19.5949198,17.0334225 C20.0467937,17.0334225 20.368983,16.9371667 20.5614973,16.7446524 L20.5614973,15.7379679 L19.4665775,15.7379679 L19.4665775,14.8516043 L21.7647059,14.8516043 L21.7647059,17.1818182 Z" id="G" fill="#FFFFFF"></path>
                        <polygon id="N" fill="#FFFFFF" points="15.8791209 18 14.6428571 18 12.2362637 14.0521978 12.2362637 18 11 18 11 12 12.2362637 12 14.646978 15.956044 14.646978 12 15.8791209 12"></polygon>
                        <path d="M6.23626373,15.885989 L6.23626373,18 L5,18 L5,12 L7.34065934,12 C7.79121104,12 8.18749829,12.0824168 8.52953297,12.2472527 C8.87156764,12.4120887 9.13461447,12.6462897 9.31868132,12.9498626 C9.50274817,13.2534356 9.59478022,13.5988992 9.59478022,13.9862637 C9.59478022,14.5741788 9.39354597,15.037773 8.99107143,15.3770604 C8.58859689,15.7163479 8.03159697,15.885989 7.32005494,15.885989 L6.23626373,15.885989 Z M6.23626373,14.8846154 L7.34065934,14.8846154 C7.66758405,14.8846154 7.91689474,14.8076931 8.0885989,14.6538462 C8.26030305,14.4999992 8.34615384,14.2802212 8.34615384,13.9945055 C8.34615384,13.700548 8.25961625,13.462913 8.08653846,13.2815934 C7.91346067,13.1002738 7.67445207,13.0068682 7.36950549,13.0013736 L6.23626373,13.0013736 L6.23626373,14.8846154 Z" id="Shape" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export { PngIcon };