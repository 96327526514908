import "./index.css";
import { CONTRACT_ERROR_LIST } from "../../constants";
import { CloseCircle } from "../../svg/closeCircle";

export const ContractErrorPopup = ({ setShowPopup }: any) => (
  <div
    className="contract-error-container"
    data-testid="contract-error-container"
  >
    <span className="top-bot"></span>
    <span className="top-bot top-bot-border"></span>
    <div className="contract-error">
      <div className="d-flex align-items-center justify-content-between pb-3">
        <div className="error-title">Errors (3)</div>
        <div onClick={() => setShowPopup(false)} className="cursor-pointer">
          <CloseCircle size={"20px"} color="#000000" />
        </div>
      </div>
      <table className="contract-errors-table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Error Type</th>
          </tr>
        </thead>
        <tbody className="table-border">
          {CONTRACT_ERROR_LIST.map((item, index) => (
            <tr key={index} data-testid="contract-error-list-item">
              <td>{item.file_name}</td>
              <td>{item.errorType}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default ContractErrorPopup;
