import { useState } from "react";
import { FILE_EXTRACTED_LIST } from "../../constants";
import "./index.css";

const ContractTable = ({
  checkedItems,
  setCheckedItems,
  displayedFiles,
  selectAllChecked,
  setSelectAllChecked,
  uploadedFiles,
  viewContractDetails,
}: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const totalPages = Math.ceil(FILE_EXTRACTED_LIST.length / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(
    currentPage * itemsPerPage,
    FILE_EXTRACTED_LIST.length
  );

  const handleSelectAllChange = () => {
    const newCheckedState = selectAllChecked
      ? []
      : displayedFiles.map((file: { file_id: any }) => file.file_id);
    setCheckedItems(newCheckedState);
    setSelectAllChecked(!selectAllChecked);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setCheckedItems([]);
    setSelectAllChecked(false);
  };

  const paginateData = (data: any[]) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const handleCheckboxChange = (fileId: string) => {
    setCheckedItems((prevState: any) => {
      const newState = [...prevState];
      if (newState.includes(fileId)) {
        newState.splice(newState.indexOf(fileId), 1);
      } else {
        newState.push(fileId);
      }
      return newState;
    });
  };

  return (
    <div className="files-list">
      <table className="extracted-file-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectAllChecked}
                onChange={handleSelectAllChange}
              />
            </th>
            <th>File Name</th>
            <th>Status</th>
          </tr>
        </thead>
        {uploadedFiles > 0 && (
          <tbody>
            {paginateData(displayedFiles).map((file, index) => (
              <tr
                key={index}
                onClick={(e) =>
                  viewContractDetails(e, file.file_name, file.file_id)
                }
              >
                <td>
                  <input
                    type="checkbox"
                    className={
                      file.status === "Error" ? "opacity-75 cursor-default" : ""
                    }
                    disabled={file.status === "Error"}
                    checked={checkedItems.includes(file.file_id)}
                    onChange={() => handleCheckboxChange(file.file_id)}
                  />
                </td>
                <td className="file-name-color">{file.file_name}</td>
                <td className={file.status === "Error" ? "text-danger" : ""}>
                  {file.status}
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {displayedFiles.length > 10 && (
        <div className="pagination-files">
          <div className="paginated-text">
            Showing {startItem} - {endItem} of {displayedFiles.length}
          </div>
          <div className="d-flex gap-10 align-items-center">
            <div>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  className={`pagination-pages
                          ${
                            currentPage === i + 1
                              ? "pagination-active"
                              : "pagination-default"
                          }
                        `}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
            <div className="pagination-arrows">
              <button
                className="arrow-button"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              <button
                className="arrow-button"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractTable;
