import { Offcanvas } from 'react-bootstrap';
import { ChevronLeft } from '@rss-engineering/fabric-icons';
import { useState, useEffect } from 'react';
import SidePanel from '../SidePanel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setIsSidePanelActiveMobileView } from '../../data/api/SidePanel/sidePanelSlice';

function SidePanelMobileView() {
  const dispatch = useAppDispatch();
  const {
    isSidePanelActiveMobileView,
  } = useAppSelector((state: RootState) => state.sidePanelDetails);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isSidePanelActiveMobileView) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isSidePanelActiveMobileView]);

  const handleClose = () => {
    dispatch(setIsSidePanelActiveMobileView(false));
    setShow(false);
  };

  return (
    <Offcanvas show={show} placement="end" className="mobile-view-side-panel" onHide={handleClose} scroll tabIndex={-1} backdrop={false}>
      <Offcanvas.Title className="d-flex me-auto mt-6 ms-2  flex-row align-items-center" data-testid="side-panel-title">
        <ChevronLeft
          className="cursor-pointer canvas-header-close-button"
          title="back"
          onClick={handleClose}
          size={24}
          data-testid="side-panel-close"
        />
        <span className="canvas-header-text"> Back</span>
      </Offcanvas.Title>
      <SidePanel />
    </Offcanvas>
  );
}

export default SidePanelMobileView;
