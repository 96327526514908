import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { serializeError } from "serialize-error";
import {
  RawRequestError,
  fetchPostApi,
  fetchPutApi,
  fetchGetApi,
} from "../../../api/fetch";
import { PREDICT } from "../../../constants";
import { History, Favorites } from "../../../types";
import { HISTORY_URL, FAVORITES_URL, GET_SEARCH_URL } from "../../../constants";
import { getResponseConfig } from "../../../utils/settings-utils";
import { RootState } from "../../../redux/store";

type SearchParams = {
  query: string;
};

type RatingParams = {
  rating: number;
  id: number;
};

export enum GetSearch {
  search,
  historyfav,
}

type FavoriteParams = {
  favorite: boolean;
  id: number;
};

export type Predictions = {
  predictions: string;
};

export type Source = {
  title: string;
  link: string;
  data_set: string;
  data_classification: string;
  document_type: string;
};

type ResponseObject = {
  search_id: string;
  query: string;
  answer: string;
  metadata: {
    source: Source[];
    data_classification: string;
    favorite: boolean;
    rating: number;
  };
};

type InitialState = {
  isLoading: boolean;
  searchResult: Record<
    number,
    {
      query: string;
      data: ResponseObject;
    }
  >;
  error: RawRequestError | null;
  history: History[];
  favorites: Favorites[];
  isLoadingHistory: boolean;
  isLoadingFavorites: boolean;
  isFavorite: boolean;
  favoriteSearchData: any;
  getSearch: GetSearch;
};

const initialState: InitialState = {
  isLoading: false,
  searchResult: {},
  error: null,
  history: [] as History[],
  favorites: [] as Favorites[],
  isLoadingHistory: false,
  isLoadingFavorites: false,
  isFavorite: false,
  favoriteSearchData: {},
  getSearch: GetSearch.search,
};

const fetchSearchResults = createAsyncThunk(
  "search/fetchSearchResults",
  async (params: SearchParams, thunkAPI) => {
    const { query } = params;

    const reqData = {
      query,
      response_config: getResponseConfig(thunkAPI.getState() as RootState),
    };

    return fetchPostApi(reqData, PREDICT)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const fetchPutRatingResults = createAsyncThunk(
  "search/fetchPutRatingResults",
  async (params: RatingParams, thunkAPI) => {
    const { rating, id } = params;

    const reqData = {
      rating,
      id,
    };
    const RATING = `search_rating/${id}/rating?rating=${rating}`;
    return fetchPutApi(reqData, RATING)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const fetchPutFavoriteResults = createAsyncThunk(
  "search/fetchPutFavoriteResults",
  async (params: FavoriteParams, thunkAPI) => {
    const { favorite, id } = params;

    const reqData = {
      favorite,
      id,
    };
    const FAVORITE = `search_favorite/${id}/favorite?favorite=${favorite}`;
    return fetchPutApi(reqData, FAVORITE)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

// fetch history details
const fetchHistory = createAsyncThunk(
  "login/fetchHistory",
  async (_: void, thunkAPI) =>
    fetchGetApi(HISTORY_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);

const fetchFavorites = createAsyncThunk(
  "login/fetchFavorites",
  async (_: void, thunkAPI) =>
    fetchGetApi(FAVORITES_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)))
);

// fetch Favorites details
const getSearch = createAsyncThunk(
  "getsearch/fetchSearchResults",
  async (search_id: string, thunkAPI) => {
    const SEARCH_URL = GET_SEARCH_URL + "/search_id?search_id=" + search_id;
    return fetchGetApi(SEARCH_URL)
      .then((res) => res)
      .catch((e: Error) => thunkAPI.rejectWithValue(serializeError(e)));
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setError: (state, action) => {
      /* eslint-disable no-param-reassign */
      state.error = action.payload;
    },
    deleteQuestion: (state, action) => {
      /* eslint-disable no-param-reassign */
      const editQuestionId: number = action.payload;
      delete state.searchResult[editQuestionId];
    },
    setGetSearch: (state, action) => ({
      ...state,
      getSearch: action.payload,
    }),
    setRating: (state, action) => {
      /* eslint-disable no-param-reassign */
      const editQuestionId: number = action.payload.id;
      state.searchResult[editQuestionId].data.metadata.rating =
        action.payload.rating;
    },
    setFavorite: (state, action) => {
      /* eslint-disable no-param-reassign */
      const editQuestionId: number = action.payload.id;
      state.searchResult[editQuestionId].data.metadata.favorite =
        action.payload.favorite;
      state.isFavorite = action.payload.favorite;
      state.favoriteSearchData = action.payload.searchResult;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchResults.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchSearchResults.fulfilled, (state, action) => {
      const { data } = action.payload;
      const { query } = action.meta.arg;
      let history = state.history;
      const existsInHistory = history.find(
        (history) => history.search_id === data.search_id
      );
      if (!existsInHistory) {
        const historyObj = {
          search_id: data.search_id,
          query: data.query,
          date_time: data.date_time,
        };
        history = [...state.history, historyObj];
      }
      const resultObject = {
        [data.search_id]: {
          query,
          data,
        },
      };

      const newSearchResults = {
        ...state.searchResult,
        ...resultObject,
      };

      // Push new result to array for incremental thread
      return {
        ...state,
        isLoading: false,
        searchResult: newSearchResults,
        error: null,
        history,
      };
    });
    builder.addCase(fetchSearchResults.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload as RawRequestError,
    }));
    builder.addCase(fetchHistory.pending, (state) => ({
      ...state,
      isLoadingHistory: true,
    }));
    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      const history = action.payload.data.result;
      return {
        ...state,
        history: history,
        isLoadingHistory: false,
        error: null,
      };
    });
    builder.addCase(fetchHistory.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        ...state,
        isLoadingHistory: false,
        history: [],
        error: action.payload as RawRequestError,
      };
    });
    builder.addCase(fetchFavorites.pending, (state) => ({
      ...state,
      isLoadingFavorites: true,
    }));
    builder.addCase(fetchFavorites.fulfilled, (state, action) => {
      const favorites = action.payload.data.result;
      return {
        ...state,
        isLoadingFavorites: false,
        favorites: favorites,
        error: null,
      };
    });
    builder.addCase(fetchFavorites.rejected, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // const favorites = action.payload.data.result;
      return {
        ...state,
        isLoadingFavorites: false,
        favorites: [],
        error: action.payload as RawRequestError,
      };
    });
    builder.addCase(fetchPutFavoriteResults.pending, (state) => ({
      ...state,
      // isLoadingFavorite: true,
    }));
    builder.addCase(fetchPutFavoriteResults.fulfilled, (state) => {
      let favorites = state.favorites;
      // if (state.chatResults && state.chatResults.length > 0) {
      const searchObj = state.favoriteSearchData;
      //const existsInFavorites = state.favoritesResults.find( (favorite) => favorite.session_id === firstChat.session_id);
      if (state.isFavorite) {
        const favorite = {
          search_id: searchObj.search_id,
          query: searchObj.query,
          date_time: searchObj.date_time,
        };
        favorites = [favorite, ...state.favorites];
      } else if (!state.isFavorite) {
        const filteredFavorites = state.favorites.filter(
          (favorite) => favorite.search_id !== searchObj.search_id
        );
        favorites = [...filteredFavorites];
      }
      // }
      return {
        ...state,
        favorites,
        //   isLoadingFavorite: false
      };
    });
    builder.addCase(fetchPutFavoriteResults.rejected, (state, action) => {
      return {
        ...state,
        isFavorite: !state.isFavorite,
        // isLoadingFavorite: false,
        favoriteError: action.payload as RawRequestError,
      };
    });
    builder.addCase(getSearch.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(getSearch.fulfilled, (state, action) => {
      const { data } = action.payload;
      const query = action.payload.data.query;
      const resultObject = {
        [data.search_id]: {
          query,
          data,
        },
      };

      const newSearchResults = {
        ...resultObject,
      };

      // Push new result to array for incremental thread
      return {
        ...state,
        isLoading: false,
        searchResult: newSearchResults,
        error: null,
      };
    });
    builder.addCase(getSearch.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload as RawRequestError,
    }));
  },
});

const { setError, setFavorite, setRating, deleteQuestion, setGetSearch } =
  searchSlice.actions;

export default searchSlice.reducer;
export {
  fetchSearchResults,
  setError,
  setFavorite,
  setRating,
  deleteQuestion,
  initialState,
  fetchPutRatingResults,
  fetchPutFavoriteResults,
  setGetSearch,
  fetchHistory,
  fetchFavorites,
  getSearch,
};
