import { Accordion } from 'react-bootstrap';
import { SIDE_PANEL_FAQS } from '../../../constants';
import './index.css';

function FAQ() {
  return (
    <>
      <div className="p-6">
        FAQ
      </div>
      <div className="align-items-center">
        <Accordion>
          {
            SIDE_PANEL_FAQS.map((faq, index) => (
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header className="faq-header">{faq.question}</Accordion.Header>
                <Accordion.Body>
                  {faq.answer}
                </Accordion.Body>
              </Accordion.Item>
            ))
          }
        </Accordion>
      </div>
    </>
  );
}

export default FAQ;
