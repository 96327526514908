import { useEffect, useState } from "react";
import "./index.css";
import { ContractIcon } from "../../svg/contractIcon";
import { Alert, Button, Spinner } from "react-bootstrap";
import SelectDropDown from "../../components/SelectDropdown";
import { SearchIcon } from "../../svg/searchIcon";
import { RootState } from "../../redux/store";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import {
  // FILE_EXTRACTED_LIST,
  // GCS_EXTRACTED_DETAILS,
  CONTRACT_STATUS,
} from "../../constants/DataConstants";
import ContractResponse from "../../components/ContractResponse";
import ContractErrorPopup from "../../components/ContractErrorPopup";
import logo from "../../svg/fair-logo-red.svg";
import ContractTable from "../../components/ContractTable";
import ContractConfirmPopup from "../../components/ContractConfirmPopup";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  uploadContract,
  fetchFileList,
  getExtractedData,
  submitContract,
  setFiledata,
  getDownloadContract,
} from "../../data/api/Contract/contractSlice";

function ContractExtraction() {
  const [gcsBucket, setGcsBucket] = useState("");
  const [previousBucket, setPreviousBucket] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<{
    name: string;
    value: string;
  }>({
    name: "All",
    value: "All",
  });
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [viewSummary, setViewSummary] = useState(false);
  const [viewSource, setViewSource] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileId, setFileId] = useState("");
  const [bucketId, setBucketId] = useState("");
  const {
    bucket_id,
    status,
    file_data,
    totalFilesUploaded,
    filesSuccessfullyUploaded,
    filesWithError,
    submitContractLoading,
    submitContractStatus,
    contractError,
    isDownloading,
    isUploadingVar,
    uploadedFilesVar,
    bucket_name,
  } = useAppSelector((state: RootState) => state.contractDetails);
  // const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  // const [itemsPerPage] = useState(10);
  const [displayedFiles, setDisplayedFiles] = useState<
    Array<{ file_name: string; status: string; file_id: string }>
  >([]);
  const [remainingFiles, setRemainingFiles] = useState<
    Array<{ file_name: string; status: string; file_id: string }>
  >([]);
  const [extractedAll, setExtracxtedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [addNewSource, setAddNewSource] = useState(false);
  const [addNewUpload, setAddNewUpload] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showSubmitContractToast, setShowSubmitContractToast] = useState(false);
  const [toastHeader, setToastHeader] = useState("");
  const [toastBody, setToastBody] = useState("");
  const [toastVariant, setToastVariant] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (isUploading) {
      setTimeout(() => {
        if (
          file_data.length > 0 &&
          file_data.every((file) =>
            Object.values(file).some((value) => value !== "")
          )
        ) {
          const initialFile = file_data[0];
          setDisplayedFiles(file_data);
          const remainingFiles = file_data.filter(
            (file) => file !== initialFile
          );
          setRemainingFiles(remainingFiles);
          setUploadedFiles(1);
        }
      }, 5000);
    }
  }, [isUploading]);

  useEffect(() => {
    if (
      addNewUpload &&
      file_data.every((file) =>
        Object.values(file).some((value) => value !== "")
      )
    ) {
      setTimeout(() => {
        if (file_data.length > 0) {
          const initialFile = file_data[0];
          setDisplayedFiles(file_data);
          const remainingFiles = file_data.filter(
            (file) => file !== initialFile
          );
          setRemainingFiles(remainingFiles);

          setUploadedFiles(1);
        }
      }, 5000);
    }
  }, [addNewUpload]);

  useEffect(() => {
    setIsUploading(isUploadingVar);
    setUploadedFiles(uploadedFilesVar);
  }, [isUploadingVar]);

  useEffect(() => {
    if (submitContractStatus === "success") {
      setShowSubmitContractToast(true);
      setToastHeader("Success");
      setToastBody("Contract Submitted");
      setToastVariant("success");
    }
    if (submitContractStatus === "failed") {
      setShowSubmitContractToast(true);
      setToastHeader("Failed");
      setToastBody("Contract Submission Failed");
      setToastVariant("danger");
    }
  }, [submitContractStatus]);

  useEffect(() => {
    if (displayedFiles.length === 1 && isUploading) {
      setTimeout(() => {
        setDisplayedFiles((prevFiles) => [...prevFiles, ...remainingFiles]);
        //  setExtracxtedAll(true);
        setUploadedFiles(remainingFiles.length + 1);
        setAddNewUpload(false);
      }, 5000);
    } else if (isUploading) {
      setUploadedFiles(displayedFiles.length);
    }
  }, [displayedFiles]);

  const viewContractDetails = (e: any, filename: string, file_id: string) => {
    const targetElement = e.target as HTMLInputElement;
    if (
      targetElement.tagName.toLowerCase() !== "input" ||
      targetElement.type !== "checkbox"
    ) {
      setViewSummary(true);
      setFileName(filename);
      setFileId(file_id);
      dispatch(getExtractedData(file_id));
    }
  };

  useEffect(() => {
    if (contractError) {
      setIsUploading(false);
      setViewSummary(false);
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [contractError]);

  const resetUpload = () => {
    setRemainingFiles([]);
    setDisplayedFiles([]);
    setUploadedFiles(0);
    setAddNewSource(false);
    setUploadedFiles(0);
    setCheckedItems([]);
    setSelectAllChecked(false);
    setExtracxtedAll(false);
    setViewSummary(false);
    setViewSource(false);

    setIsUploading(true);
    setAddNewUpload(true);
    setShowAlert(false);
    setPreviousBucket(gcsBucket);
    uploadContracts(gcsBucket, true);
  };

  useEffect(() => {
    const filtered = file_data.filter(
      (item) =>
        item.file_name
          .toLowerCase()
          .includes(searchValue.toLowerCase().trim()) &&
        (selectedCategory.name !== "All"
          ? item.status === selectedCategory.name
          : true)
    );

    setDisplayedFiles(filtered);
  }, [searchValue, selectedCategory, file_data]);

  const submitContractToBigQuery = () => {
    setShowSubmitPopup(false);
    setCheckedItems([]);
    dispatch(submitContract(bucketId));
    setSelectAllChecked(false);
  };

  const getLoadingComponent = () => {
    return (
      <div className="extract-data-loading-container w-100 h-100 d-flex justify-content-center">
        <div className="spinner-container d-flex flex-column align-items-center">
          <Spinner animation="border" variant="primary" />
          <div className="loading-txt">
            Submitting Contract
            <br />
            This may take a few moments
          </div>
        </div>
      </div>
    );
  };

  const uploadContracts = (bucketName: string, newSource?: boolean) => {
    console.log("Uploading contracts from GCS bucket", bucketName);
    if (newSource) {
      dispatch(setFiledata([]));
    }
    dispatch(uploadContract(bucketName));
    setIsUploading(true);
  };

  useEffect(() => {
    if (bucket_id) {
      setBucketId(bucket_id);
      fetchingFileList(bucket_id);
    }
  }, [bucket_id]);

  useEffect(() => {
    // let interval = setInterval(() => {
    //   const res = await fetch(`https://jsonplaceholder.typicode.com/posts`);
    // }, 2000);
    var timer: string | number | NodeJS.Timeout | undefined;
    if (status !== "Completed" && bucket_id) {
      // fetchingFileList(bucket_id);
      timer = setInterval(() => {
        fetchingFileList(bucket_id);
      }, 5000);
      if (status === "Completed") {
        //clearInterval(timer);
        fetchingFileList(bucket_id);
        setExtracxtedAll(true);
      }
    }
    if (status === "Completed") {
      setGcsBucket(bucket_name);
      // clearInterval(timer);
      fetchingFileList(bucket_id);
      setExtracxtedAll(true);
    }
    return () => {
      clearInterval(timer);
    };
  }, [status]);

  const downloadContracts = (fileType: string) => {
    console.log(checkedItems, "checkedItems");

    checkedItems.forEach((fileId: string) => {
      const selectedFile = displayedFiles.find(
        (file: any) => file.file_id === fileId
      );
      if (selectedFile) {
        const fileName = `Contract Details - ${
          selectedFile.file_name.split(".")[0]
        }.${fileType}`;
        dispatch(
          getDownloadContract({
            fileType,
            fileName,
            fileId: selectedFile.file_id,
          })
        );
      }
    });
  };

  const fetchingFileList = (bucketId: string) => {
    dispatch(fetchFileList(bucketId));
  };

  useEffect(() => {
    setDisplayedFiles(file_data);
  }, [file_data]);

  const isAnyCheckboxChecked = checkedItems.some((item) => item);
  const numSelected = checkedItems.filter((item) => item).length;

  return (
    <div
      className="contract-container contract-container-padding"
      data-testid="contract-extraction-component"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="contract-title">Contract Extraction</div>
        {extractedAll && !addNewSource && (
          <div
            className="add-new-source"
            onClick={() => {
              setAddNewSource(true);
              setGcsBucket("");
            }}
          >
            Add New Source
          </div>
        )}
      </div>
      <ToastContainer position="top-center" className="p-3">
        <Toast
          bg={toastVariant}
          onClose={() => setShowSubmitContractToast(false)}
          show={showSubmitContractToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">{toastHeader}</strong>
          </Toast.Header>
          <Toast.Body>{toastBody}</Toast.Body>
        </Toast>
      </ToastContainer>
      {addNewSource && (
        <div className="new-gcs-container">
          <div className="source-link-container">
            <label className="source-file-label">GCS Bucket Link:</label>
            <input
              type="text"
              className="gcs-input"
              placeholder="Enter GCS bucket link with contract files"
              value={gcsBucket}
              onChange={(e) => setGcsBucket(e.target.value)}
            />
            <button
              className={`upload-contract-button ${
                !gcsBucket ? "disable-opacity" : ""
              }`}
              disabled={!gcsBucket}
              onClick={resetUpload}
            >
              Upload Contracts
            </button>
          </div>
        </div>
      )}
      <div className="gcs-container">
        {!isUploading ? (
          <div className="source-link-container">
            <label className="source-file-label">GCS Bucket Link:</label>
            <input
              type="text"
              className="gcs-input"
              placeholder="Enter GCS bucket link with contract files"
              value={gcsBucket}
              onChange={(e) => {
                setGcsBucket(e.target.value);
                setPreviousBucket(e.target.value);
              }}
            />
            <button
              className={`upload-contract-button ${
                !gcsBucket ? "disable-opacity" : ""
              }`}
              disabled={!gcsBucket}
              onClick={() => uploadContracts(gcsBucket)}
            >
              Upload Contracts
            </button>
          </div>
        ) : (
          <div
            className={`gcs-loading-container ${
              extractedAll ? "loaded-padding" : "loading-padding"
            }`}
          >
            <div className="source-link">
              <div className="small-bold">Source:</div>
              <div>{previousBucket}</div>
            </div>
            {!extractedAll ? (
              <>
                <div className="uploading-text">
                  <Spinner
                    animation="border"
                    variant="primary"
                    className="upload-spinner"
                  />
                  {uploadedFiles > 0 ? "Extracting" : "Uploading"}
                  {totalFilesUploaded > 0 && (
                    <>
                      {" File "}
                      {filesSuccessfullyUploaded === totalFilesUploaded
                        ? filesSuccessfullyUploaded
                        : filesSuccessfullyUploaded + 1}
                      {" of "}
                      {totalFilesUploaded}
                    </>
                  )}
                  {" …"}
                </div>
                <Button
                  className="text-primary cancel-upload-btn"
                  variant="transparent"
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <div className="extracted-details">
                <div className="d-flex gap-10">
                  <div className="source-link">
                    <div className="small-bold">Files Uploaded:</div>
                    <div>{totalFilesUploaded}</div>
                  </div>
                  <div className="source-link">
                    <div className="small-bold">Contracts Summarized:</div>
                    <div>{filesSuccessfullyUploaded}</div>
                  </div>
                  <div className="source-link position-relative">
                    <div className="small-bold">Errors:</div>
                    <div
                      className="errored-text"
                      onClick={() => setShowPopup(true)}
                    >
                      <u>{filesWithError}</u>
                    </div>
                    {showPopup && (
                      <ContractErrorPopup setShowPopup={setShowPopup} />
                    )}
                  </div>
                </div>
                <div className="d-flex gap-7">
                  {isDownloading ? (
                    <div className="download-files-btn d-flex align-items-center justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <button
                      className={`download-files-btn ${
                        !isAnyCheckboxChecked ? "disable-opacity" : ""
                      }`}
                      data-testid="download-button"
                      disabled={!isAnyCheckboxChecked}
                      onClick={() => downloadContracts("pdf")}
                    >
                      Download {numSelected > 0 && `(${numSelected})`}
                    </button>
                  )}
                  {submitContractLoading && getLoadingComponent()}
                  <button
                    className={`submit-contract-button ${
                      !extractedAll ? "disable-opacity" : ""
                    }`}
                    disabled={!extractedAll}
                    onClick={() => setShowSubmitPopup(true)}
                  >
                    Submit Contract
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="response-screen">
        {!isUploading && !viewSummary ? (
          <>
            {showAlert && (
              <Alert variant="danger" className="mb-0">
                <div className="d-flex gap-6 align-items-center">
                  <img src={logo} height={40} alt="Logo" />
                  Failed to upload contract.
                </div>
              </Alert>
            )}
            <div className="empty-contract-screen">
              <div className="d-flex flex-column align-items-center gap-7">
                <ContractIcon />
                <div>
                  Welcome to{" "}
                  <span className="title-bold">Contract Extraction</span>
                </div>
                <div className="d-flex flex-column gap-3">
                  <div>
                    1. Enter a GCS bucket link containing the contract files and
                    upload
                  </div>
                  <div>
                    2. We will automatically extract and summarize your
                    contracts
                  </div>
                  <div>
                    3. Review your contracts and submit them to Bigquery in GCP
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : isUploading && !viewSummary ? (
          <div className="file-uploaded-container">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="file-extracted">
                  Files Extracted ({filesSuccessfullyUploaded})
                </div>
                <div className="file-description">
                  Select a file to view the contract summary
                </div>
              </div>
              <div className="d-flex gap-10 align-items-center">
                <div className="d-flex gap-4 align-items-center">
                  <div className="small-bold">Status:</div>
                  <SelectDropDown
                    value={selectedCategory}
                    setValue={setSelectedCategory}
                    data={CONTRACT_STATUS}
                    placeHolder="Select"
                    width="133px"
                    height="40px"
                  />
                </div>
                <div className="search-file-box">
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search Files"
                    className="search-file-input"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
            <ContractTable
              checkedItems={checkedItems}
              displayedFiles={displayedFiles}
              selectAllChecked={selectAllChecked}
              setCheckedItems={setCheckedItems}
              setSelectAllChecked={setSelectAllChecked}
              uploadedFiles={uploadedFiles}
              viewContractDetails={viewContractDetails}
            />
          </div>
        ) : (
          <div className="file-summary-container">
            <div
              className="back-to-files"
              onClick={() => {
                setViewSummary(false);
                setViewSource(false);
                setCheckedItems([]);
                setSelectAllChecked(false);
              }}
            >
              {"< Back to File List"}
            </div>
            <ContractResponse
              viewSource={viewSource}
              setViewSource={setViewSource}
              fileName={fileName}
              fileId={fileId}
              bucketId={bucketId}
            />
          </div>
        )}
      </div>
      {showSubmitPopup && (
        <ContractConfirmPopup
          isOpen={showSubmitPopup}
          onClose={() => setShowSubmitPopup(false)}
          submit={() => submitContractToBigQuery()}
          title="Submit Contracts to Bigquery"
          buttonText="Submit"
        >
          <div className="submit-popup-content">
            <div className="submit-source-details">
              <div>
                <div className="small-bold">Source:</div>
                <div>{gcsBucket}</div>
              </div>
              <div>
                <div className="small-bold">Contracts</div>
                <div>{filesSuccessfullyUploaded}</div>
              </div>
            </div>
            <div className="py-9 px-5">
              <p>Once submitted these contracts can’t be edited. </p>
              <p>Are you sure you want to submit?</p>
            </div>
            <div className="pt-4 px-5 d-flex align-items-center gap-4">
              <input type="checkbox" />
              Don’t show this message again
            </div>
          </div>
        </ContractConfirmPopup>
      )}
    </div>
  );
}

export default ContractExtraction;
