function OptionsIcon() {
  return (
    <svg
      width="6px"
      height="24px"
      viewBox="0 0 6 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>8C8F60E9-CDC4-4930-BC10-E7FDF98EC8C3</title>
      <g
        id="Admin"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Admin---Connector-List"
          transform="translate(-1385, -393)"
          fill="#9E9E9E"
          stroke="#9E9E9E"
        >
          <g id="Group-3" transform="translate(329, 384)">
            <g id="options-icon" transform="translate(1056, 9)">
              <circle id="Oval" cx="3" cy="21" r="2.5"></circle>
              <circle id="Oval-Copy-3" cx="3" cy="12" r="2.5"></circle>
              <circle id="Oval-Copy-4" cx="3" cy="3" r="2.5"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { OptionsIcon };
