function EyesOnlyType() {
    return (
        <svg width="8px" height="8px" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>415787D2-41C1-4119-970C-2BA2AA1422A2</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-373, -530)" fill="#000000">
                    <rect id="confidential-copy" x="373" y="530" width="8" height="8"></rect>
                </g>
            </g>
        </svg>
    )
}

export { EyesOnlyType }