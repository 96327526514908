import "./index.css";
import { useAppSelector } from "../../redux/hooks";
import VisualQuestionBubbleFileInput from "../VisualQuestionBubbleFileInput";
import { VisualQAFileInput } from "../../data/api/VisualQA/visualQASlice";
import { selectRackerInitials } from "../../data/api/UserDetails/userDetailsSlice";

type VisualQuestionBubbleProps = {
  question: string;
  file?: VisualQAFileInput;
};

function VisualQuestionBubble({ question, file }: VisualQuestionBubbleProps) {
  const rackerName = useAppSelector(selectRackerInitials);

  return (
    <div
      data-testid="question-bubble"
      className="question-bubble-container d-flex justify-content-end align-items-end"
    >
      <div className="question-bubble d-flex flex-column align-items-end">
        {file && <VisualQuestionBubbleFileInput file={file} />}
        <div className="question-bubble-txt">{question}</div>
      </div>
      <div className="flex-shrink-0 name-badge">{rackerName}</div>
    </div>
  );
}

export default VisualQuestionBubble;
