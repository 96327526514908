import './index.css';

function AppLoader() {
  return (
    <div className="loading-answer">
      <span className="loading-text-color">Loading your answer...</span>
      <div className="mt-5 mx-auto spinner-border loader-color" role="status" />
    </div>
  );
}

export { AppLoader };
