import { Offcanvas } from 'react-bootstrap';
import { ChevronLeft } from '@rss-engineering/fabric-icons';
import { SIDE_PANEL_QUESTIONS } from '../../../../constants';
import { setIsSidePanelActiveMobileView, setQuestionFromCategory } from '../../../../data/api/SidePanel/sidePanelSlice';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import '../../index.css';

type QuestionsDrawerProps = {
  selectedCategory: number;
  showDrawer: boolean;
  handleClose: () => void;
};

function QuestionsDrawer({ selectedCategory, showDrawer, handleClose }:QuestionsDrawerProps) {
  const {
    isSidePanelActive,
    isSidePanelActiveMobileView,
  } = useAppSelector((state: RootState) => state.sidePanelDetails);

  const dispatch = useAppDispatch();

  const selectQuestion = (value: string) => {
    dispatch(setQuestionFromCategory(value));
    if (isSidePanelActiveMobileView) {
      dispatch(setIsSidePanelActiveMobileView(false));
    }
  };

  return (
    <Offcanvas show={(showDrawer && isSidePanelActive) || (showDrawer && isSidePanelActiveMobileView)} className="right-panel-questions-canvas" onHide={handleClose} onExit={handleClose} placement="end" scroll tabIndex={-1} backdrop={false}>
      <Offcanvas.Header className="border-bottom question-canvas-header-text">
        <Offcanvas.Title className="d-flex flex-row align-items-center">
          <ChevronLeft className="ms-auto cursor-pointer canvas-header-close-button" title="Prompt Categories" onClick={handleClose} size={24} />
          <span className="canvas-header-text"> Prompt Categories</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="d-flex flex-column m-6">
          <h6>{SIDE_PANEL_QUESTIONS[selectedCategory].name}</h6>
          <span className="canvas-body-description"> Click on a prompt below to add it to your question field</span>
        </div>
        <div className="align-items-center">
          {SIDE_PANEL_QUESTIONS[selectedCategory].questions.map((question, index) => (
            <div
              key={`question_${question.key}`}
              className="d-flex flex-row p-6 questions cursor-pointer"
              onClick={() => selectQuestion(question.value)}
              data-testid={`question_${index}`}
            >
              <span className="question-tile">{question.value}</span>
            </div>
          ))}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default QuestionsDrawer;
