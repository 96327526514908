function OpenPanel() {
  return (
    <svg width="28px" height="24px" viewBox="0 0 30 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>open pane</title>
      <g id="ICE-V.1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M27.8571429,0 C29.0406102,0 30,0.970049709 30,2.16666667 L30,23.8333333 C30,25.0299503 29.0406102,26 27.8571429,26 L2.14285714,26 C0.959389822,26 0,25.0299503 0,23.8333333 L0,2.16666667 C0,0.970049709 0.959389822,0 2.14285714,0 Z M19.2857143,2.16666667 L2.14285714,2.16666667 L2.14285714,23.8333333 L19.2857143,23.8333333 L19.2857143,2.16666667 Z M27.8571429,2.16666667 L21.4285714,2.16666667 L21.4285714,23.8333333 L27.8571429,23.8333333 L27.8571429,2.16666667 Z M10.3470982,9 C10.5405506,9 10.7079613,9.06504065 10.8493304,9.19512195 L11.2678571,9.58023107 C11.4092262,9.70346598 11.4799107,9.85750963 11.4799107,10.042362 C11.4799107,10.2272144 11.4092262,10.381258 11.2678571,10.5044929 L9.6328125,12.0141207 L15.3470982,12.0141207 C15.5405506,12.0141207 15.6977307,12.0783055 15.8186384,12.2066752 C15.9395461,12.3350449 16,12.4899444 16,12.6713736 L16,13.3286264 C16,13.5100556 15.9395461,13.6649551 15.8186384,13.7933248 C15.6977307,13.9216945 15.5405506,13.9858793 15.3470982,13.9858793 L9.6328125,13.9858793 L11.2678571,15.4955071 C11.4092262,15.618742 11.4799107,15.7727856 11.4799107,15.957638 C11.4799107,16.1390672 11.4092262,16.2948224 11.2678571,16.4249037 L10.8493304,16.8100128 C10.7042411,16.9366709 10.5368304,17 10.3470982,17 C10.1536458,17 9.984375,16.9366709 9.83928571,16.8100128 L6.20647321,13.4672657 C6.0688244,13.3406076 6,13.1848524 6,13 C6,12.8117244 6.0688244,12.6576808 6.20647321,12.5378691 L9.83928571,9.19512195 C9.98065476,9.06504065 10.1499256,9 10.3470982,9 Z" id="open-pane" fill="#000000" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export { OpenPanel };
