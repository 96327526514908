import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { RootState } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchSearchResults,
  setGetSearch,
} from "../../data/api/Search/searchSlice";
import SearchResponse from "../../components/searchResponse";
import WelcomePage from "../WelcomePage";
import { AppLoader } from "../../components/AppLoader";
import ErrorResponse from "../../components/ErrorResponse";
import { setQuestionFromCategory } from "../../data/api/SidePanel/sidePanelSlice";
import {
  MAX_CHARACTERS,
  EXTERNAL_DATASETS,
  INTERNAL_DATASETS,
} from "../../constants";
import { PromptSearch } from "../../svg/promptSearch";
import "./index.css";
import { GetSearch } from "../../data/api/Search/searchSlice";
import { fetchTokenDetails } from "../../data/api/UserDetails/userDetailsSlice";
import Settings from "../../components/Settings";

function Search() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [rackerName, setRackerName] = useState<string>("");
  const [isErrorInInput, setIsErrorinInput] = useState(false);
  const [selectedDataSet, setSelectedDataSet] = useState<string | null>();
  const [categoryText, setCategoryText] = useState("");
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [openSettings, setOpenSettings] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const resultsRef = useRef<HTMLDivElement | null>(null);

  const { searchResult, isLoading, error } = useAppSelector(
    (state: RootState) => state.searchDetails
  );

  const { questionFromCatagory, isSidePanelActive } = useAppSelector(
    (state: RootState) => state.sidePanelDetails
  );

  const { user, datasets, updatedDatasets } = useAppSelector(
    (state: RootState) => state.userDetails
  );

  const isInputValid = searchTerm.trim() !== "";
  const characterCountDisplay = `${searchTerm.length}/${MAX_CHARACTERS}`;

  useEffect(() => {
    const { firstName, lastName } = user;
    if (firstName && lastName) {
      setRackerName((firstName[0] + lastName[0]).toUpperCase());
    }
  }, [user]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (questionFromCatagory) {
      setSearchTerm(questionFromCatagory);
    }
  }, [questionFromCatagory]);

  const scrollToTop = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (searchResult || error) {
      scrollToTop();
    }
  }, [searchResult, error]);

  useEffect(() => {
    const allInternalUnselected = Object.values(datasets).every((category) =>
      category.datasets.every((dataset) => !dataset.selected)
    );
    const allExternalUnselected = Object.values(datasets).every((category) =>
      category.datasets.every((dataset) => !dataset.selected)
    );
    if (allInternalUnselected && allExternalUnselected) {
      setSelectedDataSet(datasets ? Object.keys(datasets)[1] : null);
      setCheckedItems(Object.values(datasets)[1]?.datasets?.map((d) => d.name));
    } else {
      const selectedExternalCategory = Object.keys(datasets).find(
        (category) =>
          category === EXTERNAL_DATASETS &&
          datasets[category].datasets.some((dataset) => dataset.selected)
      );

      const selectedInternalCategory = Object.keys(datasets).find(
        (category) =>
          category === INTERNAL_DATASETS &&
          datasets[category].datasets.some((dataset) => dataset.selected)
      );

      if (selectedExternalCategory) {
        setSelectedDataSet(EXTERNAL_DATASETS);
        const selectedDatasets = datasets[EXTERNAL_DATASETS].datasets.filter(
          (dataset) => dataset.selected
        );
        const selectedItems = selectedDatasets.map((dataset) => dataset.name);
        setCheckedItems(selectedItems);
      }

      if (selectedInternalCategory) {
        setSelectedDataSet(INTERNAL_DATASETS);
        const selectedDatasets = datasets[INTERNAL_DATASETS].datasets.filter(
          (dataset) => dataset.selected
        );
        const selectedItems = selectedDatasets.map((dataset) => dataset.name);
        setCheckedItems(selectedItems);
      }
    }
  }, [datasets]);

  useEffect(() => {
    if (updatedDatasets) {
      let category = null;
      for (const [categoryName, categoryData] of Object.entries(datasets)) {
        if (
          updatedDatasets.some((datasetName) =>
            categoryData.datasets.some(
              (dataset) => dataset.name === datasetName
            )
          )
        ) {
          category = categoryName;
          break;
        }
      }

      setSelectedDataSet(category);
      setCheckedItems(updatedDatasets);
    }
  }, [updatedDatasets]);

  useEffect(() => {
    if (checkedItems.length > 0) getSelectedDataset();
  }, [checkedItems]);

  const getSelectedDataset = () => {
    let category = selectedDataSet?.split(" ")[0];
    let type = "";
    if (
      checkedItems?.length ===
      datasets[
        selectedDataSet === EXTERNAL_DATASETS
          ? EXTERNAL_DATASETS
          : INTERNAL_DATASETS
      ]?.datasets.length
    ) {
      type = "All";
    } else if (checkedItems?.length === 1) {
      type = checkedItems[0];
    } else type = checkedItems?.length.toString();
    let text = `${category} (${type})`;
    setCategoryText(text);
  };

  const resetTextareaSize = () => {
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.style.resize = "both";
        textareaRef.current.style.height = "auto";
      }
    }, 10);
  };

  const submitSearch = () => {
    if (searchTerm) {
      dispatch(
        fetchSearchResults({
          query: searchTerm,
        })
      );
      dispatch(setGetSearch(GetSearch.search));
      dispatch(fetchTokenDetails());
      setSearchTerm("");
      dispatch(setQuestionFromCategory(""));
      resetTextareaSize();
    }
  };

  // Function to adjust the textarea height based on its content
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (isErrorInInput) {
        textarea.style.resize = "none";
      }
    }
  };

  useEffect(() => {
    // Set initial textarea content and adjust its height
    if (textareaRef.current && searchTerm !== "") {
      textareaRef.current.value = searchTerm;
      adjustTextareaHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleCharacterLimit = (
    value: string,
    setterFunction: React.Dispatch<React.SetStateAction<string>>,
    setErrorFunction: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const currentCharacterCount = value.length;
    if (currentCharacterCount <= MAX_CHARACTERS) {
      setterFunction(value);
      setErrorFunction(false);
      adjustTextareaHeight();
    } else {
      setErrorFunction(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    handleCharacterLimit(inputValue, setSearchTerm, setIsErrorinInput);
    if (e.target.value === "") {
      dispatch(setQuestionFromCategory(""));
    } else if (
      questionFromCatagory &&
      e.target.value !== questionFromCatagory
    ) {
      handleCharacterLimit(
        e.target.value,
        dispatch.bind(null, setQuestionFromCategory),
        setIsErrorinInput
      );
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitSearch();
    }
  };

  return (
    <Container
      fluid
      className={`left-container-body ${
        isSidePanelActive ? "left-panel-split-body" : ""
      }`}
    >
      <section id="inputgroup" className="textarea-container">
        <InputGroup
          className={`mx-auto ama ${
            isSidePanelActive ? "ama-total-width" : ""
          }`}
        >
          <Form.Control
            as="textarea"
            rows={1}
            ref={textareaRef}
            className={isErrorInInput ? "is-invalid" : ""}
            placeholder="Search chosen data sets for information"
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            value={searchTerm}
            data-testid="input-search"
          />
          <Button
            className="submit-search"
            variant="primary"
            id="submit-search"
            onClick={submitSearch}
            disabled={!isInputValid}
            data-testid="submit-search"
          >
            <PromptSearch />
          </Button>
        </InputGroup>
        <div
          className={`d-flex ${!isSidePanelActive ? "counter-width" : "w-100"}`}
          id="character-count"
        >
          <div
            className="dataset-text-link"
            onClick={() => setOpenSettings(!openSettings)}
          >
            <u>Datasets Selected: {checkedItems && categoryText}</u>
          </div>
          <div
            className={`${
              isErrorInInput ? "character-count-error" : "character-count"
            }`}
          >
            {characterCountDisplay}
          </div>
        </div>
      </section>

      {isLoading && <AppLoader />}

      {error && <ErrorResponse error={error} />}

      <section id="inputgroup-results" ref={resultsRef}>
        {Object.keys(searchResult).length > 0
          ? Object.keys(searchResult)
              .reverse()
              .map((key, index) => (
                <SearchResponse
                  key={key}
                  searchResult={searchResult[Number(key)]}
                  resultId={Number(key)}
                  index={index}
                  rackerName={rackerName}
                  data-testid="search-response"
                />
              ))
          : !isLoading && error === null && <WelcomePage />}
      </section>
      <Settings
        type="qa"
        setIsSettingOpen={setOpenSettings}
        isSettingOpen={openSettings}
      />
    </Container>
  );
}

export default Search;
