import { useRef } from "react";
import FileInput from "../../components/FIleInput";
import { FileDrop } from "react-file-drop";
import {
  SUPPORTED_DOCUMENT_TYPES,
  SUPPORTED_IMAGE_TYPES,
} from "../../constants";
import { getFileExtension } from "../../utils/file";
import "./fileDropContainer.css";

type FileDropContainerProps = {
  fileInput?: File;
  handleRemoveFile: (file: File) => void;
  handleAddFile: (file: File) => void;
};

function FileDropContainer({
  fileInput,
  handleRemoveFile,
  handleAddFile,
}: FileDropContainerProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileDropClick = () => {
    fileInputRef?.current?.click();
  };

  const onFileInputChange = (event: React.ChangeEvent) => {
    const { files } = event.target as any;
    if (files) {
      processFiles(files);
    }
  };

  const onFileDrop = (files: FileList | null) => {
    console.log("got files");
    if (files) {
      processFiles(files);
    }
  };

  const onRemoveFile = (file: File) => {
    handleRemoveFile(file);
  };

  const processFiles = (files: FileList) => {
    if (files.length === 0) {
      return;
    }
    if (files.length > 1) {
      alert("Multiple files are not supported");
      return;
    }
    const file = files[0];
    const fileExtension = getFileExtension(file.name);
    if (fileExtension) {
      const isFileTypeSupported =
        SUPPORTED_IMAGE_TYPES.includes(fileExtension) ||
        SUPPORTED_DOCUMENT_TYPES.includes(fileExtension);
      if (isFileTypeSupported) {
        // Need to add slight delay before removing the drag and drop field otherwise
        // the file will be opened by the browser
        setTimeout(() => {
          handleAddFile(file);
        }, 100);
      } else {
        const supportedFiles = [
          ...SUPPORTED_IMAGE_TYPES,
          ...SUPPORTED_DOCUMENT_TYPES,
        ];
        const supportedFilesString = supportedFiles.reduce(
          (acc, value) => `${acc}, ${value}`
        );
        alert(
          `File type not supported. Supported file types are: ${supportedFilesString}`
        );
      }
    }
  };

  return (
    <div className="visual-file-drop-container w-100 d-flex">
      {fileInput && (
        <>
          <div className="file-input-container h-100">
            <FileInput file={fileInput} onRemoveFile={onRemoveFile} />
          </div>
        </>
      )}
      {
        // For now, only supporting 1 file. Uncomment to add support for second file
        /*
        fileInputs.length > 1 && (
          <div className="file-input-container h-100">
            <FileInput file={fileInputs[1]} onRemoveFile={onRemoveFile} />
          </div>
        )
        */
      }
      {!fileInput && (
        <div
          className="file-drop-box h-100 flex-grow-1"
          onClick={onFileDropClick}
        >
          <FileDrop onDrop={onFileDrop}>
            Drag and drop an image or document or click to browse files
          </FileDrop>
          <input
            onChange={onFileInputChange}
            ref={fileInputRef}
            type="file"
            className="file-input"
          />
        </div>
      )}
    </div>
  );
}

export default FileDropContainer;
