import { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import {
  SidePanelState,
  setSidePanelActiveTab,
} from "../../data/api/SidePanel/sidePanelSlice";
import FAQ from "./FAQ";
import PromptLibrary from "./PromptLibrary";
import History from "./History";
import QAHistory from "./QAHistory";
import { PROMPT_TAB, FAQ_TAB, HISTORY_TAB } from "../../constants";
import "./index.css";
// import InterviewHistory from "./InterviewHistory";
import ContractHistory from "./ContractHistory";

function SidePanel() {
  const { sidePanelActiveTab, sidePanelState } = useAppSelector(
    (state: RootState) => state.sidePanelDetails
  );

  const [activeTab, setActiveTab] = useState(HISTORY_TAB);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sidePanelActiveTab) {
      setActiveTab(sidePanelActiveTab);
    }
  }, [sidePanelActiveTab]);

  useEffect(() => {
    if (
      sidePanelState === SidePanelState.qa ||
      sidePanelState === SidePanelState.interview ||
      sidePanelState === SidePanelState.contract
    ) {
      dispatch(setSidePanelActiveTab(HISTORY_TAB));
    }
  }, [sidePanelState]);

  return (
    <Tabs
      activeKey={activeTab}
      id="fill-tab-example"
      className="mt-12 side-panel-tabs"
      onSelect={(key) => dispatch(setSidePanelActiveTab(key as string))}
      fill
    >
      {/* <Tab eventKey={HISTORY_TAB} title="History" data-testid="history">
        <History data-testid="history-content" />
      </Tab> */}
      <Tab eventKey={HISTORY_TAB} title="History" data-testid="history">
        {sidePanelState === SidePanelState.search ? (
          <History data-testid="history-content" />
        ) : sidePanelState === SidePanelState.qa ? (
          <QAHistory />
        ) : // ) : sidePanelState === SidePanelState.interview ? (
        //   <InterviewHistory />
        sidePanelState === SidePanelState.contract ? (
          <ContractHistory />
        ) : null}
      </Tab>
      {sidePanelState === SidePanelState.search && (
        <Tab
          eventKey={PROMPT_TAB}
          title="Prompt Library"
          data-testid="prompt-library"
        >
          {/* Below condition is added to default the accordion on tab switch */}
          {activeTab === PROMPT_TAB && (
            <PromptLibrary data-testid="prompt-content" />
          )}
        </Tab>
      )}
      <Tab eventKey={FAQ_TAB} title="FAQ" data-testid="faq">
        {/* Below condition is added to default the accordion on tab switch */}
        {activeTab === FAQ_TAB && <FAQ data-testid="faq-content" />}
      </Tab>
    </Tabs>
  );
}

export default SidePanel;
