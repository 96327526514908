function DocIcon() {
    return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>34862E40-14F0-4A51-A156-CE6E529CD7E1</title>
            <g id="ICE-V.2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Style-Sheet" transform="translate(-252, -621)">
                    <g id="DOC" transform="translate(252, 621)">
                        <g id="HTML">
                            <g id="Group-21" transform="translate(3, 0)" fill="#9E9E9E" fill-rule="nonzero">
                                <path d="M13.5857864,0 L2,0 C0.8954305,0 0,0.767511857 0,1.71428571 L0,22.2857143 C0,23.2324881 0.8954305,24 2,24 L18,24 C19.1045695,24 20,23.2324881 20,22.2857143 L20,5.49789734 C20,5.0432405 19.7892863,4.60720522 19.4142136,4.28571429 L15,0.502102661 C14.6249272,0.180611726 14.1162194,0 13.5857864,0 Z M2,1.71428571 L13.5857864,1.71428571 L18,5.49789734 L18,22.2857143 L2,22.2857143 L2,1.71428571 Z" id="Rectangle"></path>
                                <path d="M15.625,1.73214286 L15.625,5.44642857 L18.625,5.44642857 L18.625,7.30357143 L14.625,7.30357143 C14.1340802,7.30357143 13.7257833,6.97508899 13.6411113,6.54191189 L13.625,6.375 L13.625,1.73214286 L15.625,1.73214286 Z" id="Path-42"></path>
                            </g>
                            <rect id="Rectangle" fill="#9E9E9E" x="0" y="10" width="26" height="10" rx="2"></rect>
                            <path d="M15.4299002,15.1323529 C15.4299002,15.7072221 15.328297,16.2112278 15.1250874,16.644385 C14.9218778,17.0775423 14.6311053,17.4117635 14.2527612,17.6470588 C13.8744171,17.8823541 13.4405979,18 12.9512906,18 C12.467331,18 12.0355171,17.883691 11.6558361,17.6510695 C11.276155,17.418448 10.9820403,17.0862321 10.7734831,16.6544118 C10.5649259,16.2225914 10.459312,15.7259386 10.4566382,15.1644385 L10.4566382,14.8756684 C10.4566382,14.3007993 10.5602468,13.7947883 10.7674671,13.3576203 C10.9746874,12.9204524 11.2674652,12.5848942 11.6458093,12.3509358 C12.0241535,12.1169774 12.4566382,12 12.9432692,12 C13.4299027,12 13.862385,12.1169774 14.2407291,12.3509358 C14.6190733,12.5848942 14.9118511,12.9204524 15.1190714,13.3576203 C15.3262917,13.7947883 15.4299002,14.2994624 15.4299002,14.8716578 L15.4299002,15.1323529 Z M14.2106489,14.8676471 C14.2106489,14.2553445 14.1010243,13.7901085 13.8817719,13.4719251 C13.6625195,13.1537417 13.3496884,12.9946524 12.9432692,12.9946524 C12.5395239,12.9946524 12.2280297,13.1517364 12.0087773,13.4659091 C11.7895248,13.7800818 11.6785634,14.2406387 11.6758896,14.8475936 L11.6758896,15.1323529 C11.6758896,15.7286126 11.7855141,16.1911748 12.0047666,16.5200535 C12.224019,16.8489321 12.5395239,17.013369 12.9512906,17.013369 C13.355036,17.013369 13.6651933,16.8549481 13.8817719,16.5381016 C14.0983505,16.2212551 14.2079751,15.7593613 14.2106489,15.1524064 L14.2106489,14.8676471 Z" id="O" fill="#FFFFFF"></path>
                            <path d="M5,18 L5,12 L6.84615385,12 C7.37362901,12 7.84546495,12.1188175 8.26167582,12.356456 C8.6778867,12.5940946 9.00274609,12.9320033 9.23626374,13.3701923 C9.46978139,13.8083813 9.58653846,14.3063159 9.58653846,14.864011 L9.58653846,15.1401099 C9.58653846,15.697805 9.47184181,16.1936791 9.24244505,16.6277473 C9.0130483,17.0618154 8.68956253,17.3983505 8.27197802,17.6373626 C7.85439352,17.8763748 7.38324438,17.9972527 6.85851648,18 L5,18 Z M6.23626374,13.0013736 L6.23626374,17.0068681 L6.83379121,17.0068681 C7.31731011,17.0068681 7.68681191,16.8489027 7.94230769,16.532967 C8.19780348,16.2170314 8.32829668,15.7651128 8.33379121,15.1771978 L8.33379121,14.8598901 C8.33379121,14.249997 8.20741885,13.7877763 7.95467033,13.4732143 C7.70192181,13.1586523 7.33242001,13.0013736 6.84615385,13.0013736 L6.23626374,13.0013736 Z" id="D" fill="#FFFFFF"></path>
                        </g>
                        <g id="Group-22" transform="translate(16.3, 12)" fill="#FFFFFF">
                            <path d="M4.76069521,3.97459895 C4.71524044,4.60294434 4.48329089,5.0975918 4.06483959,5.45855617 C3.6463883,5.81952052 3.09492322,6 2.41042782,6 C1.66176097,6 1.07286311,5.74799723 0.643716585,5.24398398 C0.214570048,4.73997076 0,4.04813276 0,3.16844921 L0,2.81149734 C0,2.2499972 0.0989294926,1.75534974 0.296791446,1.32754012 C0.494653399,0.899730485 0.777404585,0.571525212 1.14505349,0.342914441 C1.51270238,0.11430367 1.93983715,0 2.4264706,0 C3.10027076,0 3.64304609,0.180479483 4.05481285,0.541443854 C4.46657962,0.902408224 4.70454515,1.40908766 4.7687166,2.06149734 L3.56550803,2.06149734 C3.53609612,1.6844901 3.43115065,1.41109711 3.25066846,1.24131017 C3.07018628,1.07152322 2.79545641,0.986631019 2.4264706,0.986631019 C2.02539907,0.986631019 1.72526839,1.13034616 1.52606953,1.41778075 C1.32687067,1.70521535 1.22459897,2.15106651 1.21925134,2.75534761 L1.21925134,3.19652408 C1.21925134,3.82754328 1.31483862,4.28876862 1.50601605,4.58021392 C1.69719348,4.87165923 1.99866105,5.0173797 2.41042782,5.0173797 C2.78208743,5.0173797 3.05949108,4.9324875 3.24264707,4.76270056 C3.42580307,4.59291361 3.53074855,4.3302157 3.55748665,3.97459895 L4.76069521,3.97459895 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { DocIcon }