type RackerNameProps = {
  rackerName?: string;
};

function RackerName({ rackerName }: RackerNameProps) {
  return (
    <svg className="default-size-svg" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>73330071-4F61-4772-882D-AD86D8F89716</title>
      <g id="ICE-V.1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ICE-V.1---Question-Error" transform="translate(-132.000000, -388.000000)">
          <g id="Group-3" transform="translate(120.000000, 376.000000)">
            <g id="Group-Copy-2" transform="translate(12.000000, 12.000000)">
              <circle id="Oval" stroke="#333333" fill="#333333" cx="20" cy="20" r="19.5" />
              <text id="NS" fontFamily=".AppleSystemUIFont" fontSize="16" fontWeight="normal" fill="#FFFFFF" textAnchor="middle" dominantBaseline="central">
                <tspan x="20" y="20">{rackerName}</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export { RackerName };
