import { Navbar, Container, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { IceLogoDesktop } from "../../svg/iceLogoDesktop";
import { IceLogoMobile } from "../../svg/iceLogoMobile";
import { SettingsIcon } from "../../svg/settings";
import { UserDetails, Token } from "../../types";
import { StatusGreen } from "../../svg/statusGreen";
import { StatusRed } from "../../svg/statusRed";
import { StatusYellow } from "../../svg/statusYellow";
import "./index.css";
import { useEffect, useState } from "react";
import { HelpIcon } from "../../svg/helpIcon";
import { setIsSidePanelActive } from "../../data/api/SidePanel/sidePanelSlice";
import { setSidePanelActiveTab } from "../../data/api/SidePanel/sidePanelSlice";
import { FAQ_TAB } from "../../constants";

type NavbarProps = {
  userDetails: UserDetails;
  tokenDetails: Token;
  setIsSettingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSettingOpen: boolean;
  setIsFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFeedbackOpen: boolean;
};

function NavbarComponent(props: NavbarProps) {
  const {
    userDetails,
    tokenDetails,
    setIsSettingOpen,
    isSettingOpen,
    setIsFeedbackOpen,
    isFeedbackOpen,
  } = props;

  const dispatch = useAppDispatch();

  const [tokenStatusIcon, setTokenStatusIcon] = useState<React.ReactNode>(
    <StatusGreen />
  );

  const [enableHelp, setEnableHelp] = useState(false);

  useEffect(() => {
    const tokenPercentage =
      (tokenDetails.reamaing_quota / tokenDetails.total_quota) * 100;
    if (tokenPercentage >= 31) {
      setTokenStatusIcon(<StatusGreen />);
    }
    if (tokenPercentage >= 11 && tokenPercentage <= 30) {
      setTokenStatusIcon(<StatusYellow />);
    }
    if (tokenPercentage <= 10) {
      setTokenStatusIcon(<StatusRed />);
    }
  }, [tokenDetails]);

  const { isSidePanelActive } = useAppSelector(
    (state: RootState) => state.sidePanelDetails
  );

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary border-bottom position-sticky bg-navbar"
      fixed="top"
      data-testid="navbar"
    >
      <Container
        fluid
        className={`navbar-default ${
          isSidePanelActive ? "navbar-split-view" : ""
        }`}
      >
        <Navbar.Brand href="#home" className="logos">
          <div className="ice-logo-desktop">
            <IceLogoDesktop />
          </div>
          <div className="ice-logo-mobile">
            <IceLogoMobile />
          </div>
        </Navbar.Brand>
        <div className="token-settings-parent">
          <div className="user-token-class">
            <span className="user-details-class">
              <OverlayTrigger
                placement="bottom-start"
                delay={{ show: 250, hide: 400 }}
                // overlay={()=>renderTooltip(key.data_set,key.data_classification)}
                overlay={(props: any) => (
                  <Tooltip {...props} id="tooltipid">
                    <span>
                      Quota ($):{" "}
                      {tokenDetails.reamaing_quota
                        ? `${tokenDetails.reamaing_quota} of ${tokenDetails.total_quota}`
                        : ""}
                    </span>
                  </Tooltip>
                )}
              >
                <span>&nbsp; {tokenStatusIcon}</span>
              </OverlayTrigger>
            </span>
            <span className="ms-auto">{`${userDetails.firstName} ${userDetails.lastName}`}</span>
          </div>
          <div onClick={() => setEnableHelp(!enableHelp)} className="help-icon">
            <HelpIcon />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => setIsSettingOpen(!isSettingOpen)}
          >
            {" "}
            <SettingsIcon />{" "}
          </div>
          {enableHelp && (
            <div className="help-options" onClick={() => setEnableHelp(false)}>
              <div
                onClick={() => {
                  dispatch(setIsSidePanelActive(true));
                  dispatch(setSidePanelActiveTab(FAQ_TAB));
                }}
              >
                FAQ
              </div>
              <div>Documentation</div>
              <div onClick={() => setIsFeedbackOpen(!isFeedbackOpen)}>
                Share Feedback
              </div>
            </div>
          )}
        </div>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
